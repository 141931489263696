import { Dialog, DialogFooter, DialogType, Dropdown, IDropdownOption, IDropdownStyles, PrimaryButton, Spinner, Stack, TooltipHost } from "@fluentui/react";
import { useBoolean } from '@fluentui/react-hooks';
import React from "react";
import { useState } from "react";
import { ApiParameters, useApi } from "../hooks/useApi";
import { Session } from "../models/session";
import { TrieNode } from "../models/trieNode";
import devOpsApi, { GetSessionsRequest } from '../services/devOpsApiClient';
import externaleApi, { QueryDocAsyncRequest } from "../services/externalApiClient";
import { StatusMessage } from "../statusMessageBar";


export enum viewTypeEnum {
    SubDocument = "SubDocument",
    CompareSubDocument = "CompareSubDocument",
    ReVisionSubDocument = "ReVisionSubDocument",

}
export interface revisionProps {
    documentId: string,
    revisionId: string,
    docType: string,
    setRevisionSubDocument: Function,
    selectedDimensionPermutation: any,
    dimensionTypeList: string[],
    showRevision: Function
}
export interface DimensionObject {
    key: string,
    data: any,
    text: string,
}
export interface revisionDropdown {
    revisions: IDropdownOption[]
    name: string,
    selectedKey: string
}
const dropStyles: Partial<IDropdownStyles> = {
    title: {
        borderColor: '#96c2e9'
    },
    label: {
        color: '#96c2e9',
        textAlign: 'center'
    }
};
export function Revision(props: revisionProps) {
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
    const [buttonDisplay, setButtonDisplay] = useState<boolean>(false);
    const [request, updateRequest] = useState<GetSessionsRequest>({} as GetSessionsRequest);
    const [ready, response, isLoading, error, execute] = useApi<any>();
    const [sessions, updateSessions] = React.useState<Session[]>([]);
    const [statusMessage, setStatusMessage] = useState<StatusMessage | null>(null);
    const [revision, setRevision] = useState<revisionDropdown>();
    const [selectedKey, SetSelectedKey] = useState<string>("");
    const [revisionSubDocumentCache, setRevisionSubDocumentCache] = useState<Map<string, any[]>>(new Map<string, any[]>());
    const [documentTypeTrieNodeDictionary, setDocumentTypeTrieNodeDictionary] = useState<Map<string, TrieNode>>(new Map<string, TrieNode>());
    const [tempReVisionSubDocuments, setReVisionTempSubDocuments] = useState<any[]>([]);
    const [requestStage, setRequestStage] = useState("");
    const [isLoadingReVision, setIsLoadingReVision] = useState(false);
    const [modalProps, setModalProps] = useState<any>({
        type: DialogType.normal,
        title: 'Warning',
        subText: 'Please select DocumentType first.',
    })
    const modalPropsStyles = { main: { maxWidth: 450 } };


    React.useEffect(() => {
        const request = {
            documentId: props.documentId,

        } as GetSessionsRequest;
        updateRequest(request);
        setRequestStage("setDropdown");

    }, [props.documentId]);
    React.useEffect(() => {
        if (selectedKey !== "") {

            setRevision({ selectedKey: (selectedKey as string), revisions: (revision?.revisions as IDropdownOption<any>[]), name: revision?.name as string });
            setIsLoadingReVision(true);
            getSubDocument(props.docType);
        }
        else {
            setRevision({ selectedKey: (selectedKey as string), revisions: (revision?.revisions as IDropdownOption<any>[]), name: revision?.name as string });
        }

    }, [selectedKey]);
    React.useEffect(() => {
        if (props.docType !== undefined && selectedKey !== "" && isLoadingReVision === false) {
            setIsLoadingReVision(true);
            getSubDocument(props.docType);
        }
    }, [props.docType, props.selectedDimensionPermutation, selectedKey]);

    React.useEffect(() => {
        if (ready) {
            execute([devOpsApi.getSessions(request)]);
        }
    }, [request, ready]);

    React.useEffect(() => {
        if (response) {
            if (requestStage === "setDropdown") {
                let array = (response as Session[]).filter(o => o.sessionId != props.revisionId);
                updateSessions(array);
                let opt: revisionDropdown
                let arr: IDropdownOption[] = [];
                array.forEach(item => {
                    arr.push({ key: item.sessionId, text: item.sessionId, data: item });
                });
                opt = {
                    name: "Revision History",
                    revisions: arr,
                    selectedKey: selectedKey

                }
                setRevision(opt);
            }
            else if (requestStage === "getSubDocument") {
                let subArray: any[] = [];
                let res = response["Items"]["$values"] as any[];
                res.forEach(element => {
                    subArray.push(element);
                });
                tempReVisionSubDocuments.forEach((item) => { subArray.push(item) });
                if (response.ContinuationToken !== null) {
                    setReVisionTempSubDocuments(subArray);
                    fetchReVisionSubDocument(props.docType, response.ContinuationToken);
                }
                else {
                    setRevisionSubDocumentCache(c => c.set(props.docType + "_" + selectedKey, subArray));
                    showSubDocument(subArray, props.docType);
                }
            }
        }

    }, [response]);

    React.useEffect(() => {
        if (error) {
            setStatusMessage(error);
        }
    }, [error]);

    return (
        <Stack style={{ marginTop: 18, marginLeft: 20 }} horizontal>
            <div>
                {
                    sessions !== undefined && revision !== undefined ?sessions.length>0? (
                        <Dropdown
                            placeholder="Select an option"
                            label={revision?.name}
                            options={revision?.revisions as IDropdownOption<any>[]}
                            style={{ marginTop: 2, }}
                            styles={dropStyles}
                            onChange={onchange}
                            selectedKey={revision?.selectedKey}
                            onRenderOption={(i) => {
                                return (
                                    <TooltipHost content={i?.text}>
                                        <span>{(i?.text as string).length > 12 ? i?.text.substring(0, 12) + "..." : i?.text}</span>
                                    </TooltipHost>
                                );
                            }}
                        />
                    ) :"":(<Spinner label="Loading..." />)
                }
            </div>

            <div>
                {buttonDisplay ?
                    (<PrimaryButton style={{ height: 31, marginTop: 34, marginLeft: 15 }} onClick={() => { SetSelectedKey(""); setIsLoadingReVision(false); setButtonDisplay(false); props.showRevision(false) }}>Clean</PrimaryButton>) : ""
                }
            </div>
            <Dialog hidden={hideDialog} onDismiss={toggleHideDialog} dialogContentProps={modalProps} modalProps={modalProps}>
                <DialogFooter>
                    <PrimaryButton onClick={toggleHideDialog} text="OK" />
                </DialogFooter>
            </Dialog>
        </Stack>
    )

    function onchange(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption<any>, index?: number) {
        if (props.docType === undefined) {
            toggleHideDialog();
            SetSelectedKey("");
        }
        else if (isLoadingReVision) {
            setModalProps({ type: DialogType.normal,
                title: 'Warning',
                subText: 'please waiting SubDocument DownLoad!'})
            toggleHideDialog();
        }
        else {
            props.showRevision(true);
            SetSelectedKey(option?.key as string);
            setButtonDisplay(true);
        }

    }

    function getSubDocument(itemDocumentType: string) {
        props.setRevisionSubDocument(null, null, false, viewTypeEnum.ReVisionSubDocument, true);
        if (revisionSubDocumentCache.has(itemDocumentType + "_" + selectedKey) === true) {
            showSubDocument(revisionSubDocumentCache.get(itemDocumentType + "_" + selectedKey) as any[], itemDocumentType);
        }
        else {
            setReVisionTempSubDocuments([]);
            setRequestStage('getSubDocument');
            fetchReVisionSubDocument(itemDocumentType, "");
        }
    }

    function fetchReVisionSubDocument(documentType: string, continuationToken: string) {
        const requestQuerySubDocuments = {
            productId: props.documentId,
            revisionId: selectedKey,

            queryOptions: {
                PageSize: 1000,
                DocumentTypes: [documentType],
                continuationToken: continuationToken !== "" ? continuationToken : undefined
            }
        } as QueryDocAsyncRequest
        if (ready) {
            execute([externaleApi.queryDocumentsAsync(requestQuerySubDocuments)]);
        }
    }
    function showSubDocument(subArray: any[], documentType: string) {
        if (subArray.length === 0) {
            props.setRevisionSubDocument(null, null, false, viewTypeEnum.ReVisionSubDocument, true);
        }
        else {
            const node = getOrAddTrieNodeByDocumentType(documentType, subArray, props.dimensionTypeList);
            if (props.selectedDimensionPermutation === undefined) {
                let subDocumentArray = new Array<string>();
                subArray.forEach(element => {
                    subDocumentArray.push(JSON.stringify(element));
                });
                props.setRevisionSubDocument(subDocumentArray, null, false, viewTypeEnum.ReVisionSubDocument, true);
            }
            else {
                let reVisionSubDocument = getReVisionSubDocumentFromTrie(node, props.selectedDimensionPermutation, props.dimensionTypeList);

                props.setRevisionSubDocument([reVisionSubDocument], null, false, viewTypeEnum.ReVisionSubDocument, true);

            }
            setIsLoadingReVision(false);
        }
    }
    function getReVisionSubDocumentFromTrie(node: TrieNode, permutation: any, dimensionTypeList: Array<string>) {
        const dimensions = JSON.parse(JSON.stringify(permutation)) as Array<DimensionObject>;
        let dimensionList = new Array<any>();
        dimensions.forEach(element => {
            dimensionList.push(element.data);
        });
        return node.GetValue(dimensionList, dimensionTypeList, 0);
    }
    
    function getOrAddTrieNodeByDocumentType(documentType: string, subDocuments: Array<any>, dimensionTypeList: Array<string>) {
        let node: TrieNode;
        if (documentTypeTrieNodeDictionary.has(documentType + "_" + selectedKey) === false) {
            node = BuildTrie(subDocuments, dimensionTypeList);
            setDocumentTypeTrieNodeDictionary(d => d.set(documentType + "_" + selectedKey, node));
        }
        else {
            node = documentTypeTrieNodeDictionary.get(documentType + "_" + selectedKey) as TrieNode;
        }
        return node;
    }
    function BuildTrie(subDocuments: Array<any>, dimensionTypeList: Array<string>) {
        let head = new TrieNode();
        subDocuments.forEach(subDocument => {
            head.AddValue(JSON.stringify(subDocument), dimensionTypeList, 0, 0);
        });
        return head;
    }
}