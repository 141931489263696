import { Dropdown, IDropdownOption, IDropdownStyles, ILabelStyles, IStackStyles, IStackTokens, IStyleSet, Label, PrimaryButton, Stack, TooltipHost } from "@fluentui/react";
import React from "react";
import { useEffect, useState } from "react";
import { DimensionData } from "../models/dimensionData";
import { HistoryOptions } from "./historyOptions";
import { Revision } from "./revision";
import { useApi } from "../hooks/useApi";
import { StatusMessage } from "../statusMessageBar";
export interface DimensionDropdownProps {
    permutationKeys: DimensionData[],
    jsonData: any[],
    dimensionsName: string[],
    getJsonData: Function,
    getcompareJsonData:Function,
    productId:string,
    revisionId:string,
    docType:string,
    setRevisionSubDocument:Function,
    showReVision:Function
}
export interface historyData {
    data:any[],
    historData:string
 }
export interface SomeDimension {
    someDimensions: IDropdownOption[]
    dimensionname: string,
    index: number,
    selectedKeys: string
}

export enum dimensionEnum {
    IsEdgeZoneDimension = "IsEdgeZoneDimension",
    LifecycleStateDimension = "LifecycleStateDimension"
}
export function DimensionDropdown(props: DimensionDropdownProps) {
    const stackStyles: IStackStyles = {
        root: {
            width: 950,
            marginLeft: 0,
            marginTop: 30,
            marginBottom:20
        },
    };
    const buttonStyle: IStackStyles = {
        root: {
            marginTop: 28,
            minWidth: 90
        }
    
   
    }
    const [someDimension, setSomeDimension] = useState<SomeDimension[]>();
    const [selectedKeys, setSelectedKeys] = useState<string[]>();
    const [storageValue,setStorageValue]=useState<any[]>();
    const [jsonData, SetJsonData] = useState<any[]>(props.jsonData);
    const [getCompareJson, SetCompareGetJson] = useState<any>();
    const[selectRevisionId,setSelectRevisionId]=useState<any>();
    const[initOption,setInitOption]=useState<SomeDimension[]>();
    const [ready, response, isLoading, error, execute] = useApi<any>();
    const [statusMessage, setStatusMessage] = useState<StatusMessage | null>(null);
    const storageKey=  props.productId;
    const dropdownStyles: Partial<IDropdownStyles> = {
        dropdown: { width: 150 },
        dropdownItemSelected: {
            selectors: {
                '&:before': {
                    content: '',
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    bottom: 0,
                    width: '4px',
                    background: 'rgb(0, 120, 212)',
                },
            },
        },
    };
    const stackTokens: IStackTokens = { childrenGap: 10 };
    useEffect(() => {
        if (selectedKeys === undefined) {
            let selectKeys: any[] = [];
            props.dimensionsName.forEach(item => {
                selectKeys.push(item + "_");
            })
            setSelectedKeys(selectKeys);
        }
        let SomeDimensionArray: SomeDimension[] = [];
        for (let i = 0; i < props.permutationKeys.length; i++) {
            let obj: SomeDimension = {
                someDimensions: [],
                dimensionname: "",
                index: 0,
                selectedKeys: ""
            };

            for (let j = 0; j < jsonData.length; j++) {
                if (!obj.someDimensions?.includes(jsonData[j][i])) {
                    obj.someDimensions.push(jsonData[j][i])
                    obj.dimensionname = jsonData[j][i].data.$type.split(',')[0].split('.')[1].split("Dimension")[0];
                }
            }
            obj.index = i;
            if (selectedKeys !== undefined) {
                obj.selectedKeys = selectedKeys[i].split('_')[1];
            }
            SomeDimensionArray.push(obj);
        }

        if(initOption==undefined)
        {
            setInitOption(SomeDimensionArray)
        }
        setSomeDimension(SomeDimensionArray);

    }, [jsonData]);

    useEffect(()=>{
        if(localStorage.getItem(storageKey)!==null)
        {
            setStorageValue(JSON.parse(localStorage.getItem(storageKey) as string));
        }
    },[])

    useEffect(()=>{

       let array=JSON.parse(localStorage.getItem(storageKey) as string);
        if(storageValue?.includes(JSON.parse(localStorage.getItem(storageKey) as string)))
        {
            setStorageValue(JSON.parse(localStorage.getItem(storageKey) as string));
        }

    },[storageValue])
    
    useEffect(() => {
        if (error) {
            setStatusMessage(error);
        }
    }, [error]);
    
    return (
        <Stack  horizontal wrap>
        <Stack styles={stackStyles} horizontal wrap tokens={stackTokens}>

            {someDimension !== undefined ? (
                someDimension?.map((item: SomeDimension | undefined, key) =>
                    item !== undefined ? (

                        <Dropdown key={key}
                            placeholder="Select an option"
                            label={item.dimensionname}
                            options={item.someDimensions}
                            styles={dropdownStyles}
                            onChange={onchange}
                            selectedKey={item.selectedKeys}
                            onRenderOption={(i) => {
                                return (
                                    <TooltipHost content={i?.text}>
                                        <span>{(i?.text as string).length > 12 ? i?.text.substring(0, 12) + "..." : i?.text}</span>
                                    </TooltipHost>
                                );
                            }}
                            onRenderLabel={() => {
                                return (
                                    <TooltipHost content={item.dimensionname}>
                                        <span>{item.dimensionname.length > 20 ? item.dimensionname.substring(0, 20) + "..." : item.dimensionname}</span>
                                    </TooltipHost>
                                );
                            }}
                        />

                    ) : "")

            ) : ""}
            <PrimaryButton styles={buttonStyle} text="Clean" onClick={clean} allowDisabledFocus />
            <PrimaryButton styles={buttonStyle} text="Confirm Selection" onClick={() => {
                if (selectedKeys?.filter(o => o.split('_')[1] !== "").length === 0) {
                    props.getJsonData(undefined)
                }
                else {
                    props.getJsonData(jsonData[0]);
                    if(storageValue===undefined)
                    {
                        let array:historyData[]=[];
                        
                        array.push({data:jsonData[0],historData:(new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString()).toString()})
                        setStorageValue(array);
                    localStorage.setItem(storageKey,JSON.stringify(array))
                    }
                    else
                    {
                        if(storageValue.filter(o=>o.data===jsonData[0]).length===0)
                        {
                            storageValue.push({data:jsonData[0],historData:(new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString()).toString()});
                            let array:any[]=[]
                            storageValue.forEach(element => {
                                array.push(element)
                            });
                            setStorageValue(array);
                            localStorage.setItem(storageKey,JSON.stringify(storageValue))
                        }
                    }
                    
                }
            }} allowDisabledFocus />

        </Stack>

        {
            storageValue!==undefined?(
                    <HistoryOptions   getcompareJsonData={(data: any) => { SetCompareGetJson(data); props.getcompareJsonData(data.data);props.getJsonData(jsonData[0]);}}  storageValue={storageValue}  productId={props.productId as string} revisionId={props.revisionId as string}/>):""
        }
       <Revision showRevision={(data:boolean)=>props.showReVision(data)} setRevisionSubDocument={(data:any)=>{ data!==null? props.setRevisionSubDocument(data,null,false):props.setRevisionSubDocument(null,null,true)}} dimensionTypeList={props.dimensionsName} selectedDimensionPermutation={jsonData[0]} docType={props.docType} documentId={props.productId} revisionId={props.revisionId} />

       </Stack>
       

 

    );
    function onchange(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption<any>, index?: number) {
        let list: any[] = [];
        selectedKeys?.forEach(el => {
            if (el.indexOf(option?.data.$type.split(',')[0].split('.')[1]) !== -1) {
                el = option?.data.$type.split(',')[0].split('.')[1] + "_" + option?.key;
                list.push(el);
            }
            else {
                list.push(el);
            }
        });
        setSelectedKeys(list);
        let array: any[] = [];
        jsonData.forEach(item => {
            if (item.includes(option)) {
                array.push(item);
            }
        })
        SetJsonData(array)
    }
    function clean() {
        if (selectedKeys?.filter(o => o.split('_')[1] !== "").length !== 0) {
            setSelectedKeys(undefined);
            props.getJsonData(undefined)
            
        }
        props.getcompareJsonData(undefined);
        SetCompareGetJson(undefined);
        SetJsonData(props.jsonData);
    }
}











