import React from 'react'
import { Nav, INavLink, INavStyles, INavLinkGroup } from '@fluentui/react/lib/Nav';
import { useState, useEffect } from 'react';
import { useApi, ApiParameters } from '../hooks/useApi';
import externaleApi, {  QueryDocAsyncRequest } from "../services/externalApiClient";
import { Label, ILabelStyles } from '@fluentui/react/lib/Label';
import { Spinner } from "@fluentui/react";
import { useBoolean } from '@fluentui/react-hooks';
import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import { PrimaryButton } from '@fluentui/react/lib/Button';
import { TrieNode } from "../models/trieNode";
import { StatusMessage, StatusMessageBar } from '../statusMessageBar';
import { DocmentType } from '../models/productDocument';

const navStyles: Partial<INavStyles> = {
  root: {
    width: 400,
    height: 750,
    boxSizing: 'border-box',
    border: '1px solid #eee',
    overflowY: 'auto',
  }
};
export enum viewTypeEnum {
  SubDocument = "SubDocument",
  CompareSubDocument = "CompareSubDocument"

}
const labelStyles: Partial<ILabelStyles> = { root: { fontSize: 14, fontWeight: 600 } };
const modalPropsStyles = { main: { maxWidth: 450 } };
const dialogContentProps = {
  type: DialogType.normal,
  title: 'Warning',
  subText: 'Please wait previos operation to be finished!',
};
const modalProps = {
  isBlocking: true,
  styles: modalPropsStyles,
  dragOptions: undefined,
}
export interface ProductNavProps {
  productId: string,
  revisionId: string,
  selectedDimensionPermutation: any,
  selectedDimensionPermutation2: any,
  setSubDocument: Function,
  setCompareSubDocument: Function,
  DocumentType:Function
}
export interface DocumentTypeItem {
  documentTypeName: string,
  subdocumentCount: number,
}
export interface Dictionary<T> {
  [Key: string]: T;
}
export interface DocumentTypeMeta {
  $type: string,
  Count: number,
  Checksum: string,
}
export interface DimensionObject {
  key: string,
  data: any,
  text: string,
}

export function ProductNav(props: ProductNavProps) {
  const [apiParameters, setApiParameters] = useState<ApiParameters[]>([]);
  const [ready, response, isLoading, error, execute] = useApi<any>();
  const [requestStage, setRequestStage] = useState("");
  const [productList, setProductList] = useState<Array<INavLinkGroup>>([]);
  const [tempSubDocuments, setTempSubDocuments] = useState<any[]>([]);
  const [isRequestSubDocument, { toggle: toggleIsRequestSubDocument }] = useBoolean(false);
  const [currentDocumentType, setCurrentDocumentType] = useState("");
  const [href, setHref] = useState("");
  const [url, setUrl] = useState("");
  const [subDocumentCache, setSubDocumentCache] = useState<Map<string, any[]>>(new Map<string, any[]>());
  const [documentTypeTrieNodeDictionary, setDocumentTypeTrieNodeDictionary] = useState<Map<string, TrieNode>>(new Map<string, TrieNode>());
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
  const [dimensionTypeList, setDimensionTypeList] = useState<Array<string>>([]);
  const [statusMessage, setStatusMessage] = useState<StatusMessage | null>(null);


  useEffect(() => {
    setUrl(window.location.href.substring(0, window.location.href.indexOf("#")));
    setRequestStage("getNav");
    fetchNav();
  }, []);

  useEffect(() => {
    if (ready) {
      execute(apiParameters);
    }
  }, [apiParameters, ready, execute]);

  useEffect(() => {
    if (response) {
      if (requestStage === "getNav") {
        let swaggerResponse;
        let productResponse;
        if (response[0].components !== undefined) {
          swaggerResponse = response[0];
          productResponse = response[1];
        }
        else {
          swaggerResponse = response[1];
          productResponse = response[0];
        }
        setProductNav(swaggerResponse, productResponse["Items"]["$values"][0]);
      }
      else if (requestStage === "getSubDocument") {
        let subArray: any[] = [];
        let res = response["Items"]["$values"] as any[];
        res.forEach(element => {
          subArray.push(element);
        });
        tempSubDocuments.forEach((item) => { subArray.push(item) });
        if (response.ContinuationToken !== null) {
          setTempSubDocuments(subArray);
          fetchSubDocument(currentDocumentType, response.ContinuationToken);
        }
        else {
          setSubDocumentCache(c => c.set(currentDocumentType, subArray));
          toggleIsRequestSubDocument();
          showSubDocument(subArray, currentDocumentType);
        }
      }
      if (statusMessage) {
        setStatusMessage(null);
    }
    }
  }, [response]);

  useEffect(() => {
    if (error) {
      props.setSubDocument(null, error, false);
      if (isRequestSubDocument === true) {
        toggleIsRequestSubDocument();
      }
    }
  }, [error]);

  useEffect(() => {
    if (href !== "" && href !== window.location.href) {
      window.location.href = href;
    }
  }, [window.location.href]);

  useEffect(() => {
    if (currentDocumentType !== "") {
      getSubDocument(currentDocumentType);
    }
  }, [currentDocumentType, props.selectedDimensionPermutation,props.selectedDimensionPermutation2]);

  function setProductNav(swaggerResponse: any, productResponse: any) {
    let dimensionTypes = productResponse.DimensionTypes.$values as Array<string>;
    setDimensionTypeList(dimensionTypes.sort());

    //set DocumentType and BusinessClassification dictionary
    const documentTypeArray = swaggerResponse.components.schemas.DocumentType.enum as Array<string>;
    const businessClassificationArray = swaggerResponse.components.schemas.DocumentType.additionalProperties.enum as Array<string>;
    let documentTypeBusinessClassificationDictionary = new Map<string, string>();
    for (let i = 0; i < documentTypeArray.length; i++) {
      documentTypeBusinessClassificationDictionary.set(documentTypeArray[i], businessClassificationArray[i]);
    }

    //set BusinessClassification and DocumentType's array mapping
    const documentTypes = productResponse.DocumentTypes.$values as Array<string>;
    const subDocumentMetaData = productResponse.SubdocumentMetadata as Dictionary<object>;
    let documentTypeClassificationMap = new Map<string, Array<DocumentTypeItem>>();
    documentTypes.forEach(documentType => {
      let businessClassification = documentTypeBusinessClassificationDictionary.get(documentType) as string;
      let documentTypeItem: DocumentTypeItem = { documentTypeName: documentType, subdocumentCount: 0 };
      if (Object.prototype.hasOwnProperty.call(subDocumentMetaData, documentType)) {
        const documentTypeMeta = subDocumentMetaData[documentType] as DocumentTypeMeta;
        documentTypeItem.subdocumentCount = documentTypeMeta.Count;
      }
      if (documentTypeClassificationMap.has(businessClassification) === false) {
        documentTypeClassificationMap.set(businessClassification, [documentTypeItem]);
      }
      else {
        let documentTypeItemArray = documentTypeClassificationMap.get(businessClassification) as Array<DocumentTypeItem>;
        documentTypeItemArray.push(documentTypeItem);
        documentTypeClassificationMap.set(businessClassification, documentTypeItemArray);
      }
    });

    //set nav list
    let tempProductList = new Array<INavLinkGroup>();
    documentTypeClassificationMap.forEach((value: Array<DocumentTypeItem>, key: string) => {
      let navLinkArray = new Array<INavLink>();
      value.forEach(item => {
        navLinkArray.push({ key: item.documentTypeName, name: item.documentTypeName, url: "#/" + key + "/" + item.documentTypeName, count: item.subdocumentCount, icon: 'DocumentSearch' });
      });
      tempProductList.push({ name: key, links: navLinkArray, isExpanded: window.location.href.indexOf(key) === -1 ? false : true });
    });
    tempProductList.sort(function(a,b){return (a.name as string).localeCompare(b.name as string)})
    tempProductList.forEach(item=>{
      item.links.sort(function(a,b){return (a.name as string).localeCompare(b.name as string)});
    })
    setProductList([{ links: tempProductList } as INavLinkGroup]);

    setCurrentDocumentTypeByUrl();
  }

  function setCurrentDocumentTypeByUrl() {
    const currentHref = window.location.href;
    const lastAnchorIndex = currentHref.lastIndexOf("#");
    const lastSlashIndex = currentHref.lastIndexOf("/");
    if (lastAnchorIndex !== -1) {
      setHref(url + currentHref.substring(lastAnchorIndex));
      setCurrentDocumentType(currentHref.substring(lastSlashIndex + 1));
    }
  }

  function fetchSubDocument(documentType: string, continuationToken: string) {
    const requestQuerySubDocuments = {
      productId: props.productId,
      revisionId: props.revisionId,
      queryOptions: {
        PageSize: 1000,
        DocumentTypes: [documentType],
        continuationToken: continuationToken !== "" ? continuationToken : undefined
      }
    } as QueryDocAsyncRequest
    setApiParameters([externaleApi.queryDocumentsAsync(requestQuerySubDocuments)]);
  }

  function _onLinkClick(ev?: React.MouseEvent<HTMLElement>, item?: INavLink) {
    if (item && item.count !== undefined) {
      if (isRequestSubDocument === true) {
        toggleHideDialog();
        return;
      }
      setHref(url + item.url);
      setCurrentDocumentType(item.key as string);
    }
  }

  function getSubDocument(itemDocumentType: string) {
    props.setSubDocument(undefined, null, true);
    props.setCompareSubDocument(undefined, null, true);
    if (subDocumentCache.has(itemDocumentType) === true) {
      showSubDocument(subDocumentCache.get(itemDocumentType) as any[], itemDocumentType);
    }
    else {
      toggleIsRequestSubDocument();
      setTempSubDocuments([]);
      setRequestStage('getSubDocument');
      fetchSubDocument(itemDocumentType, "");
    }
  }

  function BuildTrie(subDocuments: Array<any>, dimensionTypeList: Array<string>) {
    let head = new TrieNode();
    subDocuments.forEach(subDocument => {
      head.AddValue(JSON.stringify(subDocument), dimensionTypeList, 0, 0);
    });
    return head;
  }

  function getSubDocumentFromTrie(node: TrieNode, permutation: any, dimensionTypeList: Array<string>) {
    const dimensions = JSON.parse(JSON.stringify(permutation)) as Array<DimensionObject>;
    let dimensionList = new Array<any>();
    dimensions.forEach(element => {
      dimensionList.push(element.data);
    });
    return node.GetValue(dimensionList, dimensionTypeList, 0);
  }

  function getOrAddTrieNodeByDocumentType(documentType: string, subDocuments: Array<any>, dimensionTypeList: Array<string>) {
    let node: TrieNode;
    if (documentTypeTrieNodeDictionary.has(documentType) === false) {
      node = BuildTrie(subDocuments, dimensionTypeList);
      setDocumentTypeTrieNodeDictionary(d => d.set(documentType, node));
    }
    else {
      node = documentTypeTrieNodeDictionary.get(documentType) as TrieNode;
    }
    return node;
  }

  function fetchNav() {
    const request = {
      productId: props.productId,
      revisionId: props.revisionId,
      queryOptions: {
        PageSize: 1000,
        DocumentTypes: [DocmentType.product],
      }
    } as QueryDocAsyncRequest;
    setApiParameters([externaleApi.getSwagger(), externaleApi.queryDocumentsAsync(request)]);
  }

  function showSubDocument(subArray: any[], documentType: string) {
    props.DocumentType(documentType);
    if (subArray.length === 0) {
      props.setSubDocument(null, null, false);
      props.setCompareSubDocument(null, null, false);
    }
    else {
      const node = getOrAddTrieNodeByDocumentType(documentType, subArray, dimensionTypeList);
      if (props.selectedDimensionPermutation === undefined) {
        let subDocumentArray = new Array<string>();
        subArray.forEach(element => {
          subDocumentArray.push(JSON.stringify(element));
        });
        props.setSubDocument(subDocumentArray, null, false,viewTypeEnum.SubDocument,true);
      }
      else {
        let CompareSubDocument: string = "";
        let subDocument = getSubDocumentFromTrie(node, props.selectedDimensionPermutation, dimensionTypeList);
        if (props.selectedDimensionPermutation2 !== undefined) {
          CompareSubDocument = getSubDocumentFromTrie(node, props.selectedDimensionPermutation2, dimensionTypeList);

          if (subDocument === CompareSubDocument) {

            props.setCompareSubDocument([CompareSubDocument], null, false, viewTypeEnum.CompareSubDocument,true,false);
          }
          else {
            props.setCompareSubDocument([CompareSubDocument], null, false,viewTypeEnum.CompareSubDocument,true,true);
          }
        }
        else {
          let subDocumentArray = new Array<string>();
          subArray.forEach(element => {
            subDocumentArray.push(JSON.stringify(element));
          });
          props.setCompareSubDocument(subDocumentArray, null, false,viewTypeEnum.CompareSubDocument,false,false);
        }


        props.setSubDocument([subDocument], null, false,true);



      }
    }
  }

  return (
    <div>
    <StatusMessageBar message={statusMessage} isMultiline />
      <Label styles={labelStyles}>BusinessClassifications &amp; DocumentTypes:</Label>
      {productList.length > 0 ? (<Nav onLinkClick={_onLinkClick} styles={navStyles} groups={productList} selectedKey={currentDocumentType} />) : (<Spinner label="Loading..." />)}
      <Dialog hidden={hideDialog} onDismiss={toggleHideDialog} dialogContentProps={dialogContentProps} modalProps={modalProps}>
        <DialogFooter>
          <PrimaryButton onClick={toggleHideDialog} text="OK" />
        </DialogFooter>
      </Dialog>
    </div>
  );
}
