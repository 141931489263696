import { request } from 'http';
import { getEndpoint, EndpointType } from '../../config/endpointConfig';
import { ApiParameters } from '../hooks/useApi';
import { ReplayEventRequestBody } from '../models/replayEventItem';
import { DeleteSuppressionRuleStateRequest, SuppresionRuleStateScope, SuppressionRuleStateFeedType, SuppressionRuleStateRequest } from '../models/suppressionRule';
import { DeadLetterEvent } from '../models/deadLetter';
import RequestParamSetting from '../util/RequestParamSetting';

export interface GetSessionsRequest {
    documentId: string;
    startTime: string;
    endTime:string;
    externalDataFilter: string;
    cV: string;
    skip: number;
}

export interface GetBigIdSessionsRequest {
    bigId: string;
    bigcatVersion: string;
    cv: string;
    skip: number;
}


export interface GetSessionRequest {
    documentId: string;
    sessionId: string;
}

export interface GetEventsRequest {
    productId: string;
    revisionId: string;
    hasFilter: string;
    displaySessionMonitoringEvent: string;
    eventTypeName: string;
    startTime: string;
    endTime: string;
    pageSize: string;
    continuationToken: string
    bigIdVersion: string;
}

export interface GetManifastEventsRequest {
    revisionId: string;
    hasFilter: string;
    displaySessionMonitoringEvent: string;
    eventTypeName: string;
    startTime: string;
    endTime: string;
    pageSize: string;
    continuationToken: string
}

export interface ReplayEventRequest {
    body: ReplayEventRequestBody;
}

export interface AddOrEditSuppressionRuleEvent {
    body: SuppressionRuleStateRequest
}

export interface DeleteSuppressionRuleEvent {
    body: DeleteSuppressionRuleStateRequest
}

export interface GetDocumentConversionCompletionEventRequest {
    documentId: string;
    revisionId: string;
    responseType: string;
}

export interface GetPublishStateEntityRequest {
    responseId: string;
    responseType: string;
    isTest: string;
}

export interface GetRateCardDocumentRequest {
    productBigId: string;
    serviceBigId: string;
    revisionId: string;
}

export interface GetManifestSessionRequest {
    sessionId: string;
}

export interface GetManifestSessionsRequest {
    sessionId: string;
    startTime: string;
    endTime:string;
    cV: string;
    skip: number;
}

export interface GetDeadLettersRequest {
    revisionId: string;
    productId: string;
    startTime: string;
    endTime:string;
    eventType: string;
    tagType: number;
    pageSize: number;
    pageIndex: number;
    skipCount: number;
}

export interface TagRequest {
    body: Array<DeadLetterEvent>;
}

const apiSubPath: string = "Session";
const deadLetterApiSubPath: string = "DeadLetterEvent";

function getBaseUrl() {
    return getEndpoint(EndpointType.DevOpsApi);
}
const getManifestSession = (request: GetManifestSessionRequest) => {
    var url = new URL(`${getBaseUrl()}/${apiSubPath}/contentArtifactSession/revision/${request.sessionId}`);
    return { uri: url.toString(), method: "GET" } as ApiParameters;
}

const getManifestSessions = (request: GetManifestSessionsRequest) => {
    var url = new URL(`${getBaseUrl()}/${apiSubPath}/contentArtifact/${request.sessionId}`);
    
    if (request.startTime && request.startTime !== "") {
        url.searchParams.set("startTime", request.startTime);
    }
    if (request.endTime && request.endTime !== "") {
        url.searchParams.set("endTime", request.endTime);
    }
    if (request.skip) {
        url.searchParams.set("skip", request.skip.toString());
    }

    if (request.cV) {
        url.searchParams.set("cV", request.cV);
    }

    return { uri: url.toString(), method: "GET" } as ApiParameters;
}


const getSessions = (request: GetSessionsRequest) => {
    var url = new URL(`${getBaseUrl()}/${apiSubPath}/sessions/${request.documentId}`);

    if (request.startTime && request.startTime !== "") {
        url.searchParams.set("startTime", request.startTime);
    }
    if (request.endTime && request.endTime !== "") {
        url.searchParams.set("endTime", request.endTime);
    }
    if (request.externalDataFilter && request.externalDataFilter !== "") {
        url.searchParams.set("externalDataFilter", request.externalDataFilter);
    }

    if (request.skip) {
        url.searchParams.set("skip", request.skip.toString());
    }

    if (request.cV) {
        url.searchParams.set("cV", request.cV);
    }

    return { uri: url.toString(), method: "GET" } as ApiParameters;
}

const getBigIdSessions = (request:GetBigIdSessionsRequest) =>{
    var url = new URL(`${getBaseUrl()}/${apiSubPath}/bigIdSessions/${request.bigId}`);
    if (request.bigcatVersion) {
        url.searchParams.set("bigcatVersion", request.bigcatVersion);
    }
    if (request.cv) {
        url.searchParams.set("cv", request.cv);
    }
    if (request.skip) {
        url.searchParams.set("skip", request.skip.toString());
    }
    return { uri: url.toString(), method: "GET" } as ApiParameters;
}

const getSession = (request: GetSessionRequest) => {
    var url = new URL(`${getBaseUrl()}/${apiSubPath}/Session/${request.documentId}/${request.sessionId}`);
    return { uri: url.toString(), method: "GET" } as ApiParameters;
}

const getEvents = (request: GetEventsRequest) => {
    var url = new URL(`${getBaseUrl()}/FetchHistory/fetchHistoryByPagination`);
    url.searchParams.set("productId", request.productId);
    if (request.revisionId && request.revisionId !== "") {
        url.searchParams.set("revisionId", request.revisionId);
    }
    if (request.hasFilter) {
        url.searchParams.set("hasFilter", request.hasFilter);
    }

    if (request.bigIdVersion && request.bigIdVersion !== "") {
        url.searchParams.set("bigIdVersion", request.bigIdVersion);
    }
    if (request.displaySessionMonitoringEvent) {
        url.searchParams.set("displaySessionMonitoringEvent", request.displaySessionMonitoringEvent);
    }
    if (request.eventTypeName && request.eventTypeName !== "") {
        url.searchParams.set("eventTypeName", request.eventTypeName);
    }
    if (request.pageSize && request.pageSize !== "") {
        url.searchParams.set("pageSize", request.pageSize);
    }
    if (request.startTime && request.startTime !== "") {
        console.log("rs",request.startTime)
        url.searchParams.set("startTime", request.startTime);
    }
    if (request.endTime && request.endTime !== "") {
        url.searchParams.set("endTime", request.endTime);
    }
    if (request.continuationToken && request.continuationToken !== "") {
        url.searchParams.set("continuationToken", request.continuationToken);
    }

    return { uri: url.toString(), method: "GET" } as ApiParameters;
}

const getManifastEvents = (request: GetManifastEventsRequest) => {
    var url = new URL(`${getBaseUrl()}/FetchHistory/manifestHistory`);
        url.searchParams.set("revisionId", request.revisionId);
    if (request.hasFilter) {
        url.searchParams.set("hasFilter", request.hasFilter);
    }
    if (request.displaySessionMonitoringEvent) {
        url.searchParams.set("displaySessionMonitoringEvent", request.displaySessionMonitoringEvent);
    }
    if (request.eventTypeName && request.eventTypeName !== "") {
        url.searchParams.set("eventTypeName", request.eventTypeName);
    }
    if (request.pageSize && request.pageSize !== "") {
        url.searchParams.set("pageSize", request.pageSize);
    }
    if (request.startTime && request.startTime !== "") {
        console.log("rs",request.startTime)
        url.searchParams.set("startTime", request.startTime);
    }
    if (request.endTime && request.endTime !== "") {
        url.searchParams.set("endTime", request.endTime);
    }
    if (request.continuationToken && request.continuationToken !== "") {
        url.searchParams.set("continuationToken", request.continuationToken);
    }

    return { uri: url.toString(), method: "GET" } as ApiParameters;
}

const getReplayEvent = (request: ReplayEventRequest) => {
    var url = new URL(`${getBaseUrl()}/ReplayEvent`);

    return { uri: url.toString(), method: "POST", body: JSON.stringify(request.body) } as ApiParameters;
}

const getResponseType = () => {
    var url = new URL(`${getBaseUrl()}/RateCard/responseTypes`);
    console.log("[DevOpsApiClient]getting responseType from " + url);
    return { uri: url.toString(), method: "GET" } as ApiParameters;
}

const getDocumentConversionCompletionEvent = (request: GetDocumentConversionCompletionEventRequest) => {
    var url = new URL(`${getBaseUrl()}/RateCard/documentConversionCompletionEvent`);
    if (request.documentId && request.documentId !== "") {
        url.searchParams.set("documentId", request.documentId);
    }
    if (request.revisionId && request.revisionId !== "") {
        url.searchParams.set("revisionId", request.revisionId);
    }
    if (request.responseType && request.responseType !== "") {
        url.searchParams.set("responseType", request.responseType);
    }
    console.log("[DevOpsApiClient]getting documentConversionCompletionEvent from " + url);
    return { uri: url.toString(), method: "GET" } as ApiParameters;
}

const getPublishStateEntity = (request: GetPublishStateEntityRequest) => {
    var url = new URL(`${getBaseUrl()}/RateCard/publishStateEntity/${request.responseId}/${request.responseType}`);
    if (request.isTest && request.isTest !== "") {
        url.searchParams.set("isTest", request.isTest);
    }
    console.log("[DevOpsApiClient]getting publishStateEntity from " + url);
    return { uri: url.toString(), method: "GET" } as ApiParameters;
}

const getRateCardDocument = (request: GetRateCardDocumentRequest) => {
    var url = new URL(`${getBaseUrl()}/RateCard/documents/${request.productBigId}/${request.serviceBigId}/${request.revisionId}`);
    console.log("[DevOpsApiClient]getting ratecard documents from " + url);
    return { uri: url.toString(), method: "GET", responseType: "blob" } as ApiParameters;
}

const getSuppressionRuleURL = (feedType: SuppressionRuleStateFeedType, scope?: SuppresionRuleStateScope): URL => {
    if (feedType === SuppressionRuleStateFeedType.RatingFeed)
        if (scope)
            return new URL(`${getBaseUrl()}/RatingFeedValidationSuppressionRules/session/${scope.bigId}/${scope.revisionId}`);
        else
            return new URL(`${getBaseUrl()}/RatingFeedValidationSuppressionRules/global`);
    else if(feedType === SuppressionRuleStateFeedType.RateCard)
        if (scope)
            return new URL(`${getBaseUrl()}/RateCardValidationSuppressionRules/session/${scope.bigId}/${scope.revisionId}`);
        else
            return new URL(`${getBaseUrl()}/RateCardValidationSuppressionRules/global`);
    return new URL('');
}

const getSuppressionRules = (feedType: SuppressionRuleStateFeedType, scope?: SuppresionRuleStateScope) => {
    var url = getSuppressionRuleURL(feedType, scope);
    console.log("[DevOpsApiClient] getting suppression rules from " + url);
    return { uri: url.toString(), method: "GET", responseType: "json" } as ApiParameters;
}

const getAddSuppressionRule = (feedType: SuppressionRuleStateFeedType, request: AddOrEditSuppressionRuleEvent, scope?: SuppresionRuleStateScope) => {
    var url = getSuppressionRuleURL(feedType, scope);
    console.log(`[DevOpsApiClient] adding ${feedType} suppression rules to ${url}`);
    return { uri: url.toString(), method: "POST", body: JSON.stringify(request.body) } as ApiParameters;
}

const getEditSuppressionRule = (feedType: SuppressionRuleStateFeedType, request: AddOrEditSuppressionRuleEvent, scope?: SuppresionRuleStateScope) => {
    var url = getSuppressionRuleURL(feedType, scope);
    console.log(`[DevOpsApiClient] update suppression rule to ${url}`);
    return { uri: url.toString(), method: "PUT", body: JSON.stringify(request.body) } as ApiParameters;
}

const getDeleteSuppressionRule = (feedType: SuppressionRuleStateFeedType, request: DeleteSuppressionRuleEvent, scope?: SuppresionRuleStateScope) => {
    var url = getSuppressionRuleURL(feedType, scope);
    console.log(`[DevOpsApiClient] deleting suppression rule ${url}`);
    return { uri: url.toString(), method: "DELETE", body: JSON.stringify(request.body), responseType: "blob" } as ApiParameters;
}

const getDeadLetters = (request: GetDeadLettersRequest) => {
    var url = new URL(`${getBaseUrl()}/${deadLetterApiSubPath}/events/${request.eventType}`);

    RequestParamSetting.setNonEmptyStringParam(url, "startTime", request.startTime);
    RequestParamSetting.setNonEmptyStringParam(url, "endTime", request.endTime);
    RequestParamSetting.setNonEmptyStringParam(url, "productId", request.productId);
    RequestParamSetting.setNonEmptyStringParam(url, "revisionId", request.revisionId);
    RequestParamSetting.setNonDefaultIntParam(url, "tagType", request.tagType, -1);
    RequestParamSetting.setNonDefaultIntParam(url, "pageSize", request.pageSize, 10);
    RequestParamSetting.setNonMinIntParam(url, "pageIndex", request.pageIndex, 1);
    RequestParamSetting.setNonMinIntParam(url, "skipCount", request.skipCount, 0);

    return { uri: url.toString(), method: "GET" } as ApiParameters;
}

const addTagForDeadLetters = (request: TagRequest) => {
    var url = new URL(`${getBaseUrl()}/${deadLetterApiSubPath}/addTag`);

    return { uri: url.toString(), method: "POST", body: JSON.stringify(request.body) } as ApiParameters;
}

const removeTagForDeadLetters = (request: TagRequest) => {
    var url = new URL(`${getBaseUrl()}/${deadLetterApiSubPath}/removeTag`);

    return { uri: url.toString(), method: "POST", body: JSON.stringify(request.body) } as ApiParameters;
}

const interfaces = {
    getSessions,
    getSession,
    getEvents,
    getReplayEvent,
    getResponseType,
    getDocumentConversionCompletionEvent,
    getPublishStateEntity,
    getRateCardDocument,
    getSuppressionRules,
    getAddSuppressionRule,
    getEditSuppressionRule,
    getDeleteSuppressionRule,
    getBigIdSessions,
    getManifestSession,
    getManifestSessions,
    getManifastEvents,
    getDeadLetters,
    addTagForDeadLetters,
    removeTagForDeadLetters
};

export default interfaces;