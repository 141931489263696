import React from 'react'
import { IColumn } from "@fluentui/react";
import { DeadLetterEvent } from "./deadLetter";

export const getDeadLetterListColumns = (): IColumn[] => {
    return [
        {
            key: 'blobNameColumn',
            name: 'BlobName',
            fieldName: 'BlobName',
            minWidth: 490,
            isResizable: true,
            data: 'string',
            isPadded: false,
        },
        {
            key: 'revisionIdColumn',
            name: 'RevisionId',
            fieldName: 'RevisionId',
            minWidth: 90,
            isResizable: true,
            data: 'number',
            isPadded: false,
        },
        {
            key: 'productIdColumn',
            name: 'ProductId',
            fieldName: 'ProductId',
            minWidth: 400,
            isResizable: true,
            data: 'string',
            isPadded: false,
        },
        {
            key: 'eventTimeColumn',
            name: 'EventTime',
            fieldName: 'EventTime',
            minWidth: 110,
            isResizable: true,
            data: 'string',
            onRender: (item: DeadLetterEvent) => {
                return <span>{item.EventTime}</span>;
            },
            isPadded: false,
        },
        {
            key: 'eventTypeColumn',
            name: 'EventType',
            fieldName: 'EventType',
            minWidth: 210,
            isResizable: true,
            data: 'string',
            isPadded: false,
        },
        {
            key: 'isTaggedColumn',
            name: 'IsTagged',
            fieldName: 'IsTagged',
            minWidth: 60,
            isResizable: true,
            data: 'string',
            isPadded: false,
        }
    ];
};

