import React from 'react'
import { ILabelStyles, IStyleSet, Label, PrimaryButton, Stack, TooltipHost } from "@fluentui/react";
import { useEffect, useState } from "react";

export interface historyProductProps {
    productId: string,
    revisionId: string,
    storageValue:any[],
    getcompareJsonData:Function,
}
const h3style = {
    color: "#99cccc",
    marginLeft:50

    
}
const divHistorStyle = {
    marginLeft: 50,
    paddingleft: 180,

}
const divHistorBoxStyle = {
    width: 280,
    height: 185,
    overflow: "auto",
}
const liStyle ={
    listStyleType:"none",
marginTop:3,
marginRight:8,
paddingRight:5}

const labelTagStyles: Partial<IStyleSet<ILabelStyles>> = {
    root: {
        width:240,
        margin: 5,
        padding: 5,
        fontSize: 12,
        lineHeight: 15,
        display: "inline-block",
        border: '0.5px solid #ccc',
        borderRadius: 4,
        color: '#DDA0DD',
        textAlign: 'center',
        backgroundColor:'#ffff'
        
    }

}
export function HistoryOptions(props: historyProductProps) {
    
    const [values, setValue] = useState<any[]>();
    const storageKey = props.productId;
    useEffect(() => {
           setValue(props.storageValue);          
    }, [props.storageValue])

function _onClick( el:React.MouseEvent<HTMLButtonElement, MouseEvent>)
{
    if(el.currentTarget.textContent==="+")
    {
        el.currentTarget.nextElementSibling?.nextElementSibling?.removeAttribute("hidden");
        return el.currentTarget.textContent="-";
    }
    else
    {
       el.currentTarget.nextElementSibling?.nextElementSibling?.setAttribute("hidden","");
        return el.currentTarget.textContent="+"; 
    }
}
function _onRemoveClick(data:any)
{
    let array:any[]=[];
    if((values as any[]).includes(data))
    {
       (values as any[]).splice((values as any[]).indexOf(data),1);
        (values as any[]) .forEach((item=>{
            array.push(item);
        }))
       localStorage.setItem(storageKey,JSON.stringify(values));

    }
    else
    {
        (values as any[]).splice((values as any[]).indexOf(data),1);
        (values as any[]).forEach((item=>{
            array.push(item);
        })) 
    }
  setValue(array)
}

    return (
        <Stack style={{marginTop:18}}>
            <div style={divHistorStyle}>
                {values!==undefined?(
                <h4 style={h3style}>Dimension History</h4>):("")}
                <div className="histBox" style={divHistorBoxStyle}>
                {
                    values!==undefined?
                  (values.reverse() as any[]).map((value,index)=>{
                     return  <Label styles={labelTagStyles} key={index}>{value.historData} <button style={{float:"right"}} onClick={_onClick}>+</button><br></br><ul hidden >{value.data.map((val:any,index:number)=>{
                         return <li key={index} style={liStyle}> <TooltipHost content={val.data.$type.split(',')[0].split('.')[1].split("Dimension")[0]}>
                         <span>{(val.data.$type.split(',')[0].split('.')[1].split("Dimension")[0] as string).length > 12 ? val.data.$type.split(',')[0].split('.')[1].split("Dimension")[0].substring(0, 12) + "..." : val.data.$type.split(',')[0].split('.')[1].split("Dimension")[0]}</span>
                     </TooltipHost> <strong>:</strong><TooltipHost content={val.text}>
                                        <span>{(val.text as string).length > 12 ? val.text.substring(0, 12) + "..." : val.text}</span>
                                    </TooltipHost></li>
                     })}<div style={{marginTop:5}}><button style={{marginRight:10}} onClick={()=>{props.getcompareJsonData(value)}}>compare</button><button onClick={()=>_onRemoveClick(value)}>Remove</button></div></ul></Label>
                  }):""
                      
                }
                </div>
            </div>
        </Stack>
    )
}