import { Stack, StackItem, TextField, PrimaryButton, IStackStyles } from '@fluentui/react';
import React from 'react';
import { SuppressionRulesListView } from './suppressionRulesListView';
import { SuppressionRuleStateFeedType } from '../../models/suppressionRule';

const content: React.CSSProperties = {
    width: '100%',
    flexGrow: '1',
}

const itemStyles: React.CSSProperties = {
    marginRight: '40px',
    marginTop: '-10px',
    minWidth: '300px'
};

const listStyles: React.CSSProperties = {
    marginTop: '20px',
    marginBottom: '20px',
    width: '100%',
    flexGrow: '1'
};

export interface SessionSuppressionRulesListViewProps {
    feedType: SuppressionRuleStateFeedType
}

export function SessionSuppressionRulesListView(props: SessionSuppressionRulesListViewProps) {
    const [bigId, updateBigId] = React.useState<string>('');
    const [revisionId, updateRevisionId] = React.useState<string>('');
    const [canFetchRules, updateCanFetchRules] = React.useState<boolean>(false);

    return (
        <Stack style={content}>
            <Stack horizontal horizontalAlign="start" verticalAlign="end">
                <StackItem style={itemStyles}>
                    <TextField
                        label="Big Id"
                        placeholder="Please enter BigId here"
                        onChange={(e, val) => {
                            updateBigId(val || "");
                        }}
                    />
                </StackItem>
                <StackItem style={itemStyles}>
                    <TextField
                        label="Revision Id"
                        placeholder="Please enter RevisionId here"
                        onChange={(e, val) => {
                            updateRevisionId(val || "");
                        }}
                    />
                </StackItem>
                <StackItem>
                    <PrimaryButton text="Search" onClick={_onSubmitBtnClickHandler} />
                </StackItem>
            </Stack>
            <Stack style={listStyles}>
                {canFetchRules && <SuppressionRulesListView feedType={props.feedType} scope={{ bigId: bigId, revisionId: revisionId }} />}
            </Stack>
        </Stack>
    );

    function _onSubmitBtnClickHandler() {
        if (bigId.trim() === "" || revisionId.trim() === "") {
            alert('Please provide valid BigId and RevisionId');
            return;
        }
        updateCanFetchRules(true);
    }
}