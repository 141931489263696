export interface SuppressionRuleState {
    "documentType": string,
    "validationRuleName": string,
    "suppressedDocumentIds": string[],
    "comments": string,
    "isDeleted": boolean,
    "lastModifiedBy": string,
    "lastModifiedDateTime": string,
}

export interface DeleteSuppressionRuleStateRequest {
    "comments": string;
    "deletedByAlias": string;
    "documentType": string;
    "validationRuleName": string;
}

export interface SuppressionRuleStateRequest {
    "comments": string;
    "createdByAlias": string;
    "documentType": string;
    "suppressedDocumentIds": string[];
    "validationRuleName": string;
}

export interface SuppresionRuleStateScope {
    bigId: string,
    revisionId: string
}

export enum SuppressionRuleStateFeedType {
    RatingFeed = 'RatingFeed',
    RateCard = 'RateCard'
}
