import { Spinner } from '@fluentui/react';
import React, { useState, useEffect } from 'react';
import { DetailsList, DetailsListLayoutMode, IColumn, SelectionMode } from '@fluentui/react/lib/DetailsList';
import { publishRecordState } from '../models/publishRecordState';
import { useApi, ApiParameters } from '../hooks/useApi';
import { StatusMessage, StatusMessageBar } from '../statusMessageBar';
import pcdApiClient, { GetSyncRecordRequest } from '../services/pcdApiClient';

export interface PublishRecordListProps {
    documentId: string,
    revisionId: string,
    documentType: string[],
    getBigIdVersion(bigIds: string[]): void;
}

export function PublishRecordStateView(props: PublishRecordListProps) {
    const [record, updateRecord] = useState<publishRecordState[]>([]);
    const [apiParameters, updateApiParameters] = useState<ApiParameters[]>([]);
    const [ready, response, isLoading, error, execute] = useApi<publishRecordState>();
    const [finished, setFinished] = useState(false);
    const [statusMessage, setStatusMessage] = useState<StatusMessage | null>(null);

    const columns: IColumn[] = [
        {
            key: 'bigIdColumn',
            name: 'BigId',
            fieldName: 'bigId',
            minWidth: 100,
            isResizable: true,
            data: 'string',
            isPadded: true,
        },
        {
            key: 'productIdColumn',
            name: 'ProductId',
            fieldName: 'productId',
            minWidth: 80,
            isResizable: true,
            data: 'string',
            isPadded: true,
        },
        {
            key: 'versionColumn',
            name: 'Version',
            fieldName: 'version',
            minWidth: 50,
            isResizable: true,
            data: 'string',
            isPadded: true,
        },
        {
            key: 'eTagColumn',
            name: 'ETag',
            fieldName: 'eTag',
            minWidth: 50,
            isResizable: true,
            data: 'string',
            isPadded: true,
        },
        {
            key: 'documentVersionColumn',
            name: 'DocumentVersion',
            fieldName: 'documentVersion',
            minWidth: 100,
            isResizable: true,
            data: 'number',
            isPadded: true,
        },
        {
            key: 'doTypeColumn',
            name: 'DocumentType',
            fieldName: 'documentType',
            minWidth: 100,
            isResizable: true,
            data: 'string',
            isPadded: true,
        },
        {
            key: 'syncTypeColumn',
            name: 'SyncType',
            fieldName: 'syncType',
            minWidth: 100,
            isResizable: true,
            data: 'string',
            isPadded: true,
        },
        {
            key: 'ctTimeColumn',
            name: 'Publish Time',
            fieldName: 'createdTime',
            minWidth: 120,
            isResizable: true,
            data: 'string',
            isPadded: true,
        },
        {
            key: 'pdTimeColumn',
            name: 'Generated Time',
            fieldName: 'pcdGeneratedTime',
            minWidth: 120,
            isResizable: true,
            data: 'string',
            isPadded: true,
        },
        {
            key: 'cvColumn',
            name: 'CurrentPublishingVersion',
            fieldName: 'currentPublishingDocumentVersion',
            minWidth: 150,
            isResizable: true,
            data: 'number',
            isPadded: true,
        },
        {
            key: 'lvColumn',
            name: 'LastPublishedVersion',
            fieldName: 'lastPublishedDocumentVersion',
            minWidth: 150,
            isResizable: true,
            data: 'number',
            isPadded: true,
        },
    ];

    useEffect(() => {
        if (finished) {
            return;
        }
        props.documentType.forEach((type) => {
            if (type[0] !== "TestFragment" && type[0] !== "Fragment") {
                return;
            }

            const request = {
                documentId: props.documentId,
                revisionId: props.revisionId,
                documentType: type[0],
            } as GetSyncRecordRequest;

            updateApiParameters(preApiParas => [...preApiParas, pcdApiClient.getSyncRecord(request)]);
        });

    }, [props.documentId, props.revisionId, props.documentType]);

    useEffect(() => {
        if (ready && !finished && apiParameters.length !== 0) {
            execute(apiParameters);
        }
    }, [ready]);

    useEffect(() => {
        if (response) {
            setFinished(true);
            if (Array.isArray(response)) {
                const documents = response as publishRecordState[];
                if (documents.length === 0) {
                    return;
                }

                documents.forEach((data) => {
                    props.documentType.forEach((type) => {
                        if (data?.documentType === type[0]) {
                            data!.pcdGeneratedTime = type[1];
                        }
                    });
                });

                updateRecord(documents);
                documents.forEach((data) => {
                    props.getBigIdVersion(((preData: any) => [...preData, [data?.bigId, data?.version, data?.documentType]]) as any);
                });
            } else {
                const document = response as publishRecordState;

                props.documentType.forEach((type) => {
                    if (document?.documentType === type[0]) {
                        document!.pcdGeneratedTime = type[1];
                    }
                });

                updateRecord([document]);

                props.getBigIdVersion(((preType: any) => [...preType, [document?.bigId, document?.version, document?.documentType]]) as any);
            }
            setFinished(true);
            if (statusMessage) {
                setStatusMessage(null);
            }
        }
    }, [response]);

    useEffect(() => {
        if (error) {
            setStatusMessage(error);
        }
    }, [error])

    const [items, updateItems] = useState<publishRecordState[]>(record);

    useEffect(() => {
        updateItems(record);
    }, [record]);

    return (
        <div>
            {isLoading && (<Spinner label="Loading..." />)}
            {items.length !== 0 &&
                <DetailsList
                    items={items}
                    compact={false}
                    columns={columns}
                    selectionMode={SelectionMode.none}
                    setKey="multiple"
                    layoutMode={DetailsListLayoutMode.justified}
                    isHeaderVisible={true}
                    selectionPreservedOnEmptyClick={true}
                    enterModalSelectionOnTouch={true}
                    ariaLabelForSelectionColumn="Toggle selection"
                    ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                    checkButtonAriaLabel="select row"
                />}
            <StatusMessageBar message={statusMessage} isMultiline />
        </div>
    );
}
