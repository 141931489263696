import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useEffect, useRef, useState } from "react";
import { useBoolean } from "@fluentui/react-hooks";
import { BrowserAuthOptions, Configuration } from "@azure/msal-browser";
import { Environment, getEnvironment } from "./environmentConfig";
import { appInsights } from "./telemetryConfig";

function getAuthConfig(): BrowserAuthOptions {
  switch (getEnvironment()) {
    case Environment.PROD:
      return {
        clientId: "3671d2bc-4a9b-4396-a810-6aeac410f764",
        authority:
          "https://login.microsoftonline.com/72f988bf-86f1-41af-91ab-2d7cd011db47",
        redirectUri: process.env.REACT_APP_AUTHCONFIG_REDIRECTION_URL,
      };
    default:
      return {
        clientId: "3671d2bc-4a9b-4396-a810-6aeac410f764",
        authority:
          "https://login.microsoftonline.com/72f988bf-86f1-41af-91ab-2d7cd011db47",
        redirectUri: process.env.REACT_APP_AUTHCONFIG_REDIRECTION_URL,
      };
  }
}

function getAuthPMEConfig(): BrowserAuthOptions {
  switch (getEnvironment()) {
    case Environment.PROD:
      return {
        clientId: "46ee7996-aa73-4ba6-99f0-524c473ec8b0",
        authority:
          "https://login.microsoftonline.com/975f013f-7f24-47e8-a7d3-abc4752bf346",
        redirectUri: process.env.REACT_APP_AUTHCONFIG_REDIRECTION_URL,
      };
    default:
      return {
        clientId: "29300f28-bc2f-46d0-b13d-372815f33a08",
        authority:
          "https://login.microsoftonline.com/975f013f-7f24-47e8-a7d3-abc4752bf346",
        redirectUri: process.env.REACT_APP_AUTHCONFIG_REDIRECTION_URL,
      };
  }
}

function getTokenRequestRedirectionUrl() {
  return process.env.REACT_APP_AUTHCONFIG_REDIRECTION_URL;
}

export const msalConfig: Configuration = {
  auth: getAuthConfig(),
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

export const msalConfigPME: Configuration = {
  auth: getAuthPMEConfig(),
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ["https://graph.microsoft.com/User.Read"],
};

// Note that setting to false doesn't skip server side auth, only set to false for local debugging
export const useIsLoginEnforced: boolean = true;

export const useAccessToken = () => {
  const mountedRef = useRef(true);
  const { instance, accounts } = useMsal();
  const [token, setToken] = useState<string>();
  const [tokenLoaded, { toggle: toggleLoading }] = useBoolean(false);

  appInsights.setAuthenticatedUserContext(accounts[0] && accounts[0].username);

  useEffect(() => {
    if (!useIsLoginEnforced || accounts.length < 1) {
      setToken(undefined);
      toggleLoading();
      return;
    }

    const request = {
      ...loginRequest,
      account: accounts[0],
      redirectUri: getTokenRequestRedirectionUrl(),
    };

    instance
      .acquireTokenSilent(request)
      .then((response: any) => {
        setToken(response.accessToken);
        toggleLoading();
      })
      .catch((e: any) => {
        if (mountedRef.current) {
          instance.handleRedirectPromise().then((tokenResponse: any) => {
            if (!tokenResponse) {
              instance.acquireTokenPopup(request).then((response: any) => {
                setToken(response.accessToken);
                toggleLoading();
              });
            } else {
              setToken(tokenResponse.accessToken);
              toggleLoading();
            }
          });
        }
      });
  }, [instance, accounts, toggleLoading]);

  return [token, tokenLoaded];
};
