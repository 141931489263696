import React, { CSSProperties, useEffect } from 'react'

import { useSearchParams } from 'react-router-dom'
import {
  IStackTokens,
  Stack,
  FontWeights,
  ITextStyles,

  Text,
} from "@fluentui/react";
import { useState } from "react";
import { LayoutStyles } from "../styles/layout";
import {
  listNameEnum,
  SearchListProps,
  SessionListView,
} from "./session/sessionListView";


export interface IHomeViewProps {}

export function HomeView(props: IHomeViewProps) {
  const [buttonVal, SetButtonVal] = useState("");
  const stackTokens: Partial<IStackTokens> = { childrenGap: 20 };
  const [search,setsearch] = useSearchParams()
  console.log(search.get('search'));


  const stackStyle: CSSProperties = {
    marginTop:'80px',
    textAlign: "center",
  }
  

  const boldStyle: Partial<ITextStyles> = {
    root: { fontWeight: FontWeights.semibold },
};
  const searchInfo : string =search.get('search') as string
  return (
    searchInfo==null||searchInfo==''?(

<div style={LayoutStyles.Main}>
                <Stack style={{...stackStyle}} tokens={stackTokens}>
                    <Text variant="xxLarge" styles={boldStyle}>
                    Please enter DocumentId or BigId to Search
                    </Text>
                    </Stack>
    </div>):(
    <div style={LayoutStyles.Main}>
      <div>
        <Stack tokens={stackTokens}>         
          <SessionListView
            documentId={searchInfo}
            searchList={{ documentId:searchInfo } as SearchListProps}
            listName={listNameEnum.homeView}
          />
        </Stack>
      </div>
    </div>)
  );
}
