import React, { useState, useEffect } from 'react';
import "./styles/modal.css";

interface ModalProps {
  show: boolean;
  onClose: () => void;
  title: string;
  description: string[];
  text: string;
}

export function CommonModal(props: ModalProps) {
  const [isShowing, setIsShowing] = useState(props.show);

  useEffect(() => {
    setIsShowing(props.show);
  }, [props.show, props.title, props.description]);

  const handleCloseClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setIsShowing(false);
    props.onClose();
  }

  if (!isShowing) {
    return null;
  }

  function changeString(item: object) {
    let output = "";
    Object.entries(item).forEach(([k, v]) => {
      if (String(v).indexOf("StableTypeName") == -1 && String(v) !== "null") {
        output = output + String(k) + ":" + String(v) + ','
      }

    })
    return output.substring(0, output.length - 1)
  }

  return (
    <div className="modal-container" id="modal-opened">
      <div className="modal">
        <div className="modal__details">
          <h1 className="modal__title">{props.title}</h1>
          <div className="modal__text">
            {props.description.map((item, index) => { return <p className="modal__description" key={index}>{typeof item === 'object' ? changeString(item) : String(item)}  </p> })}
          </div>
        </div>
        <a href="#modal-closed" style={{ color: 'black' }} className="link-2" onClick={handleCloseClick}></a>
      </div>
    </div>
  );
}

export default CommonModal;
