import { DefaultButton, ILabelStyles, IStackStyles, IStackTokens, IStyleSet, Label, ProgressIndicator, Spinner, Stack, Text } from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import { StatusMessage, StatusMessageBar } from '../statusMessageBar';
import CommonMoadl from '../commonModal';
import externaleApi, { QueryDocAsyncRequest } from "../services/externalApiClient";
import { ApiParameters, useApi } from '../hooks/useApi';
import { DocmentType } from '../models/productDocument';

const labelStyles: Partial<IStyleSet<ILabelStyles>> = {
    root: { marginTop: 3 },
};
interface OutputType {
    key: string, value: any[] 
}
interface PermutationsOutputType {
    [key: string]: string[];
}
const labelTagStyles: Partial<IStyleSet<ILabelStyles>> = {
    root: {
        margin: 3,
        padding: 5,
        fontSize: 12,
        lineHeight: 15,
        display: "inline-block",
        border: '0.5px solid #ccc',
        borderRadius: 4,
        color: '#55afff',
        textAlign: 'center',
    }
}


export interface SubDocProps {
    documentId: string,
    sessionId: string,
    dimensionTypes: string[]
}
interface PermutationKeys {
    $type: string;
    [key: string]: any;
}


export function SubDocumentDimensionType(props: SubDocProps) {
    const [dimensionType, setDimensionType] = useState<OutputType[]>([]);
    const [permutationsDimensionType, setPermutationsDimensionType] = useState<PermutationsOutputType>();
    const [isOpen, setIsOpen] = useState(false);
    const [title, setTitle] = useState<string>();
    const [values, setValues] = useState<string[]>();
    const [apiParameters, setApiParameters] = useState<ApiParameters[]>([]);
    const [ready, response, isLoading, error, execute] = useApi<any>();
    const [statusMessage, setStatusMessage] = useState<StatusMessage | null>(null);
    const [displayShow, setDisplayShow] = useState<string>("block");
    let uniqueDimensions = new Map<string, any>();

    useEffect(() => {
        fetchPermutationForRequest("");
    }, [props]);

    useEffect(() => {
        if (ready) {
            execute(apiParameters);
        }
    }, [apiParameters, ready, execute]);

    useEffect(() => {
        if (response) {
            try {
                let res = response["Items"]["$values"] as any[];
                if (res[0].Permutations !== undefined) {
                    let datas: any[] = res[0].Permutations.$values;
                    getPermutationsDimensionTypeValue(datas)
                } else {
                    let data =  extractPermutationKeys(res[0].PermutationKeys);
                    getDimensionTypeValue(data)
                }

                if (statusMessage) {
                    setStatusMessage(null);
                }
            }
            catch(e){
                setStatusMessage({ type: "error", message: "No documents found." });
            }
        } 
    }, [response]);

    useEffect(() => {
        if (error) {
            setStatusMessage(error);
        }
    }, [error]);
    function getDimensionTypeValue(datas: any[]) {

        setDisplayShow("none")
        setDimensionType(datas)
    }

    function toggleModal() {
        setIsOpen(!isOpen)
    }

    function getPermutationsDimensionTypeValue(datas: any[]) {
        datas.forEach(data => {
            (data.$values as any[]).forEach(d => {
                const dimensionString = JSON.stringify(d);
                if (!uniqueDimensions.has(dimensionString)) {
                    uniqueDimensions.set(dimensionString, d);
                }
            })
        })
        const uniqueDimensionsArray = Array.from(uniqueDimensions.values());
        let output: PermutationsOutputType = {};
        uniqueDimensionsArray.forEach((item) => {
            const key = item.$type;
            const value = (Object.values(item).filter(v => v !== key)[0]) as string;
            if (output[key]) {
                output[key].push(value);
            } else {
                output[key] = [value];
            }
        });
        setDisplayShow("none")
        setPermutationsDimensionType(output)
    }

    function fetchPermutationForRequest(continuationToken: string) {
        const requestQueryPermutation = {
            productId: props.documentId,
            revisionId: props.sessionId,
            queryOptions: {
                PageSize: 1000,
                DocumentTypes: [DocmentType.permutations,DocmentType.compressedPermutations],
                continuationToken:  continuationToken !== "" ? continuationToken : undefined
              }
        } as QueryDocAsyncRequest

        setApiParameters([externaleApi.queryDocumentsAsync(requestQueryPermutation)]);
    }
    function extractPermutationKeys(permutationKeys: PermutationKeys): { key: string, value: any[] }[] {
        const output = Object.entries(permutationKeys)
            .filter(([key]) => key !== "$type")
            .reduce((output, [key, dimension]) => {
                Object.entries(dimension)
                    .filter(([subKey]) => subKey !== "$type")
                    .forEach(([subKey, item]) => {
                        const typedItem = item as { [key: string]: any, $type: string }; 
                        const itemType = typedItem.$type;
                        const itemValues = Object.values(typedItem).filter(v => v !== itemType && v !== "");
                        const itemValue = itemValues.map(processItemValue).join(",");
                        output[itemType] = output[itemType] || [];
                        output[itemType].push(itemValue);
                    });
                return output;
            }, {} as { [key: string]: any[] });
    
        const finalOutput: { key: string, value: any[] }[] = [];
        Object.keys(output).forEach(key => {
            finalOutput.push({ key: extractSubstring(key), value: output[key] });
        });
    
        return finalOutput;
    
        function processItemValue(value: any): string {
            if (typeof value === 'object' && value !== null) {
                const entries = Object.entries(value)
                    .filter(([key, val]) => key !== "$type" && val !== "")
                    .map(([key, val]) => `${key}:${val}`);
                return `[${entries.join(",")}]`;
            }
            return String(value);
        }
    }

    function extractSubstring(input: string): string {
        const startIndex = input.indexOf('.') + 1;
        const endIndex = input.indexOf(',');
        if (startIndex > 0 && endIndex > startIndex) {
            return input.substring(startIndex, endIndex);
        }
        return '';
    }
    return (

        <div>
            <StatusMessageBar message={statusMessage} isMultiline />
            {props.dimensionTypes != null && props.dimensionTypes !== undefined ?
            <div style={{ display: displayShow }}>
                <Text>DimensionTypes: {props.dimensionTypes?.map((item, index) => { return <DefaultButton disabled={true} styles={labelTagStyles} key={index}>{item}</DefaultButton> })}</Text>
            </div> : <Spinner label="Loading..." />}
            {dimensionType.length > 0 ?(
            <div>            
            {dimensionType != null && dimensionType != undefined ?
                <Text>DimensionTypes: {dimensionType.map((item, index) => { return <DefaultButton onClick={() => { setTitle(item.key), setValues(item.value), setIsOpen(true) }} styles={labelTagStyles} key={index}>{item.key}</DefaultButton> })}</Text> : <div style={{ display: displayShow }}><ProgressIndicator label="load Dimension Button" /></div>}
            <CommonMoadl
                show={isOpen}
                onClose={toggleModal}
                title={title as string}
                description={values as string[]}
                text=""
            />
            </div>):permutationsDimensionType != undefined ?(
            <div>
            {permutationsDimensionType != null && permutationsDimensionType != undefined ?
                <Text>DimensionTypes: {Object.entries(permutationsDimensionType).map(([key, values], index) => { return <DefaultButton onClick={() => { setTitle(key), setValues(values), setIsOpen(true) }} styles={labelTagStyles} key={index}>{(key.split(".")[1]).split(",")[0]}</DefaultButton> })}</Text> : <div style={{ display: displayShow }}><ProgressIndicator label="load Dimension Button" /></div>}
            <CommonMoadl
                show={isOpen}
                onClose={toggleModal}
                title={((title?.split(".")[1])?.split(",")[0])?.split("Dimension")[0] as string}
                description={values as string[]}
                text=""
            />
            </div>):(isLoading && (<Spinner label="Loading..." />))}
        </div>
    );
}