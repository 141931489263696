import { Spinner } from '@fluentui/react';
import React, { useState, useEffect } from 'react';
import { DetailsList, DetailsListLayoutMode, IColumn, SelectionMode } from '@fluentui/react/lib/DetailsList';
import { RatingFeedPublishSession } from '../models/ratingFeedPublishSession';
import ratingFeedApiClient, { GetPublishSessionRequest } from '../services/ratingFeedApiClient';
import { StatusMessage, StatusMessageBar } from '../statusMessageBar';
import { useApi, ApiParameters } from '../hooks/useApi';

export interface PublishSessionProps {
    revisionId: string,
    bigIdVersion: string,
    getPublishModes(modes: string[]): void;
}

export function RatingFeedPublishSessionView(props: PublishSessionProps) {
    const [session, updateSession] = useState<RatingFeedPublishSession[]>([]);
    const [apiParameters, updateApiParameters] = useState<ApiParameters[]>([]);
    const [ready, response, isLoading, error, execute] = useApi<RatingFeedPublishSession>();
    const [finished, setFinished] = useState(false);
    const [statusMessage, setStatusMessage] = useState<StatusMessage | null>(null);

    const columns: IColumn[] = [
        {
            key: 'idColumn',
            name: 'ProcessedDocumentId',
            fieldName: 'processedDocumentId',
            minWidth: 220,
            maxWidth: 260,
            isResizable: true,
            data: 'string',
            isPadded: true,
        },
        {
            key: 'modeColumn',
            name: 'PublishMode',
            fieldName: 'publishMode',
            minWidth: 80,
            maxWidth: 100,
            isResizable: true,
            data: 'string',
            isPadded: true,
        },
        {
            key: 'stateColumn',
            name: 'State',
            fieldName: 'state',
            minWidth: 60,
            maxWidth: 80,
            isResizable: true,
            data: 'string',
            isPadded: true,
        },
        {
            key: 'proColumn',
            name: 'Progress',
            fieldName: 'progress',
            minWidth: 50,
            maxWidth: 80,
            isResizable: true,
            data: 'number',
            isPadded: true,
        },
        {
            key: 'sccColumn',
            name: 'SuccessCount',
            fieldName: 'successCount',
            minWidth: 80,
            maxWidth: 80,
            isResizable: true,
            data: 'number',
            isPadded: true,
        },
        {
            key: 'totalColumn',
            name: 'TotalCount',
            fieldName: 'totalCount',
            minWidth: 50,
            maxWidth: 80,
            isResizable: true,
            data: 'number',
            isPadded: true,
        },
        {
            key: 'ctTimeColumn',
            name: 'Created Time',
            fieldName: 'createdTime',
            minWidth: 110,
            maxWidth: 130,
            isResizable: true,
            data: 'string',
            isPadded: true,
        },
        {
            key: 'paColumn',
            name: 'ParentPath',
            fieldName: 'parentPath',
            minWidth: 120,
            maxWidth: 160,
            isResizable: true,
            data: 'string',
            isPadded: true,
        },
        {
            key: 'scColumn',
            name: 'SecondaryParentPath',
            fieldName: 'secondaryParentPath',
            minWidth: 120,
            maxWidth: 160,
            isResizable: true,
            data: 'string',
            isPadded: true,
        }
    ];

    useEffect(() => {
        if (finished) {
            return;
        }

        const publishModes = ["Direct", "Api"];
        publishModes.forEach((publishMode) => {
            const request = {
                publishMode: publishMode,
                revisionId: props.revisionId,
                bigcatVersion:props.bigIdVersion!=""?props.bigIdVersion: "%20"
            } as GetPublishSessionRequest;

            updateApiParameters(preApiParas => [...preApiParas, ratingFeedApiClient.getGetPublishSession(request)]);
        });

    }, [props]);

    useEffect(() => {
        if (ready && !finished) {
            execute(apiParameters);
        }
    }, [apiParameters, ready, execute]);


    useEffect(() => {
        if (response) {
            setFinished(true);
            if (Array.isArray(response)) {
                const documents = response as RatingFeedPublishSession[];
                if (documents.length === 0) {
                    return;
                }

                updateSession(documents);

                documents.forEach((data) => {
                    props.getPublishModes(((preMode: any) => [...preMode, data?.publishMode]) as any);
                });
            } else {
                const document = response as RatingFeedPublishSession;
                updateSession([document]);

                props.getPublishModes(((preMode: any) => [...preMode, document?.publishMode]) as any);
            }

            if (statusMessage) {
                setStatusMessage(null);
            }
        }
    }, [response]);

    useEffect(() => {
        if (error) {
            setStatusMessage(error);
        }
    }, [error]);

    const [items, updateItems] = useState<RatingFeedPublishSession[]>(session);

    useEffect(() => {
        updateItems(session);
    }, [session]);

    return (
        <div>
            {isLoading && (<Spinner label="Loading..." />)}
            {items.length !== 0 && <DetailsList
                items={items}
                compact={false}
                columns={columns}
                selectionMode={SelectionMode.none}
                setKey="multiple"
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                selectionPreservedOnEmptyClick={true}
                enterModalSelectionOnTouch={true}
                ariaLabelForSelectionColumn="Toggle selection"
                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                checkButtonAriaLabel="select row"
            />}
            <StatusMessageBar message={statusMessage} isMultiline />
        </div>
    );
}
