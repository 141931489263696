import { IStackTokens, Stack } from '@fluentui/react';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { PrimaryButton } from '@fluentui/react/lib/Button';
import { DetailsList, DetailsListLayoutMode, IColumn, SelectionMode } from '@fluentui/react/lib/DetailsList';
import { mergeStyles } from '@fluentui/react/lib/Styling';
import React, { useState } from 'react';
import { PublishState } from '../models/rateCard';
import { useApi, ApiParameters } from '../hooks/useApi';
import devOpsApi, { GetDocumentConversionCompletionEventRequest, GetPublishStateEntityRequest, GetRateCardDocumentRequest } from '../services/devOpsApiClient';
import { StatusMessage, StatusMessageBar } from '../statusMessageBar';

const buttonClass = mergeStyles({
    display: 'block',
    marginTop: '10px',
    marginBottom: '10px',
});
  
const stackTokens: IStackTokens = {
    childrenGap: 40
};

export interface RateCardListProps {
    documentId: string,
    revisionId: string,
}

export function RateCardView(props: RateCardListProps) {
    const [publishStates, setPublishStates] = useState<PublishState[]>([]);
    const [items, setItems] = useState<PublishState[]>([]);
    const [downloading, setDownloading] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [requestStage, setRequestStage] = useState("");
    const [apiParameters, setApiParameters] = useState<ApiParameters[]>([]);
    const [ready, response, isLoading, error, execute] = useApi<any>();
    const [productBigId, setProductBigId] = useState("");
    const [statusMessage, setStatusMessage] = useState<StatusMessage | null>(null);
    const noValue = "n/a";

    const columns: IColumn[] = [
        {
            key: 'responseTypeColumn',
            name: 'Response Type',
            fieldName: 'responseType',
            minWidth: 250,
            maxWidth: 250,
            isResizable: true,
            data: 'string',
            isPadded: true,
        },
        {
            key: 'responseIdColumn',
            name: 'Response Id',
            fieldName: 'responseId',
            minWidth: 200,
            maxWidth: 200,
            isResizable: true,
            data: 'string',
            isPadded: true,
        },
        {
            key: 'lastModifiedDateTimeColumn',
            name: 'Last Modified Time',
            fieldName: 'lastModifiedDateTime',
            minWidth: 150,
            maxWidth: 150,
            isResizable: true,
            data: 'string',
            onRender: (item: PublishState) => {
                return <span>{item.lastModifiedDateTime}</span>;
            },
            isPadded: true,
        },
        {
            key: 'responseHashColumn',
            name: 'Response Hash',
            fieldName: 'responseHash',
            minWidth: 250,
            isResizable: true,
            data: 'string',
            isPadded: true,
        },
    ];

    React.useEffect(() => {
        setRequestStage("getResponseType");
        setApiParameters([devOpsApi.getResponseType()]);
    }, [props]);

    React.useEffect(() => {
        if (ready)
        {
            execute(apiParameters);
        }
    }, [apiParameters, ready, execute]);

    React.useEffect(() => {
        if (response)
        {
            if (requestStage === "getResponseType")
            {
                updateResponseTypeAndSetNextRequest(response);
            }
            else if (requestStage === "getDocumentConversionCompletionEvent")
            {
                updateDocumentConversionCompletionEventAndSetNextRequest(response);
            }
            else if (requestStage === "getPublishStateEntity")
            {
                updatePublishStateEntity(response);
            }
            else if (requestStage === "getRateCardDocument")
            {
                downloadRateCardDocument(response);
            }

            if (statusMessage) {
                setStatusMessage(null);
            }
        }
    }, [response]); // eslint-disable-line react-hooks/exhaustive-deps


    React.useEffect(() => {
        if (error) {
            const errorMessage = {type: error.type, message: preProcessErrorMessage(error.message)} as StatusMessage;
            if (errorMessage.message !== "")
            {
                setStatusMessage(errorMessage);
            }
        }
    }, [error]);

    React.useEffect(() => {
        setDisabled(items.findIndex(i => i.responseHash !== noValue) === -1);
    }, [items]);

    return (
        <div>
            {isLoading && !downloading && (<Spinner label="Loading..." />)}
            <DetailsList
                items={items}
                compact={false}
                columns={columns}
                selectionMode={SelectionMode.none}
                setKey="multiple"
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                selectionPreservedOnEmptyClick={true}
                enterModalSelectionOnTouch={true}
                ariaLabelForSelectionColumn="Toggle selection"
                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                checkButtonAriaLabel="select row"
            />
            <StatusMessageBar message={statusMessage} isMultiline />
            {items.length > 0 &&
                (<Stack horizontal tokens={stackTokens}>
                    <PrimaryButton text="Download" onClick={getRateCardDocument} allowDisabledFocus disabled={disabled} className={buttonClass} />
                    {downloading && (<Spinner label="Downloading..." size={SpinnerSize.medium} labelPosition="left" />)}
                </Stack>)
            }
        </div>
    );

    function updateResponseTypeAndSetNextRequest(responseItems: string[]): void {
        let tempPublishStates = publishStates;
        responseItems.forEach((element: string) => {
            tempPublishStates.push({
                responseType: element,
                responseId: noValue,
                lastModifiedDateTime: noValue,
                responseHash: noValue,
            });
        });
        setPublishStates(tempPublishStates);
        
        let apiParams = new Array<ApiParameters>();
        tempPublishStates.forEach(element => {
            const request = {
                documentId: props.documentId,
                revisionId: props.revisionId,
                responseType: element.responseType,
            } as GetDocumentConversionCompletionEventRequest;
            apiParams.push(devOpsApi.getDocumentConversionCompletionEvent(request));
        });
        setRequestStage("getDocumentConversionCompletionEvent");
        setApiParameters(apiParams);
    }

    function updateDocumentConversionCompletionEventAndSetNextRequest(responseItems: any[]): void {
        let tempPublishStates = publishStates;
        tempPublishStates.forEach(element => {
            const item = responseItems.find(r => r?.responseType === element.responseType);
            if (item !== undefined)
                element.responseId = item.responseId;
        });
        setPublishStates(tempPublishStates);
        
        let apiParams = new Array<ApiParameters>();
        tempPublishStates.forEach(element => {
            if (element.responseId !== noValue)
            {
                const request = {
                    responseId: element.responseId,
                    responseType: element.responseType,
                    isTest: "false",
                } as GetPublishStateEntityRequest;
                apiParams.push(devOpsApi.getPublishStateEntity(request));
            }
        });
        if (apiParams.length > 0)
        {
            setRequestStage("getPublishStateEntity");
            setApiParameters(apiParams);
        }
        else
        {
            setItems(publishStates);
        }
    }

    function updatePublishStateEntity(responseItems: any[]): void {
        let tempPublishStates = publishStates;
        tempPublishStates.forEach(element => {
            const item = responseItems.find(r => r?.responseType === element.responseType);
            if (item !== undefined)
            {
                element.lastModifiedDateTime = new Date(item.lastModifiedDateTime).toLocaleString([], { hour12: false });
                element.responseHash = item.responseHash;
            }
        });
        setPublishStates(tempPublishStates);
        setItems(publishStates);
    }

    function getRateCardDocument(): void {
        downloadBegin();
        let productBigId = "";
        let serviceBigId = "";
        items.forEach(element => {
            if (element.responseId !== "" && (element.responseType === "ProductResponse" || element.responseType === "OfferingMappingResponse"))
            {
                productBigId = element.responseId;
            }
            else if (element.responseType === "ServiceResponse")
            {
                serviceBigId = element.responseId;
            }
        });
        setProductBigId(productBigId);
        const request = {
            productBigId: productBigId,
            serviceBigId: serviceBigId,
            revisionId: props.revisionId,
        } as GetRateCardDocumentRequest;
        setRequestStage("getRateCardDocument");
        setApiParameters([devOpsApi.getRateCardDocument(request)]);
    }

    function downloadRateCardDocument(responseBlob: Blob): void {
        const link = document.createElement('a');
        link.style.display = 'none';
        link.href = URL.createObjectURL(responseBlob);
        link.download = "RateCard_OfferStore_" + productBigId + "_" + props.revisionId + ".zip";
        document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(link.href);
        document.body.removeChild(link);
        downloadEnd();
    }

    function downloadBegin(): void {
        setDisabled(true);
        setDownloading(true);
    }

    function downloadEnd(): void {
        setDownloading(false);
        setDisabled(false);
    }

    function preProcessErrorMessage(message: string): string {
        return message.split(",").filter(str => str.indexOf("status: 404") === -1).join(",");
    }
}
