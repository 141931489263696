import { EndpointType, getEndpoint } from "../../config/endpointConfig";
import { ApiParameters } from "../hooks/useApi";

function getBaseUrl() {
  return getEndpoint(EndpointType.ExternalApi);
}

function getSwaggerUrl() {
  return getEndpoint(EndpointType.ExternalApiSwagger);
}


export interface GetManifestDocRequest {
  revisionId:string;
  queryOptions: { PageSize: number; continuationToken: string;};
}



export interface QueryDocAsyncRequest {
  productId: string;
  revisionId: string;
  pageSize: number;
  queryOptions: { PageSize: number; DocumentTypes: Array<string>; continuationToken: string;};
}




const queryDocumentsAsync = (request: QueryDocAsyncRequest) => {
  var url = new URL(
    `${getBaseUrl()}/api/v2.0/documents/ProductIntents/${request.productId}/${
      request.revisionId
    }`
  );
  url.searchParams.set("PageSize", request.queryOptions.PageSize.toString());
  var jsondata = JSON.stringify(request.queryOptions.DocumentTypes);
  if (request.queryOptions.continuationToken !== undefined) {
    url.searchParams.set(
      "ContinuationToken",
      request.queryOptions.continuationToken.toString()
    );
  }
  url.searchParams.set("IncludeBase", "true");
  url.searchParams.append("includeDocumentTypes", jsondata);
  console.log("[ExternalApi]getting Doc from " + url);
  return { uri: url.toString(), method: "GET" } as ApiParameters;
};


const queryManifestDocAsync = (request: GetManifestDocRequest) => {
  var url = new URL(
    `${getBaseUrl()}/api/v1.0/ContentArtifacts/revision/${
      request.revisionId
    }`
  );
  url.searchParams.set("PageSize", request.queryOptions.PageSize.toString());
  if (request.queryOptions.continuationToken !== undefined) {
    console.log("token",request.queryOptions.continuationToken)
    url.searchParams.set(
      "ContinuationToken",
      request.queryOptions.continuationToken.toString()
    );
  }
  console.log("[ExternalApi]getting Doc from " + url);
  return { uri: url.toString(), method: "GET" } as ApiParameters;
};

const getSwagger = () => {
  var url = new URL(`${getSwaggerUrl()}`);
  return { uri: url.toString(), method: "GET" } as ApiParameters;
};

const interfaces = {
  getSwagger,
  queryManifestDocAsync,
  queryDocumentsAsync
};
export default interfaces;
