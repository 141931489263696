import { IButtonStyles, IIconProps, ISearchBoxStyles, IStackTokens, PrimaryButton, SearchBox, Stack } from '@fluentui/react';
import React, { useState } from 'react';
import { LayoutStyles } from '../styles/layout';
import { listNameEnum, SearchListProps, SessionListView } from './session/sessionListView';

export interface IIntentsViewProps {

}

export function IntentsView(props: IIntentsViewProps) {
    const [searchval, SetVal] = useState("");
    const [buttonVal, SetButtonVal] = useState("");
    const stackTokens: Partial<IStackTokens> = { childrenGap: 20 };
    const searchBoxStyles: Partial<ISearchBoxStyles> = {
        root: {
            width: 650,
            borderRadius: "10px 0 0 10px",
            border: "2px solid #c4c7ce",
            height: 40,
            position: "relative",
            left: 82,
            top: 11,
            selectors: {
                ":after": {
                    borderRadius: "10px 0 0 10px",
                    border: "1px solid #c4c7ce",
                },
                ":hover,:focus": {
                    border: "2px solid #7d9eeb",
                },
            },
        },
    };
    const searchButtonStyles: Partial<IButtonStyles> = {
        root: {
            width: 100,
            height: 40,
            borderRadius: "0 10px 10px 0",
            position: "relative",
            left: 730,
            top: -49,
            fontSize: 16,
        },
    };
    const addSearchIcon: IIconProps = { iconName: "Search" };
    return (
        <div style={LayoutStyles.Main}>
            <Stack tokens={stackTokens}>
                <SearchBox
                    placeholder="Search"
                    onChange={(e, val) => {
                        SetVal((val as string) || "");
                    }}
                    name="Search"
                    autoComplete="on"
                    onSearch={(newValue) => SetVal(newValue)}
                    styles={searchBoxStyles}
                />
                <PrimaryButton
                    iconProps={addSearchIcon}
                    text="Search"
                    styles={searchButtonStyles}
                    onClick={handleClick}
                    allowDisabledFocus
                />
                <SessionListView
                    documentId={searchval}
                    searchList={{ documentId: searchval } as SearchListProps}
                    listName={listNameEnum.intentsView}
                />
            </Stack>
        </div>
    );
    function handleClick() {
        SetButtonVal(searchval);
        console.log("Searching.. documentid=" + searchval);
    }
}
