import { DefaultButton, Dropdown, IDropdownOption, IDropdownStyles, PrimaryButton, Stack, StackItem, TextField } from "@fluentui/react";
import React, { useEffect } from "react";
import { useState } from "react";
import { ApiParameters, useApi } from "../../hooks/useApi";
import { SuppresionRuleStateScope, SuppressionRuleState, SuppressionRuleStateFeedType, SuppressionRuleStateRequest } from "../../models/suppressionRule";
import devOpsApi, { AddOrEditSuppressionRuleEvent } from "../../services/devOpsApiClient";
import { StatusMessage } from "../../statusMessageBar";
import { useMsal } from "@azure/msal-react";
import { appInsights } from "../../../config/telemetryConfig";
import { Environment, getEnvironment } from "../../../config/environmentConfig";

const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: 300 },
};

const docTypeOptions: IDropdownOption[] = [
    { key: 'Product;1', text: 'Product', },
    { key: 'Promotion;1', text: 'Promotion', },
    { key: 'MeterMapping;1', text: 'MeterMapping', },
    { key: 'ProductMeterRates;1', text: 'ProductMeterRates', },
    { key: 'PromotionCollection;1', text: 'PromotionCollection', },
    { key: 'ProductPriceApplicability;1', text: 'ProductPriceApplicability', },
];

const docTypeRateCardOptions: IDropdownOption[] = [
    { key: 'ProductResponse;1', text: 'ProductResponse', },
    { key: 'OfferingMappingResponse;1', text: 'OfferingMappingResponse', },
    { key: 'ServiceFamilyResponse;1', text: 'ServiceFamilyResponse', },
    { key: 'ServiceResponse;1', text: 'ServiceResponse', },
];

export interface AddOrEditSuppressionRuleViewProps {
    feedType: SuppressionRuleStateFeedType,
    scope?: SuppresionRuleStateScope,
    suppressionRuleState?: SuppressionRuleState,
    onAddOrEditSuccess: (suppressionRuleState: SuppressionRuleState) => void,
    onAddOrEditCancel: () => void,
}

export function AddOrEditSuppressionRuleView(props: AddOrEditSuppressionRuleViewProps) {
    const { accounts } = useMsal();
    const loggedInUserAlias = accounts[0] && accounts[0].username.replace('@pme.gbl.msidentity.com', '');

    const { feedType, scope, suppressionRuleState, onAddOrEditSuccess, onAddOrEditCancel } = props;

    const isAddOperation: boolean = suppressionRuleState === undefined;

    const [apiParameters, setApiParameters] = useState<ApiParameters[] | undefined>(undefined);
    const [ready, response, isLoading, error, execute] = useApi<any>();
    const [statusMessage, setStatusMessage] = useState<StatusMessage | null>(null);

    const [docType, setDocType] = React.useState<IDropdownOption | undefined>(suppressionRuleState ? (feedType == SuppressionRuleStateFeedType.RatingFeed ? docTypeOptions.filter(r => r.key === suppressionRuleState.documentType)[0] : docTypeRateCardOptions.filter(r => r.key === suppressionRuleState.documentType)[0]) : undefined);
    const [ruleName, setRuleName] = React.useState<string>(suppressionRuleState ? suppressionRuleState.validationRuleName : '');
    const [comment, setComment] = React.useState<string>(suppressionRuleState ? suppressionRuleState.comments : '');
    const [suppressedDocIds, setSuppressedDocIds] = React.useState<string>(suppressionRuleState ? suppressionRuleState.suppressedDocumentIds.join(',') : '');

    useEffect(() => {
        if (ready && apiParameters) {
            execute(apiParameters);
        }
    }, [apiParameters, ready]);

    useEffect(() => {
        if (response) {
            const message = { type: "success", message: JSON.stringify(response) } as StatusMessage;
            setStatusMessage(message);

            appInsights.trackEvent({
                name: isAddOperation ? "AddSuppressionRuleState" : "EditSuppressionRuleState",
                properties: {
                    EventData: JSON.stringify(apiParameters),
                    Environment: Environment[getEnvironment()],
                    Response: JSON.stringify(response),
                },
            });

            onAddOrEditSuccess(response as SuppressionRuleState);

            if (statusMessage) {
                setStatusMessage(null);
            }
        }
    }, [response]);

    useEffect(() => {
        if (error) {
            const errorMessage = { type: error.type, message: error.message } as StatusMessage;
            if (errorMessage.message !== "") {
                setStatusMessage(errorMessage);
            }
        }
    }, [error]);

    const _onDocTypeChange = (event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption): void => {
        setDocType(item);
    };

    const _onRuleNameChange = React.useCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            setRuleName(newValue || '');
        },
        [],
    );

    const _onCommentChange = React.useCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            setComment(newValue || '');
        },
        [],
    );

    const _onSuppressedDocIdsChange = React.useCallback(
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            setSuppressedDocIds(newValue || '');
        },
        [],
    );

    return (
        <Stack>
            <StackItem>
                <Dropdown
                    placeholder="Select document type"
                    label="Document Type"
                    selectedKey={docType ? docType.key : undefined}
                    onChange={_onDocTypeChange}
                    options={feedType == SuppressionRuleStateFeedType.RatingFeed ? docTypeOptions : docTypeRateCardOptions}
                    styles={dropdownStyles}
                    disabled={isAddOperation ? false : true}
                />
            </StackItem>
            <StackItem>
                <TextField
                    name="ruleName"
                    label="Validation Rule Name"
                    value={ruleName}
                    onChange={_onRuleNameChange}
                    readOnly={isAddOperation ? false : true}
                    disabled={isAddOperation ? false : true}
                />
            </StackItem>
            <StackItem>
                <TextField
                    name="suppressedDocIds"
                    label="Suppressed document Ids (comma separated values)"
                    multiline
                    rows={3}
                    autoAdjustHeight
                    value={suppressedDocIds}
                    onChange={_onSuppressedDocIdsChange}
                />
            </StackItem>
            <StackItem>
                <TextField
                    name="comment"
                    label="Comments"
                    multiline
                    rows={3}
                    autoAdjustHeight
                    value={comment}
                    onChange={_onCommentChange}
                />
            </StackItem>
            <StackItem>
                <>&nbsp;&nbsp;</>
            </StackItem>
            <StackItem>
                <PrimaryButton text={isAddOperation ? "Add" : "Update"} allowDisabledFocus onClick={_addOrEditBtnClickHandler} />
                <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                <DefaultButton text="Cancel" allowDisabledFocus onClick={_onCancelBtnClickHandler} />
            </StackItem>
        </Stack>
    );

    function _onCancelBtnClickHandler() {
        onAddOrEditCancel();
    }

    function _addOrEditBtnClickHandler(): void {
        let addOrEditRequest: SuppressionRuleStateRequest = {
            documentType: docType ? '' + docType.key : '',
            validationRuleName: ruleName.trim(),
            suppressedDocumentIds: suppressedDocIds.split(',').map(docId => docId.trim()).filter(docId => docId && docId !== ''),
            comments: comment.trim(),
            createdByAlias: loggedInUserAlias,
        }

        console.log(addOrEditRequest);

        var errMessages = _validateSuppressionRule(addOrEditRequest);
        if (errMessages.length > 1) {
            alert(errMessages.join('\n \u2022 '));
            return;
        }

        let addOrEditRequestEvent = {
            body: addOrEditRequest,
        } as AddOrEditSuppressionRuleEvent;

        if (isAddOperation)
            setApiParameters([devOpsApi.getAddSuppressionRule(feedType, addOrEditRequestEvent, scope)]);
        else
            setApiParameters([devOpsApi.getEditSuppressionRule(feedType, addOrEditRequestEvent, scope)]);
    }

    function _validateSuppressionRule(rule: SuppressionRuleStateRequest): string[] {
        var errMsg: string[] = ["Suppression rule validation error"];

        if (rule.documentType === '') {
            errMsg.push("Document type cannot be empty");
        }

        if (rule.validationRuleName === '') {
            errMsg.push("Validation rule name cannot be empty");
        }

        if (rule.validationRuleName.indexOf(' ') >= 0) {
            errMsg.push("ValidationRuleName should not contain empty space.")
        }

        if (rule.suppressedDocumentIds.length === 0) {
            errMsg.push("Suppression document ids cannot be empty");
        }

        if (comment && comment.length > 150) {
            errMsg.push("Comment should be less than or equal to 150 characters.")
        }

        return errMsg;
    }
}