import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated, useMsal } from "@azure/msal-react";
import "@fluentui/react/dist/css/fabric.css";
import React, { useCallback } from "react";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import { Header } from "./components/header";
import { PageRouter } from "./components/pageRouter";
import { loginRequest, msalConfig, msalConfigPME, useIsLoginEnforced } from "./config/authConfig";
import { PrimaryButton, Stack } from "@fluentui/react";
import '../src/components/styles/login.css';
import { PublicClientApplication } from "@azure/msal-browser";
import { Console } from "console";
import { set } from "lodash";
export const App: React.FunctionComponent = () => {
  const { instance } = useMsal();
  const [isDisplay, setIsDisplay] = React.useState(false);
  const [message, setMessage] = React.useState<string>("");


  function ReLoad() {
    localStorage.clear();
    window.location.href = "/";
  }

  function handleLogin(param:string){
    if (param === "pme") {
      localStorage.setItem("msal", "pme");
      new PublicClientApplication(msalConfigPME).loginRedirect(loginRequest)
      .then(response => {
     
        console.log('Logged in:', response);
      })
      .catch(error => {
        setIsDisplay(true);
        console.error('Failed to log in:', error);
        setMessage(error);
      });
  }
  else{
    localStorage.setItem("msal", "ms");
      new PublicClientApplication(msalConfig).loginRedirect(loginRequest)
      .then(response => {
        console.log('Logged in:', response);
      })
      .catch(error => {
        setIsDisplay(true);
        console.error('Failed to log in:', error);
        setMessage(error);
      });
}
  }
  return (
    <><AuthenticatedTemplate>
      <BrowserRouter>
        <Header></Header>
        <PageRouter />
      </BrowserRouter>
    </AuthenticatedTemplate>
      <UnauthenticatedTemplate>  
        <Stack horizontalAlign="center" verticalAlign="center" verticalFill>
        <div className="arc-login-form-container">
        <h2 className='h2-style'>Login</h2>
        <Stack tokens={{ childrenGap: 30 }}>
      <PrimaryButton className="button-style" onClick={()=>handleLogin("pme")}>pme account</PrimaryButton>
      <PrimaryButton className="button-style" onClick={()=>handleLogin("ms")}>microsoft account</PrimaryButton>
      {isDisplay && 
      <div>
        <p className="error-style">error:{message}!please ReLoad diff account</p>
      <PrimaryButton className="button-style" onClick={ReLoad}>ReLoad</PrimaryButton>
      </div>
      }
      </Stack>
    </div>
    </Stack>
      </UnauthenticatedTemplate></>
  );
};
