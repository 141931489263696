import React from 'react'
import { DefaultButton, IButtonStyles, PrimaryButton, Spinner } from "@fluentui/react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useApi } from "../hooks/useApi";
import { DimensionData } from "../models/dimensionData";
import externaleApi, { QueryDocAsyncRequest } from "../services/externalApiClient";
import { DimensionDropdown } from "../sessions/dimensionDropdown";
import { ProductNav } from "../sessions/productNav";
import { SubDocumentView } from "../sessions/subDocumentView";
import { Stack, IStackProps, IStackStyles } from '@fluentui/react/lib/Stack';
import { StatusMessage, StatusMessageBar } from '../statusMessageBar';
import { delay } from 'lodash';
import { resolve } from 'path';
import { DocmentType } from '../models/productDocument';

const stackStyles: Partial<IStackStyles> = { root: { width: 1500 } };
const stackTokens = { childrenGap: 50 };
const columnLeftProps: Partial<IStackProps> = {
    tokens: { childrenGap: 15 },
    styles: { root: { width: 420 } },
};
const columnRightProps: Partial<IStackProps> = {
    tokens: { childrenGap: 15 },
    styles: { root: { width: 1080, height: 750 } },
};
const buttonStyle: IButtonStyles = {
    root: {
        marginBottom: 20,
        width: 100,
        height: 40,
        float: "right",
        marginRight: 10
    }
}

export interface PermutationKeys {
    dimension: string,
    child: KeyValue[]
}
export interface KeyValue {
    key: string,
    data: any,
    text: string
}
export interface PermuationIndexs {
    dimension: string,
    stringKey: string,
    child: KeyValue[],
}
export enum schemaVersionEnum {
    schemaVersion = "0.0.2",
}
export enum dimensionEnum {
    IsEdgeZoneDimension = "IsEdgeZoneDimension",
    LifecycleStateDimension = "LifecycleStateDimension",
    ReservationDurationDimension = "ReservationDurationDimension",
    EligibilityDefinitionDimension = "EligibilityDefinitionDimension",
    BillingPlanDimension = "BillingPlanDimension",
    SalesMotionTypeDefinitionDimension = "SalesMotionTypeDefinitionDimension",
    ProcessorCoresDimension = "ProcessorCoresDimension",
    SubscriptionTermDimension = "SubscriptionTermDimension"
}
export enum viewTypeEnum {
    SubDocument = "SubDocument",
    CompareSubDocument = "CompareSubDocument",
    ReVisionSubDocument = "ReVisionSubDocument",

}

export function PermutationsView() {
    const params = useParams();
    const [docType, SetdocType] = useState<string>();
    const documentId = params.documentId;
    const revisionId = params.sessionId;
    const pageSize = 1000;
    const [jsonArray, setJsonArray] = useState<any[]>();
    const [dimensionsList, setdimensionsList] = useState<DimensionData[]>();
    const [ready, response, isLoading, error, execute] = useApi<any>();
    const [dimensionsName, SetdimensionsName] = useState<string[]>();
    const [getJson, SetGetJson] = useState<any>();
    const [getCompareJson, SetCompareGetJson] = useState<any>();
    const [getReVisionJson, SetReVisionGetJson] = useState<any>();
    const [request, updateRequest] = useState<QueryDocAsyncRequest>({} as QueryDocAsyncRequest);
    const [subDocument, setSubDocument] = useState<[string[] | undefined, StatusMessage | null, boolean, viewTypeEnum, boolean]>([undefined, null, false, viewTypeEnum.SubDocument, true]);
    const [compareSubDocument, setCompareSubDocument] = useState<[string[] | undefined, StatusMessage | null, boolean, viewTypeEnum, boolean, boolean]>([undefined, null, false, viewTypeEnum.CompareSubDocument, false, false]);
    const [reVisionsubDocument, setReVisionSubDocument] = useState<[string[] | undefined, StatusMessage | null, boolean, viewTypeEnum, boolean]>([undefined, null, false, viewTypeEnum.ReVisionSubDocument, true]);
    const [showReVision, setShowRevision] = useState(false);
    const [statusMessage, setStatusMessage] = useState<StatusMessage | null>(null);
    useEffect(() => {
        const request = {
            productId: documentId,
            revisionId: revisionId,
            queryOptions: {
                PageSize: 1000,
                DocumentTypes: [DocmentType.permutations,DocmentType.compressedPermutations],
              }
        } as QueryDocAsyncRequest;

        updateRequest(request);
    }, [documentId, revisionId]);


    useEffect(() => {
        if (ready) {
            execute([externaleApi.queryDocumentsAsync(request)]);
        }
    }, [request, ready]);

    useEffect(() => {
        if (response) {
            if (response.Count === 0 ) {
                if (response.ContinuationToken !== null) {
                    GetPermutationForRequest(response);
                }
                else {
                    setStatusMessage({ message: "Permutation Not  found", type: "error" });
                    return;
                }
        
            }
            else {
                let data: string[] = response.Items.$values.map((element: any) => 
                    element.PermutationIndexes.$values
                ).reduce((acc: string[], val: string[]) => acc.concat(val), []);
                setJsonArray(data);
                let dimList = getPermutationKeys(response.Items.$values[0]);
                setdimensionsList(dimList);
                let array: any[] = [];
                data.forEach(el => {
                    array.push(el.split(','));
                })
                let list: any[] = [];
                array.sort(compare).forEach(el => {
                    let listChild: any[] = []
                    for (let i = 0; i < dimList.length; i++) {

                        let element: any[] = [];

                        if ((dimList.length > el.length && i <= el.length) || (dimList.length === el.length)) {

                            for (let j = 0; j < el.length; j++) {

                                element = dimList[i].child.filter(o => o.key == el[j]);
                                if (element.length > 0) {

                                    break;
                                }
                            }
                            if (element.length > 0) {
                                listChild.push(element[0])
                            }
                            else {
                                listChild.push(dimList[i].child[0])
                            }
                        }
                        else if (dimList.length > el.length && i > el.length) {
                            listChild.push(dimList[i].child[0])
                        }
                    }
                    list.push(listChild);
                })
                let type: any[] = [];
                dimList.forEach(el => {
                    type.push(el.dimension);
                })
                SetdimensionsName(type);
                setJsonArray(list);
            }
            if (statusMessage) {
                setStatusMessage(null);
            }
        }
    }, [response]);

    useEffect(() => {
        if (error) {
            setStatusMessage(error);
        }
    }, [error]);
    return (
        <Stack>

            <div>
                <StatusMessageBar message={statusMessage} isMultiline />
                {statusMessage == null?(
                <div>
                <Stack horizontal wrap>
                    {
                        dimensionsList !== undefined && jsonArray !== undefined ? (<DimensionDropdown showReVision={(data: boolean) => { setShowRevision(data) }} docType={docType as string} productId={documentId as string} revisionId={revisionId as string} jsonData={jsonArray as any[]} permutationKeys={dimensionsList as DimensionData[]} dimensionsName={dimensionsName as string[]} getJsonData={(data: any) => { SetGetJson(data); }} setRevisionSubDocument={(text: string[], error: StatusMessage, isLoading: boolean, viewTypeEnum: viewTypeEnum, isDisplay: boolean) => { setReVisionSubDocument([text, error, isLoading, viewTypeEnum, isDisplay]); }} getcompareJsonData={(data: any) => { SetCompareGetJson(data); }} />) : (<Spinner label="Loading..." />)
                    }
                </Stack>
                <Stack horizontal tokens={stackTokens} styles={stackStyles}>
                    <Stack {...columnLeftProps}>
                        {documentId !== undefined && revisionId !== undefined && (<ProductNav DocumentType={(doctype: string) => { SetdocType(doctype); }} productId={documentId} revisionId={revisionId} selectedDimensionPermutation2={getCompareJson} selectedDimensionPermutation={getJson} setSubDocument={(text: string[], error: StatusMessage, isLoading: boolean, viewTypeEnum: viewTypeEnum, isDisplay: boolean) => { setSubDocument([text, error, isLoading, viewTypeEnum, isDisplay]); }} setCompareSubDocument={(text: string[], error: StatusMessage, isLoading: boolean, viewTypeEnum: viewTypeEnum, isDisplay: boolean, isDeffult: boolean) => { setCompareSubDocument([text, error, isLoading, viewTypeEnum, isDisplay, isDeffult]); }} />)}
                    </Stack>
                    <Stack {...columnRightProps}>
                        <SubDocumentView subDocumentContent={subDocument[0]} errorMessage={subDocument[1]} isLoading={subDocument[2]} viewType={viewTypeEnum.SubDocument} isDisplay={true} isDeffult={false} />
                    </Stack>

                    {
                        getCompareJson !== undefined ? (
                            <Stack  {...columnRightProps}>

                                <SubDocumentView subDocumentContent={compareSubDocument[0]} errorMessage={compareSubDocument[1]} isLoading={compareSubDocument[2]} viewType={viewTypeEnum.CompareSubDocument} isDisplay={compareSubDocument[4]} isDeffult={compareSubDocument[5]} />
                                <div style={{ height: 60 }}>
                                    <DefaultButton styles={buttonStyle} onClick={() => { SetCompareGetJson(undefined); }}>close</DefaultButton>
                                </div>
                            </Stack>) : ("")
                    }

                    {
                        showReVision === true ? (
                            <Stack  {...columnRightProps}>
                                {
                                    reVisionsubDocument !== undefined ? (

                                        <SubDocumentView subDocumentContent={reVisionsubDocument[0]} errorMessage={reVisionsubDocument[1]} isLoading={reVisionsubDocument[2]} viewType={viewTypeEnum.ReVisionSubDocument} isDisplay={true} isDeffult={false} />) : (<Spinner label="Loading..." />)}
                                <div style={{ height: 60 }}>
                                    <DefaultButton styles={buttonStyle} onClick={() => { setShowRevision(false) }}>close</DefaultButton>
                                </div>
                            </Stack>) : ""
                    }

                </Stack>
                </div>):""}
            </div>
        </Stack>
    );
    function compare(value1: any[], value2: any[]) {
        if (value1.length < value2.length) { return 1; }
        else if (value1.length > value2.length) { return -1; }
        else { return 0 }
    }
    function getPermutationKeys(response: any) {
        let permutationKeys: PermutationKeys[] = [];
        for (let i in response["PermutationKeys"]) {
            let obj: PermutationKeys = {
                dimension: "",
                child: []
            };
            if (i !== "$type") {
                obj.dimension = i.split('.')[i.split('.').length - 1];
                for (let j in response["PermutationKeys"][i]) {
                    if (j !== "$type") {
                        let value: string = "";
                        for (let k in response["PermutationKeys"][i][j]) {
                            if (k !== "$type") {
                                switch (obj.dimension) {
                                    case dimensionEnum.LifecycleStateDimension:
                                        value = value + j + ":" + response["PermutationKeys"][i][j][k].EligibilityType;
                                        break;
                                    case dimensionEnum.ReservationDurationDimension:
                                    case dimensionEnum.SubscriptionTermDimension:
                                        value = response["PermutationKeys"][i][j][k].Length + " " + response["PermutationKeys"][i][j][k].Unit;
                                        break;
                                    case dimensionEnum.EligibilityDefinitionDimension:
                                        value = response["PermutationKeys"][i][j][k].$values[0].DurableId;
                                        break;
                                    case dimensionEnum.BillingPlanDimension:
                                    case dimensionEnum.SalesMotionTypeDefinitionDimension:
                                        value = response["PermutationKeys"][i][j][k].DurableId;
                                        break;
                                    case dimensionEnum.ProcessorCoresDimension:
                                        value = "cores:" + response["PermutationKeys"][i][j][k];
                                        break;
                                    default: value = value + "" + response["PermutationKeys"][i][j][k];
                                        break;

                                }
                            }
                        }
                        if (obj.child.length === 0) {

                            obj.child.push({ key: "N/A", data: { "$type": response["PermutationKeys"][i][j].$type }, text: "N/A" });
                        }

                        obj.child.push({ key: j, data: response["PermutationKeys"][i][j], text: value });
                    }
                }

                permutationKeys.push(obj);
            }
        }

        return permutationKeys;
    }
    function sleep(ms: number): Promise<void> {
        return new Promise<void>((resolve) => {
            setTimeout(resolve, ms)
        })
    }
    async function GetPermutationForRequest(responseItems: any) {
        let requestQueryPermutation = {
            productId: documentId,
            revisionId: revisionId,
            queryOptions: {
                PageSize: 1000,
                DocumentTypes: [DocmentType.permutations,DocmentType.compressedPermutations],
                continuationToken: responseItems.ContinuationToken
              }

        } as QueryDocAsyncRequest

        if (ready) {
            await sleep(0.2)
            execute([externaleApi.queryDocumentsAsync(requestQueryPermutation)]);
        }
    }
}
