import React from 'react'
import { FontWeights, ILinkStyles, Spinner, DefaultButton } from '@fluentui/react';
import { SpinnerSize } from '@fluentui/react/lib/Spinner';
import { useState, useEffect } from 'react';
import { StatusMessage, StatusMessageBar } from '../statusMessageBar';
import { useApi, ApiParameters } from '../hooks/useApi';
import pcdApiClient, { GetPcdRequest } from '../services/pcdApiClient';
import filer from 'file-saver';

export interface PcdDownLoadListProps {
    documentId: string,
    revisionId: string,
}

export function PcdDownloadButton(props: PcdDownLoadListProps) {
    const [ready, response, isLoading, error, execute] = useApi<any>();
    const [downloadReady, setDownloadReady] = useState(false);
    const [apiParameters, setApiParameters] = useState<ApiParameters[]>([]);
    const [statusMessage, setStatusMessage] = useState<StatusMessage | null>(null);

    useEffect(() => {
        if (response && downloadReady) {
            if (response.length === 0) {
                return;
            }
            downloadDocument(response);

            if (statusMessage) {
                setStatusMessage(null);
            }
        }
    }, [response]);

    useEffect(() => {
        if (error) {
            setStatusMessage(error);
        }
    }, [error]);

    useEffect(() => {
        if (ready && downloadReady) {
            execute(apiParameters);
        }
    }, [apiParameters, ready]);

    return (
        <div>            
            <DefaultButton onClick={downloadPcdData} disabled={isLoading}>Download PCD</DefaultButton>
            {isLoading &&
                (<Spinner size={SpinnerSize.xSmall} label="Please wait..." ariaLive="assertive" labelPosition="right" />)}
            <StatusMessageBar message={statusMessage} isMultiline />
        </div>);


    function downloadDocument(responseBlob: Blob): void {
        const binaryData = [];
        binaryData.push((responseBlob) as any);
        const date = new Date().toJSON().slice(0, 10).replace(/-/g, "");
        filer.saveAs(new Blob((binaryData) as any), `${props.documentId}_${date}.json`);
    }

    function downloadPcdData() {
        const request = {
            documentId: props.documentId,
            revisionId: props.revisionId,
        } as GetPcdRequest;
        setApiParameters([pcdApiClient.getPcdData(request)]);

        setDownloadReady(true);
    }
}