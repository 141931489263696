import React, { useState } from "react";
import { useCookies } from "react-cookie";

// Target OfferStore environment
export enum Environment {
  Local,
  INT,
  PROD,
  AIRCAPI,
  AIRCAPI_INT,
}
const defaultEnvironment: Environment = setDefaultEnvironment(
  process.env.REACT_APP_EnvironmentName!
);
var selectedEnvironment: Environment;

export function useEnvironment() {
  const [cookies, setCookie] = useCookies();
  const [enviroment, updateEnvironment] = useState<Environment>(
    cookies.env || defaultEnvironment
  );

  React.useEffect(() => {
    console.log("env change: " + enviroment);

    selectedEnvironment = enviroment;
    updateEnvironment(enviroment);
    setCookie("env", enviroment);
  }, [enviroment]);

  return [enviroment, updateEnvironment] as const;
}

function setDefaultEnvironment(environmentName: string) {
  switch (environmentName) {
    case "DEVELOPMENT":
      return Environment.Local;
    case "INT":
      return Environment.INT;
    case "AIRCAPI":
      return Environment.AIRCAPI;
    case "AIRCAPI-INT":
      return Environment.AIRCAPI_INT;
    default:
      return Environment.PROD;
  }
}
export function getEnvironment() {
  return selectedEnvironment;
}
