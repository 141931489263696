import React from 'react'
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { mergeStyles } from "@fluentui/react";
import { initializeIcons } from "@fluentui/react/lib/Icons";
import { CookiesProvider } from "react-cookie";
import ReactDOM from "react-dom";
import { App } from "./App";
import { msalConfig, msalConfigPME } from "./config/authConfig";
import { initializeAppInsights } from "./config/telemetryConfig";
import reportWebVitals from "./reportWebVitals";

// Initialize client side tracing
initializeAppInsights();

// Initialize Authentication

const msalInstance = getLoginMsalConfig();


// Initialize Icons
initializeIcons();

// Inject some global styles
mergeStyles({
  ":global(body,html,#root)": {
    margin: 0,
    padding: 0,
    height: "100vh",
  },
});

document.title = "OfferStore Portal";
ReactDOM.render(
  <CookiesProvider>
    <MsalProvider instance={msalInstance}>
      <App />
    </MsalProvider>
  </CookiesProvider>,
  document.getElementById("root")
);

function getLoginMsalConfig():PublicClientApplication {
  switch (localStorage.getItem("msal")) {
    case "pme": 
     return new PublicClientApplication(msalConfigPME);
     default:
      return new PublicClientApplication(msalConfig);
  }
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
