import { Spinner, Stack, StackItem, IStyleSet } from '@fluentui/react';
import { Announced } from '@fluentui/react/lib/Announced';
import { DetailsList, DetailsListLayoutMode, IColumn, Selection, SelectionMode, IDetailsListStyles } from '@fluentui/react/lib/DetailsList';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useApi } from '../../hooks/useApi';
import { getHomeListColumns } from '../../models/homeListColumn';
import { Session } from '../../models/session';
import { getSessionListColumns } from '../../models/sessionListColumn';
import devOpsApi, { GetSessionsRequest } from '../../services/devOpsApiClient';
import { StatusMessage, StatusMessageBar } from '../../statusMessageBar';
import pcdApi, { GetPublishRecordHistoryRequest } from '../../services/pcdApiClient';
import { Pagination } from '../../pagination';
import { getIntentsListColumns } from '../../models/intentsListColumns';
import _ from 'lodash';

const classNames = mergeStyleSets({
    fileIconHeaderIcon: {
        padding: 0,
    },
    fileIconCell: {
        textAlign: 'center',
        selectors: {
            '&:before': {
                content: '.',
                display: 'inline-block',
                verticalAlign: 'middle',
                height: '100%',
                width: '0px',
                visibility: 'hidden',
            },
        },
    },
    fileIconImg: {
        verticalAlign: 'middle',
        maxHeight: '16px',
        maxWidth: '16px',
    },
    controlWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    selectionDetails: {
        marginBottom: '20px',
    },
});

const disableStyle: Partial<IStyleSet<IDetailsListStyles>> = {
    root: {
        opacity: 0.4
    },
};

const enableStyle: Partial<IStyleSet<IDetailsListStyles>> = {
    root: {
        opacity: 1
    },
};

export interface SessionListProps {
    documentId: string;
    searchList: SearchListProps;
    listName: listNameEnum,
}
export enum listNameEnum {
    homeView,
    sessionListView,
    intentsView}
export enum requestEnum {
    getPublishRecordHistory,
    getSessions
}
export interface SearchListProps {
    enabled: number,
    documentId: string;
    startDate: string,
    endDate: string,
    externalDataFilter: string,
    cV: string,
}

export function SessionListView(props: SessionListProps) {
    const [sessions, updateSessions] = React.useState<Session[]>([]);
    const [statusMessage, setStatusMessage] = useState<StatusMessage | null>(null);
    const [request, updateRequest] = useState<GetSessionsRequest>({} as GetSessionsRequest);
    const [ready, response, isLoading, error, execute] = useApi<Session[]>();
    const [requestNameEnum, setRequestNameEnum] = useState<requestEnum>(requestEnum.getSessions);
    const [items, updateItems] = React.useState<Session[]>(sessions);
    const [position, setPosition] = React.useState("top");

    const navigate = useNavigate();
    const selection = new Selection({
        onSelectionChanged: () => {
            updateSelectionDetails(_getSelectionDetails());
        },
    });
    const [selectionDetails, updateSelectionDetails] = React.useState<string>(_getSelectionDetails());

    //Pagination
    const [calculatedPages, setCalculatedPages] = useState<number>(1);
    const [currentPageIndex, setCurrentPageIndex] = useState<number>(0);
    const [initialPage, setInitialPage] = useState<boolean>(false);
    const pageSize = 50;

    const goToNextPage = () => {
        setCurrentPageIndex(currentPageIndex + 1);
        setInitialPage(false);
    };

    const goToPreviousPage = () => {
        if (currentPageIndex > 0) {
            setCurrentPageIndex(currentPageIndex - 1);
        }
    };

    const initialPageIndex = () => {
        setCurrentPageIndex(0);
        setCalculatedPages(1);
        setInitialPage(true);
        setPosition("top");
    };

    React.useEffect(() => {
        if (initialPage) {
            return;
        }
        const request = {
            documentId: props.searchList.documentId,
            startTime: props.searchList.startDate,
            endTime: props.searchList.endDate,
            externalDataFilter: props.searchList.externalDataFilter,
            skip: pageSize * currentPageIndex,
            cV: props.searchList.cV,
        } as GetSessionsRequest;

        updateRequest(request);

        if (items.length !== 0) {
            setPosition("bottom");
        }

    }, [currentPageIndex]);

    React.useEffect(() => {
        initialPageIndex();

        let request: GetSessionsRequest;
        if (props.searchList.enabled !== 0) {
            request = {
                documentId: props.searchList.documentId,
                startTime: props.searchList.startDate,
                endTime: props.searchList.endDate,
                externalDataFilter: props.searchList.externalDataFilter,
                cV: props.searchList.cV,
            } as GetSessionsRequest;

        } else {
            request = {
                documentId: props.documentId,
            } as GetSessionsRequest;
        }
        updateRequest(request);

    }, [props.documentId]);

    React.useMemo(() => {
        if (props.searchList.enabled !== 0) {

            initialPageIndex();

            const request = {
                documentId: props.searchList.documentId,
                startTime: props.searchList.startDate,
                endTime: props.searchList.endDate,
                externalDataFilter: props.searchList.externalDataFilter,
                cV: props.searchList.cV,
            } as GetSessionsRequest;
            updateRequest(request);
        }

    }, [props.searchList.enabled]);

    React.useEffect(() => {
        if (ready) {
            if (props.listName === listNameEnum.homeView) {
                if (props.documentId !== "") {
                    execute([devOpsApi.getSessions(request)]);
                }
                else {
                    updateItems([]);
                }
            }
            else {
                execute([devOpsApi.getSessions(request)]);
            }
        }
    }, [request, ready]);

    React.useEffect(() => {
        if (response) {
            if (requestNameEnum === requestEnum.getPublishRecordHistory) {
                let listhomeView: Session[] = [];
                deduplicate((response as any[]).sort(compare)).forEach(element => {
                    listhomeView.push({
                        sessionId: element.revisionId,
                        documentId: element.documentId,
                        createdDateTime: element.generatedAt,
                        state: "",
                        correlationVector: "",
                        publishOptions: "",
                        externalPartnerData: ""
                    })
                });
                updateSessions(listhomeView);
                setRequestNameEnum(requestEnum.getSessions)
            }
            else {
                updateSessions(response as Session[]);
            }

            if (statusMessage) {
                setStatusMessage(null);
            }
        }
    }, [response]);

    React.useEffect(() => {
        if (error) {
            if (props.listName === listNameEnum.homeView && error.message.indexOf("PcdLookup") === -1) {
                getDocumentId(response);
            }
            else {
                setStatusMessage(error);
                updateItems([]);
            }
        }
    }, [error]);

    React.useEffect(() => {
        updateItems(sessions);
        setCalculatedPages(sessions.length);
    }, [sessions]);

    return (
        (props.documentId === "" && props.listName === listNameEnum.homeView) ? (<div></div>) : (
            <Stack >
                <StackItem>
                    {position === "top" && isLoading && (<Spinner label="Loading..." />)}
                    <div style={{ pointerEvents: isLoading ? 'none' : 'auto' }}>
                        {
                            props.listName === listNameEnum.sessionListView ? (<div className={classNames.selectionDetails}>{items.length !== 0 && selectionDetails}</div>) :
                                props.listName===(listNameEnum.homeView)||(listNameEnum.intentsView)?(""):(<div className={classNames.selectionDetails}>{selectionDetails}</div>)
                        }
                        <Announced message={selectionDetails} />
                        {items.length !== 0 && <DetailsList styles={isLoading ? disableStyle : enableStyle}
                            items={items}
                            compact={false}
                            columns={_getColumns()}
                            selectionMode={isLoading ? SelectionMode.none : SelectionMode.single}
                            getKey={_getKey}
                            setKey="multiple"
                            layoutMode={DetailsListLayoutMode.justified}
                            isHeaderVisible={true}
                            selection={selection}
                            selectionPreservedOnEmptyClick={true}
                            onItemInvoked={_onItemInvoked}
                            enterModalSelectionOnTouch={true}
                            ariaLabelForSelectionColumn="Toggle selection"
                            ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                            checkButtonAriaLabel="select row"
                        />}
                        <StatusMessageBar message={statusMessage} isMultiline />
                    </div></StackItem>
                <StackItem>
                    <Stack
                        horizontal
                    >
                        {items.length !== 0 && (<Pagination
                            total={0}
                            calculatedPages={calculatedPages}
                            pageSize={pageSize}
                            goToPreviousPage={goToPreviousPage}
                            goToNextPage={goToNextPage}
                            currentPageIndex={currentPageIndex}
                        />)}{position === "bottom" && isLoading && (<Spinner label="Loading..." />)}
                    </Stack>

                </StackItem>
            </Stack>)
    );

    function _getKey(item: any, index?: number): string {
        return item.key;
    }

 
    function _onItemInvoked(item: any): void {
        var session = item as Session;
        const ItemInvoked = props.listName
        switch (ItemInvoked) {
            case listNameEnum.homeView:
                return navigate(`/explorer/ingestion/${session.sessionId}/${session.documentId}`);
            case listNameEnum.sessionListView:
                return navigate(`/explorer/ingestion/${session.sessionId}/${session.documentId}`);
                case listNameEnum.intentsView:
                    return navigate(`/explorer/intents/${session.sessionId}/${session.documentId}`);
            default:
                return navigate(`/explorer/ingestion/${session.sessionId}/${session.documentId}`);
        }
    
    }

    function _getSelectionDetails(): string {
        const selectionCount = selection.getSelectedCount();

        switch (selectionCount) {
            case 0:
                return 'No items selected';
            case 1:
                return '1 item selected: ' + (selection.getSelection()[0] as Session).sessionId;
            default:
                return `${selectionCount} items selected`;
        }
    }
    function _getColumns(): IColumn[] {
        const columns = props.listName
        switch (columns) {
            case listNameEnum.homeView:
                return getHomeListColumns();
            case listNameEnum.intentsView:
                return getIntentsListColumns();
                case listNameEnum.sessionListView:
                    return getSessionListColumns();
            default:
                return getSessionListColumns();
        }
    }
    function getDocumentId(response: any) {
        setRequestNameEnum(requestEnum.getPublishRecordHistory)
        const getPublishrequest = {
            bigId: props.searchList.documentId,
        } as GetPublishRecordHistoryRequest;
        if (ready) {
            execute([pcdApi.getPublishRecordHistoryAsync(getPublishrequest)]);
        }
    }
    function compare(elementA: any, elementB: any) {
        return new Date(elementB.generatedAt.toString()).getTime() - new Date(elementA.generatedAt.toString()).getTime()
    }
    function deduplicate(array: any[]): any[] {
        return _.uniqWith(array, (elementA: any, elementB: any) => elementA.revisionId === elementB.revisionId && elementA.documentId === elementB.documentId)
    }
}
