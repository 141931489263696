import { Dropdown, IDropdownOption, IDropdownStyles, IStackStyles, IStackTokens, Spinner, Stack, StackItem, initializeIcons } from '@fluentui/react';
import { Announced } from '@fluentui/react/lib/Announced';
import { DetailsList, DetailsListLayoutMode, IColumn, Selection, SelectionMode, DetailsRow, IDetailsRowStyles, IDetailsListProps } from '@fluentui/react/lib/DetailsList';
import { Toggle } from '@fluentui/react/lib/Toggle';
import { mergeStyleSets, getTheme } from '@fluentui/react/lib/Styling';
import React, { useState, useEffect } from 'react';
import { useBoolean } from "@fluentui/react-hooks";
import { useApi, ApiParameters } from '../hooks/useApi';
import { OrchestrationEvent } from '../models/orchestrationEvent';
import devOpsApi, { GetEventsRequest, GetManifastEventsRequest } from '../services/devOpsApiClient';
import { EventDetailButton } from './eventDetailButton';
import { EventReplayButton } from './eventReplayButton';
import { StatusMessage, StatusMessageBar } from '../statusMessageBar';
import { Pagination } from '../pagination';
import { SearchComponent } from './SearchBox';
import VF from '../util/ValueFormatting';
import { manifestEventTypeData } from '../mockDatas/DropDownDatas';

const theme = getTheme();

const classNames = mergeStyleSets({
    selectionDetails: {
        marginBottom: '20px',
    },
});
// Styles definition
const stackStyles: IStackStyles = {
    root: {
        width: 300,
    },
};

const controlStyles = {
    root: {
      margin: '20px 20px 20px 0',
    },
};

const numericalSpacingStackTokens: IStackTokens = {
    childrenGap: 10,
    padding: 0,
};

const onRenderRow: IDetailsListProps['onRenderRow'] = (props) => {
    const customStyles: Partial<IDetailsRowStyles> = {};
    if (props) {
        if (props.item.data.SubState !== undefined && props.item.data.SubState === "Failed") {
            customStyles.root = { backgroundColor: theme.palette.yellowLight };
            return <DetailsRow {...props} styles={customStyles} />;
        }
        else {
            return <DetailsRow {...props} />;
        }
    }
    return null;
};

interface EventsMap {
    pageNumber : number
    events : OrchestrationEvent[]
    continuationToken : string
}
export interface EventListProps {
    sessionId: string,
}

export function MainfestEventView(props: EventListProps) {
    const [events, updateEvents] = React.useState<OrchestrationEvent[]>([]);
    const [apiParameters, updateApiParameters] = useState<ApiParameters[]>([]);
    const [ready, response, isLoading, error, execute] = useApi<any>();
    const [displaySessionMonitorEvent, { toggle: toggleDisplaySessionMonitorEvent }] = useBoolean(false);
    const [statusMessage, setStatusMessage] = useState<StatusMessage | null>(null);
    const sessionMonitoringEvent = "sessionmonitoringevent";
    const [calculatedPages, setCalculatedPages] = useState<number>(1);
    const [currentPageIndex, setCurrentPageIndex] = useState<number>(0);
    const [initialPage, setInitialPage] = useState<boolean>(false);
    const initPageSize = 1000;
    const [pageSize, setPageSize] = useState<number>(10);
    const [position, setPosition] = React.useState("top");
    const [isDisplay,setIsDisplay] = useState<String>("false");
    const [eventyType,setEventTypeName] = useState<string>("");
    const [options,setOptions] = useState<IDropdownOption[]>([]);
    const [flag,setFlags] = useState<boolean>(false);
    const [selectedKey, setSelectedKeys] = useState<string>("");
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [isClear, setIsClear] = useState<string>("");
    const [continuationToken,setcontinuationToken] = useState<string>("");
    const [eventsMap,setEventsMap] = useState<EventsMap[]>([]);
    // Initialize icons in case this example uses them
    initializeIcons();
    const dropdownStyles: Partial<IDropdownStyles> = {
        dropdown: { 
            width: 300,
            marginBottom:50,
            marginLeft:50,
        },
        label:{
            marginLeft: 50
        }
      };

    const goToNextPage = () => {
        setCurrentPageIndex(currentPageIndex + 1);
        setInitialPage(false);
    };

    const goToPreviousPage = () => {
        if (currentPageIndex > 0) {
            setCurrentPageIndex(currentPageIndex - 1);
        }
        if (currentPageIndex + 1 == eventsMap.length && eventsMap[eventsMap.length-1].continuationToken==null)
        {
            setPageSize(p=>p-1)
        }
    };

    const initialPageIndex = () => {
        setPageSize(initPageSize)
        setCurrentPageIndex(0);
        setCalculatedPages(1);
        setInitialPage(true);
        setPosition("top");
    };
    const columns: IColumn[] = [
        {
            key: 'idColumn',
            name: 'Event Id',
            fieldName: 'id',
            minWidth: 330,
            maxWidth: 330,
            isResizable: true,
            data: 'number',
            isPadded: true,
        },
        {
            key: 'eventTimeColumn',
            name: 'Event Time',
            fieldName: 'eventTime',
            minWidth: 110,
            maxWidth: 110,
            isResizable: true,
            data: 'string',
            onRender: (item: OrchestrationEvent) => {
                return <span>{item.eventTime}</span>;
            },
            isPadded: true,
        },
        {
            key: 'eventTypeColumn',
            name: 'Event Type',
            fieldName: 'eventType',
            minWidth: 160,
            maxWidth: 160,
            isResizable: true,
            data: 'string',
            isPadded: true,
        },
        {
            key: 'subjectColumn',
            name: 'Subject',
            fieldName: 'subject',
            minWidth: isDisplay=="false"?500:320,
            maxWidth: isDisplay=="false"?500:320,
            isResizable: true,
            data: 'string',
            isPadded: true,
        },
        {
            key: 'eventNameColumn',
            name: 'Event Name',
            fieldName: 'eventName',
            minWidth: 180,
            maxWidth: 180,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item: OrchestrationEvent) => {
                return <span>{item.eventType.toLowerCase() === sessionMonitoringEvent ? JSON.parse(JSON.stringify(item.data)).EventName : ""}</span>;
            },
        },
        {
            key: 'eventSubStateColumn',
            name: 'SubState',
            fieldName: 'eventSubState',
            minWidth: 45,
            maxWidth: 45,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item: OrchestrationEvent) => {
                return <span>{item.eventType.toLowerCase() === sessionMonitoringEvent ? JSON.parse(JSON.stringify(item.data)).SubState : ""}</span>;
            },
        },
        {
            key: 'dataColumn',
            name: 'Actions',
            fieldName: 'data',
            minWidth: 190,
            maxWidth: 190,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item: OrchestrationEvent) => {
                return (
                    <Stack styles={stackStyles} horizontal tokens={numericalSpacingStackTokens}>
                        <EventDetailButton Details={item.data} />
                        {item.eventType.toLowerCase() !== sessionMonitoringEvent && (<EventReplayButton Event={item} />)}
                    </Stack>
                );
            },
        },
    ];

    const selection = new Selection({
        onSelectionChanged: () => {
            updateSelectionDetails(_getSelectionDetails());
        },
    });
    const [selectionDetails, updateSelectionDetails] = React.useState<string>(_getSelectionDetails());
    React.useEffect(() => {
        if (initialPage) {
            return;
        }
        if (eventsMap.filter(i=>i.pageNumber == currentPageIndex).length>0)
        {
            const items = eventsMap.filter(i=>i.pageNumber == currentPageIndex)
            if (items[0].continuationToken == null)
            {
                setPageSize(p=>p+1)
            }
            updateItems(items[0].events)
            setCalculatedPages(items[0].events.length);
        }
        else{
        const request = {
            revisionId: props.sessionId,
            hasFilter: "true",
            displaySessionMonitoringEvent: isDisplay,
            eventTypeName: eventyType,
            pageSize:initPageSize.toString(),
            startTime:VF.FormatDate(startDate as Date),
            endTime:VF.FormatDate(endDate as Date),
            continuationToken:continuationToken

        } as GetManifastEventsRequest;

        updateApiParameters([devOpsApi.getManifastEvents(request)]);
    }

        if (items.length !== 0) {
            setPosition("bottom");
        }

    }, [currentPageIndex]);
    useEffect(() => {
        getEvent()

    }, [props]);

    useEffect(() => {
         if (endDate && startDate && endDate < startDate) {
          alert('The end time cannot be greater than the start time');
          return;
        }
        if (startDate == null && endDate == null && isClear == "clean")
        {
            setEventsMap([])
            setPageSize(initPageSize)
            setFlags(false)
            setSelectedKeys("")
            setEventTypeName("")
            setStartDate(null)
            setEndDate(null)
        }
        getEvent()

    }, [startDate,endDate]);
    

    useEffect(() => {
        if (ready) {
            execute(apiParameters);
        }
    }, [apiParameters, ready]);

    useEffect(() => {
        if (response) {
            const documents = response.TrackingEvents as OrchestrationEvent[];
            if (documents.length === 0) {
                return;
            }
            const sortDocuments = documents.slice().sort((pre, after) => new Date(pre.eventTime.toString()).getTime() - new Date(after.eventTime.toString()).getTime());
            updateEvents(sortDocuments);
            const token = response.ContinuationToken
            setcontinuationToken(token)
            token == ""||token==null? setPageSize(p=>p+1):token
            const maps:EventsMap = {
                continuationToken:token,
                pageNumber:currentPageIndex,
                events:sortDocuments
            }
            setMaps(maps)
            if (!flag)
            {
            setDropdownOptions(manifestEventTypeData)
            }
            setCalculatedPages(sortDocuments.length);
            if (statusMessage) {
                setStatusMessage(null);
            }
        }
    }, [response]);
    useEffect(() => {
        if (error) {
            updateItems([])
            setStatusMessage(error);
        }
    }, [error]);

    const [items, updateItems] = useState<OrchestrationEvent[]>(events);

    useEffect(() => {
        if (events.length > 0) {
            updateItems(events)
        }
    }, [events]);

    useEffect(() => {
        setEventsMap([])
        getEvent()
    }, [displaySessionMonitorEvent]);

    useEffect(() => {
        setEventsMap([])
        getEvent()
    }, [eventyType]);
    return (
        <Stack >
        <StackItem>
        {isLoading ? (<Spinner label="Loading..." />):
        <div>

            {selectionDetails !== '' && <div className={classNames.selectionDetails}>{selectionDetails}</div>}
            <Announced message={selectionDetails} />
            <Stack horizontal>
            <Toggle
                label={<span style={{ fontSize: 12 }}>Display Session Monitor Event</span>}
                checked={displaySessionMonitorEvent}
                onChange={_onChangeDisplayMode}
                inlineLabel
            />
   
        <Dropdown
        placeholder="Select an option"
        label="EventType Name"
        options={options}
        onChange={_onChange}
        selectedKey={selectedKey}
        styles={dropdownStyles}/>
        <SearchComponent start={startDate as Date} end={endDate as Date} onSearchClick={(starTime,endTime,flag,)=>(setStartDate(starTime),setEndDate(endTime),setIsClear(flag))} onCleanClick={(starTime,endTime,flag)=>(setStartDate(starTime),setEndDate(endTime),setIsClear(flag))} />
         </Stack>
         {items.length > 0 ?(
            <DetailsList
                items={items}
                compact={false}
                columns={columns.filter(c => displaySessionMonitorEvent || (c.fieldName !== "eventName" && c.fieldName !== "eventSubState"))}
                selectionMode={SelectionMode.single}
                getKey={_getKey}
                setKey="multiple"
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                selection={selection}
                selectionPreservedOnEmptyClick={true}
                onItemInvoked={_onItemInvoked}
                enterModalSelectionOnTouch={true}
                ariaLabelForSelectionColumn="Toggle selection"
                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                checkButtonAriaLabel="select row"
                onRenderRow={onRenderRow}
            />):(<div></div>)}
            <StatusMessageBar message={statusMessage} isMultiline />
         
        </div>}
        </StackItem>
        <StackItem>
                        <Stack
                            horizontal
                        >
                            {items.length !== 0 && (<Pagination
                                total={0}
                                calculatedPages={calculatedPages}
                                pageSize={pageSize}
                                goToPreviousPage={goToPreviousPage}
                                goToNextPage={goToNextPage}
                                currentPageIndex={currentPageIndex}
                            />)}{position === "bottom" && isLoading && (<Spinner label="Loading..." />)}
                        </Stack>
    
                    </StackItem>
        </Stack>
    );

    function setDropdownOptions(items:string[]){
        let opts:IDropdownOption[] =[];
        opts.push({key:"",text:"All"})
        items.forEach(item=>{
            opts.push({key:item,text:item})
        })
        setOptions(isDisplay=="true"?opts:opts.filter(o=>o.text.toLowerCase()!= sessionMonitoringEvent))
        setFlags(true)
    }
    function getEvent(){
        initialPageIndex();
        const request = {
            revisionId: props.sessionId,
            hasFilter: "true",
            displaySessionMonitoringEvent: isDisplay,
            eventTypeName:eventyType,
            pageSize: initPageSize.toString(),
            startTime:VF.FormatDate(startDate as Date),
            endTime:VF.FormatDate(endDate as Date),
        } as GetManifastEventsRequest;
        updateApiParameters([devOpsApi.getManifastEvents(request)]);
    }
    function _getKey(item: any, index?: number): string {
        return item.key;
    }

    function _onItemInvoked(item: any): void {
        // TODO: batch operation to be supported.
    }
    function _onChange(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number): void {
        setEventsMap([])
        setEventTypeName(option?.key as string)
        setSelectedKeys(option?.key as string)
    }

    function _getSelectionDetails(): string {
        const selectionCount = selection.getSelectedCount();

        switch (selectionCount) {
            case 0:
                return '';
            case 1:
                return '1 item selected: ' + (selection.getSelection()[0] as OrchestrationEvent).id;
            default:
                return `${selectionCount} items selected`;
        }
    }

    function _onChangeDisplayMode(ev?: React.MouseEvent<HTMLElement>, checked?: boolean): void {
        if (checked)
        {
            setIsDisplay("true")
        }
        else
        {
            setIsDisplay("false")
        }
        setFlags(false)
        setSelectedKeys("")
        setEventTypeName("")
        setStartDate(null)
        setEndDate(null)
        setEventsMap([])
        setPageSize(initPageSize)
        toggleDisplaySessionMonitorEvent();
    }
    function setMaps(newMaps:EventsMap){
        const newEvents = [...eventsMap,newMaps];
        setEventsMap(newEvents);
    }
}