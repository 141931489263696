import { useCallback, useState } from 'react';
import { useAccessToken } from '../../config/authConfig';
import { StatusMessage } from '../statusMessageBar';
import { CorrelationVector } from "mscv";

//TODO: add error handling
export interface ApiParameters {
    uri: string,
    method: string,
    body: string,
    responseType: string,
}


export const useApi = <T>(
    runOnMount?: boolean,
) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<StatusMessage | null>(null);
    const [response, setResponse] = useState<T | T[] | undefined>(undefined);
    const [token, ready] = useAccessToken();
    
    const execute = useCallback(async (apiParamsArray: ApiParameters[]) => {

        if (!ready) {
            return;
        }
 
        setIsLoading(true);

        const headers = new Headers();
        const correlationVector: CorrelationVector = CorrelationVector.createCorrelationVector();

        if (token) {
            const bearer = `Bearer ${token}`;
            headers.append("Authorization", bearer);
            headers.append('Access-Control-Allow-Origin', '*'); 
        }
        const promiseArray = new Array<Promise<any>>();
        const responseArray = new Array<T>();
        const exceptions: string[] = [];
    
        apiParamsArray.forEach(apiParams => {
            console.log(`calling Api ${apiParams.uri}`);
            if (apiParams.body !== undefined && apiParams.body !== "")
            {
                headers.append("Content-Type", 'application/json');
            }
            else
            {
                headers.delete("Content-Type");
            }

            const cv = correlationVector.increment();
            headers.append("MS-CV", cv);

            promiseArray.push(fetch(apiParams.uri, { method: apiParams.method, headers: headers, body: apiParams.body })
                .then(async (r: any) => {
                    if (!r.ok) {
                        return r.text().then((text: string | undefined) => { throw new Error(`status: ${r.status} message: ${text}`) });
                    }
                    if (apiParams.responseType !== undefined && apiParams.responseType === "blob")
                        responseArray.push(await r.blob());
                    else
                        responseArray.push(await r.json());
                })
                .catch(error => {
                    if (apiParams.responseType !== undefined && apiParams.responseType === "blob") {
                        responseArray.push(([]) as any);
                    }
                    exceptions.push(`url:${apiParams.uri} error:${(error as Error).message}`);
                })
                .finally(() => console.log(`finish calling Api ${apiParams.uri}`)));
        });

        Promise.all(promiseArray)
            .then(() => { (apiParamsArray.length === 1) ? setResponse(responseArray[0]) : setResponse(responseArray) })
            .then(() => {
                if (exceptions.length !== 0) {
                    const msgType = (apiParamsArray.length === exceptions.length) ? "error" : "warning";
                    setError(({
                        type: msgType ,
                        message: exceptions.join(","),
                    }) as any);
                }
            })
            .then(() => setIsLoading(false));


    }, [ready, token]);

    // If runOnMount is true, then this api will be called immediately
    // without needing to be called manually.
    // useEffect(() => {
    //     if (runOnMount) {
    //         execute();
    //     }
    // }, [runOnMount, execute]);

    return [ready, response, isLoading, error, execute] as const;
};
