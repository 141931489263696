import React from 'react'
import {
    FontWeights, ILabelStyles, IStackStyles, IStackTokens, IStyleSet, ITextStyles,
    Label, Pivot, PivotItem, Stack, Text, Spinner, Link, Toggle
} from '@fluentui/react';
import { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useApi, ApiParameters } from '../hooks/useApi';
import  devOpsApi, { GetManifestSessionRequest } from '../services/devOpsApiClient';
import { StatusMessage, StatusMessageBar } from '../statusMessageBar';
import { MainifestSession, Session } from '../models/session';
import { Environment, getEnvironment } from '../../config/environmentConfig';
import { ManifestDocument } from '../sessions/manifestDocument';
import { MainfestEventView } from '../sessions/mainfestEventView';
import { ManifestSessionOverView } from '../sessions/manifestOverView';

const boldStyle: Partial<ITextStyles> = { root: { fontWeight: FontWeights.semibold } };
const itemStyles: React.CSSProperties = {
    width: 150,
};
const stackLinkStyles: IStackStyles = {
    root: {
        width: 300,
    },
};
const stackStyles: Partial<IStackStyles> = {
    root: {
        margin: '0 auto',
        color: '#605e5c',
    },
};
const labelStyles: ILabelStyles = {
    root: { marginTop: 10 },
};
const tabStyles: IStackStyles = {
    root: {
        width: 30,
    },
};
const stackTokens: IStackTokens = { childrenGap: 15 };
export function ManifestSessionView() {
    const params = useParams();
    const revisionId = params.sessionId;
    const [session, updateSession] = useState<MainifestSession>();
    const [documentType, updateDocumentType] = useState<string[]>([]);
    const [publishMode, updatePublishMode] = useState<string[]>([]);
    const [apiParameters, updateApiParameters] = useState<ApiParameters[]>([]);
    const [ready, response, isLoading, error, execute] = useApi<MainifestSession>();
    const [statusMessage, setStatusMessage] = useState<StatusMessage | null>(null);
    const ManifestOverView = useMemo(() => session !== undefined && (<ManifestSessionOverView sessionId={session.revisionId} session={session}/>), [session])
    const ManifestEventView = useMemo(() => session !== undefined && (<MainfestEventView  sessionId={session.revisionId} />), [session])
    const ManifestDocView = useMemo(() => session !== undefined && (<ManifestDocument session = {session} />), [session])

    useEffect(() => {
        const request = {
            sessionId: revisionId,
        } as GetManifestSessionRequest;

        updateApiParameters([devOpsApi.getManifestSession(request)]);
    }, [revisionId]);


    useEffect(() => {
        if (ready) {
            execute(apiParameters);
        }
    }, [apiParameters, ready]);

    useEffect(() => {
        if (response) {
            var mainifestSession = response as MainifestSession;
            updateSession(mainifestSession);
            if (statusMessage) {
                setStatusMessage(null);
            }
        }
    }, [response]);

    useEffect(() => {
        if (error) {
            setStatusMessage(error);
        }
    }, [error]);

    const handleLinkClick = (item?: PivotItem) => {
        if (documentType && documentType.length !== 0 && item && item.props?.headerText !== "PCD") {
            updateDocumentType([]);
        }

        if (publishMode && publishMode.length !== 0 && item && item.props?.headerText !== "RatingFeed") {
            updatePublishMode([]);
        }
    }; 
    const generateGenevaLink = (session: MainifestSession | undefined) => {
        if (session) {
            const namespaces = +getEnvironment() === Environment.PROD ? 'OfferStoreIngestionProd,BigCatPublishNsPROD' : 'OfferStoreIngestionInt,BigCatPublishNsINT'

            var query = `https://portal.microsoftgeneva.com/logs/dgrep?be=DGrep&time=${session.createdDateTime}&offset=%2B1&offsetUnit=Days&UTC=true`
                + `&ep=Diagnostics%20PROD&ns=${namespaces}&en=CustomEvents,Log`
                + `&kqlClientQuery=source&chartEditorVisible=true&chartType=column`;

            if (session.correlationVector != null) {
                query = query + `&conditions=[["cV","contains","${encodeURI(session.correlationVector.substring(0, session.correlationVector.indexOf('.')))}"]]`;
            }

            return query;
        }

        return '';
    };

    return (
        <>
            <Stack horizontalAlign="start" verticalAlign="start" verticalFill styles={stackStyles} tokens={stackTokens}>
                <Text variant="xxLarge" styles={boldStyle}>
                ManifestSession
                </Text>
                <Text>RevisionId: {session?.revisionId}</Text>
                <Text>CV: {session?.correlationVector}</Text>
                <Text>Create Date: {session?.createdDateTime}</Text>
                <Text>Ingestion State: {session?.state}</Text>
                <Text>Publish Options: {session?.publishOptions}</Text>
                <Stack horizontal styles={stackLinkStyles} tokens={stackTokens}>
                    <span style={itemStyles}><Link href={generateGenevaLink(session)}
                        title="Go to Geneva"
                        aria-label="Go to Geneva"
                        target='_blank'>
                        Go to Geneva
                    </Link></span>
                </Stack>
                <Text variant="xxLarge" styles={boldStyle}>
                    Details
                </Text>
            </Stack>

            <Stack horizontalAlign="start" verticalAlign="start" verticalFill styles={stackStyles} tokens={stackTokens}>
                {session !== undefined || (isLoading && <Spinner label="Loading..." />)}
                <StatusMessageBar message={statusMessage} isMultiline />
                <Pivot aria-label="Basic Pivot Example" onLinkClick={handleLinkClick}>
                    <PivotItem headerText="ManifestOverview">
                    <div style={{width:1200}}>  {ManifestOverView}</div>
                    </PivotItem>
                    <PivotItem headerText="ManifestEvents">
                    <div style={{width:1400}}>  {ManifestEventView}</div>
                    </PivotItem>
                    <PivotItem headerText="ManifestDocuments">
                        {ManifestDocView}
                    </PivotItem>
                </Pivot>
            </Stack></>
    );
}
