import { Spinner } from '@fluentui/react';
import React, { useState, useEffect } from 'react';
import { DetailsList, DetailsListLayoutMode, IColumn, SelectionMode } from '@fluentui/react/lib/DetailsList';
import { productContainerDocument } from '../models/productContainerDocument';
import pcdApiClient, { GetPcdEntitiesRequest } from '../services/pcdApiClient';
import { StatusMessage, StatusMessageBar } from '../statusMessageBar';
import { useApi, ApiParameters } from '../hooks/useApi';

export interface PcdEntitiesListProps {
    documentId: string,
    revisionId: string,
    publishOptions: string,
    getDocumentTypes(documentTypes: string[]): void;
}

export function PcdEntitiesView(props: PcdEntitiesListProps) {
    const [entities, updateEntities] = useState<productContainerDocument[]>([]);
    const [apiParameters, updateApiParameters] = useState<ApiParameters[]>([]);
    const [ready, response, isLoading, error, execute] = useApi<productContainerDocument>();
    const [finished, setFinished] = useState(false);
    const [statusMessage, setStatusMessage] = useState<StatusMessage | null>(null);

    const columns: IColumn[] = [
        {
            key: 'documentNameColumn',
            name: 'Document Name',
            fieldName: 'documentName',
            minWidth: 250,
            maxWidth: 300,
            isResizable: true,
            data: 'string',
            isPadded: true,
        },
        {
            key: 'generatedTimeColumn',
            name: 'Generated Time',
            fieldName: 'generatedTime',
            minWidth: 180,
            maxWidth: 200,
            isResizable: true,
            data: 'string',
            isPadded: true,
        },
        {
            key: 'documentTypeColumn',
            name: 'Document Type',
            fieldName: 'documentType',
            minWidth: 180,
            maxWidth: 200,
            isResizable: true,
            data: 'string',
            isPadded: true,
        },
    ];

    useEffect(() => {
        if (finished) {
            return;
        }

        const documentTypes = props.publishOptions.includes("GenerateTestPcd") ? ["Fragment", "Document", "TestFragment", "TestDocument"] : ["Fragment", "Document"] ;
        documentTypes.forEach((docType) => {
            const request = {
                documentId: props.documentId,
                revisionId: props.revisionId,
                documentType: docType
            } as GetPcdEntitiesRequest;

            updateApiParameters(preApiParas => [...preApiParas, pcdApiClient.getPcdEntities(request)]);
        });

    }, [props.documentId, props.revisionId]);

    useEffect(() => {
        if (ready && !finished) {
            execute(apiParameters);
        }
    }, [ready]);


    useEffect(() => {
        if (response) {
            setFinished(true);
            if (Array.isArray(response)) {
                const documents = response as productContainerDocument[];
                if (documents.length === 0) {
                    return;
                }

                updateEntities(documents);

                documents.forEach((data) => {
                    props.getDocumentTypes(((preType: any) => [...preType, [data?.documentType, data?.generatedTime]]) as any);
                });
            } else {
                const document = response as productContainerDocument;
                updateEntities([document]);
                props.getDocumentTypes(((preType: any) => [...preType, [document?.documentType, document?.generatedTime]]) as any);
            }

            if (statusMessage) { 
                setStatusMessage(null);
            }
        }
    }, [response]);

    useEffect(() => {
        if (error) {
            setStatusMessage(error);
        }
    }, [error]);

    const [items, updateItems] = useState<productContainerDocument[]>(entities);

    useEffect(() => {
        updateItems(entities);
    }, [entities]);

    return (
        <div>
            {isLoading && (<Spinner label="Loading..." />)}
            {items.length !== 0 && <DetailsList
                items={items}
                compact={false}
                columns={columns}
                selectionMode={SelectionMode.none}
                setKey="multiple"
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                selectionPreservedOnEmptyClick={true}
                enterModalSelectionOnTouch={true}
                ariaLabelForSelectionColumn="Toggle selection"
                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                checkButtonAriaLabel="select row"
            />}
            <StatusMessageBar message={statusMessage} isMultiline />
        </div>
    );
}
