import React from 'react'
import { FontWeights, ILinkStyles, Spinner, DefaultButton } from '@fluentui/react';
import { SpinnerSize } from '@fluentui/react/lib/Spinner';
import { useState, useEffect } from 'react';
import { StatusMessage, StatusMessageBar } from '../statusMessageBar';
import { useApi, ApiParameters } from '../hooks/useApi';
import ratingFeedApiClient, { GetRatingFeedRequest } from '../services/ratingFeedApiClient';
import filer from 'file-saver';

export interface RatingFeedListProps {
    productId: string,
    revisionId: string,
    publishMode: string[],
}

export function RatingFeedDownloadButton(props: RatingFeedListProps) {
    const [ready, response, isLoading, error, execute] = useApi<any>();
    const [downloadReady, setDownloadReady] = useState(false);
    const [apiParameters, setApiParameters] = useState<ApiParameters[]>([]);
    const [statusMessage, setStatusMessage] = useState<StatusMessage | null>(null);

    useEffect(() => {
        if (response && downloadReady) {
            let seqNumber = 1;
            if (Array.isArray(response)) {
                response.forEach((res) => {
                    if (res.length !== 0) {
                        downloadDocument(res as Blob, seqNumber.toString());
                        seqNumber ++;
                    }
                });
            } else {
                if (response.length === 0) {
                    return;
                }
                downloadDocument(response as Blob, seqNumber.toString());
            }
        }

        if (statusMessage) {
            setStatusMessage(null);
        }
    }, [response]);

    useEffect(() => {
        if (error) {
            setStatusMessage(error);
        }
    }, [error]);

    useEffect(() => {
        if (ready && downloadReady) {
            execute(apiParameters);
        }
    }, [apiParameters, ready]);

    return (
        <div>
            <DefaultButton onClick={downloadRatingFeedData} disabled={isLoading}>Download RatingFeed</DefaultButton>
            {isLoading &&
                (<Spinner size={SpinnerSize.xSmall} label="Please wait..." ariaLive="assertive" labelPosition="right" />)}
            <StatusMessageBar message={statusMessage} isMultiline />
        </div>);

    function downloadDocument(responseBlob: Blob, modeName: string) {

        const blob = new Blob([responseBlob],
            {
                type: 'application/octet-stream'
            });

        const date = new Date().toJSON().slice(0, 10).replace(/-/g, "");
        filer.saveAs(blob, `Ratingfeed_${props.productId}_${props.revisionId}_${modeName}_${date}.zip`);
    }

    function downloadRatingFeedData() {
        setApiParameters([]);
        props.publishMode.forEach((mode) => {
            const request = {
                productId: props.productId,
                revisionId: props.revisionId,
                publishMode: mode
            } as GetRatingFeedRequest;
            setApiParameters(preApiParas => [...preApiParas, ratingFeedApiClient.getRatingFeedDocument(request)]);
        });
        setDownloadReady(true);
    }
}