import * as React from 'react';
import { Nav, INavStyles, INavLinkGroup } from '@fluentui/react/lib/Nav';

const navStyles: Partial<INavStyles> = { root: { } };

const navLinkGroups: INavLinkGroup[] = [
  {
    name: 'Explorer',
    expandAriaLabel: 'Expand Explorer section',
    collapseAriaLabel: 'Collapse Explorer section',
    links: [
      {
        key: 'Home',
        name: 'Home',
        url: '/explorer/home',
      },
      {
        key: 'Product Ingestion',
        name: 'Product Ingestion',
        url: '/explorer/ingestion',
      },
      {
        key: 'Private Offer Ingestion',
        name: 'Private Offer Ingestion',
        url: '/explorer/bigIdIngestion',
      },
      {
        key: 'Manifest Ingestion',
        name: 'Manifest Ingestion',
        url: '/explorer/manifestIngestion',
      },
      {
        key: 'Intents',
        name: 'Intents',
        url: '/explorer/intents',
      },
      {
        key: 'Rating Validation Suppression',
        name: 'Rating Validation Suppression',
        url: '/explorer/ratingValidationSuppression',
      },
      {
        key: 'Rate Card Validation Suppression',
        name: 'Rate Card Validation Suppression',
        url: '/explorer/rateCardValidationSuppression',
      },
      {
        key: 'Dead Letter',
        name: 'Dead Letter',
        url: '/explorer/deadletter',
      },
    ],
  },
  // {
  //   name: 'Converters',
  //   expandAriaLabel: 'Expand Converters section',
  //   collapseAriaLabel: 'Collapse Converters section',
  //   links: [
  //     {
  //       key: 'RatingFeedConverter',
  //       name: 'RatingFeed Converter',
  //       url: '/converters/ratingfeed',
  //     },
  //     {
  //       key: 'RateCardConverter',
  //       name: 'RateCard Converter',
  //       url: '/converters/ratecard',
  //     },
  //   ],
  // },
  {
    name: 'Batch Operation',
    expandAriaLabel: 'Expand Batch Operation section',
    collapseAriaLabel: 'Collapse Batch Operation section',
    links: [
      {
        key: 'Replay Event',
        name: 'Replay Event',
        url: '/batch/replayevent',
      },
    ],
  },
  {
    name: 'References',
    expandAriaLabel: 'Expand Utilities section',
    collapseAriaLabel: 'Collapse Utilities section',
    links: [
      {
        key: 'Documentation',
        name: 'Documentation',
        url: 'https://eng.ms/docs/cloud-ai-platform/commerce-ecosystems/commerce-partner-seller-experience/marketplace-ingestion-experience-docs/guides/tsg/offerstoreportal/docs',
        target: '_blank'
      },
      {
        key: 'AzureResources',
        name: 'Azure Resources',
        url: 'https://eng.ms/docs/cloud-ai-platform/commerce-ecosystems/commerce-partner-seller-experience/marketplace-ingestion-experience-docs/guides/tsg/offerstoreportal/azurereferences',
        target: '_blank'
      },
      {
        key: '1ppIngestion',
        name: '1pp Ingestion Portal',
        url: 'https://partner.microsoft.com/en-us/internal/firstparty/',
        target: '_blank'
      },
      {
        key: 'ExpectationExplorer',
        name: 'BigCat Expectation Explorer',
        url: 'https://explorer-co-prodpme-app.azurewebsites.net/',
        target: '_blank'
      },
      {
        key: 'BigCatStoreZero',
        name: 'BigCat Store Zero',
        url: 'https://bigcatstore-prod.azurewebsites.net/catalog',
        target: '_blank'
      },
    ],
  },
];

export const NavBar: React.FunctionComponent = () => {
  return (
    <Nav styles={navStyles} ariaLabel="Nav panel" groups={navLinkGroups} />
  );
};
