import React from 'react'
import { IPublicClientApplication } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { useState } from "react";
import {
    Dropdown,
    IconButton,
    ISearchBoxStyles,
    IDropdownOption,
    IDropdownStyles,
    IIconProps,
    ILinkStyleProps,
    ILinkStyles,
    IStackProps,
    IStackTokens,
    ITheme,
    Link,
    mergeStyles,
    Panel,
    PanelType,
    Stack,
    TooltipHost,
    Text,
    SearchBox
} from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import microsoftLogo from "../assets/images/MicrosoftLogo.png";
import { Environment, useEnvironment } from "../config/environmentConfig";
import { useNavigate } from "react-router-dom"


const stackTokens: IStackTokens = { childrenGap: 5 };

const headerStackStyles = (p: IStackProps, theme: ITheme) => ({
    root: {
        backgroundColor: theme.semanticColors.bodyBackground,
        minHeight: 47,
        padding: "0 32px",
        boxShadow: theme.effects.elevation16,
    },
});

const microsoftLogoStyles = mergeStyles({
    width: "120px",
    display: "block",
});

const helpIcon: IIconProps = { iconName: "Unknown" };
const settingsIcon: IIconProps = { iconName: "PlayerSettings" };

const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: 100 },
};

const pipeFabricStyles = (p: ILinkStyleProps): ILinkStyles => ({
    root: {
        textDecoration: "none",
        color: p.theme.semanticColors.bodyText,
        fontWeight: "600",
        fontSize: p.theme.fonts.medium.fontSize,
    },
});

export const Header: React.FunctionComponent = () => {
    const [showPanel, { toggle: toggleShowPanel }] = useBoolean(false);
    const [env, updateEnv] = useEnvironment();
    const [searchval, SetVal] = useState("");
    const [buttonVal, SetButtonVal] = useState("");
    const history = useNavigate();
    const { accounts, instance } = useMsal();
    const searchBoxStyles: Partial<ISearchBoxStyles> = {
        root: {
            borderRadius: "10px 10px 10px 10px",
            border: "2px solid #c4c7ce",
            width: "600px",
            height: 30,
            top: 0,
            selectors: {
                ":after": {
                    borderRadius: "10px 10px 10px 10px",
                    border: "1px solid #c4c7ce",
                },
                ":hover,:focus": {
                    border: "2px solid #7d9eeb",
                },
            },
        },
    };

    // Tokens definition
    const horizontalGapStackTokens: IStackTokens = {
        childrenGap: 10,
        padding: 10,
    };

    const options: IDropdownOption[] = (
        Object.keys(Environment).filter((a) =>
            a.match(/^\D/)
        ) as (keyof typeof Environment)[]
    )
    .filter((a) => {
        switch (process.env.REACT_APP_EnvironmentName) {
          case "DEVELOPMENT":
            return true;
          case "PROD":
            return a !== Environment[Environment.Local];
          case "INT":
            return a === Environment[Environment.INT] || a === Environment[Environment.AIRCAPI_INT];
          case "AIRCAPI":
            return a === Environment[Environment.AIRCAPI];
          case "AIRCAPI-INT":
            return a === Environment[Environment.AIRCAPI_INT];
          default:
            return false;
        }
      })
        .map((key) => {
            return { key: key, text: key };
        });
    const onChange = (
        event: React.FormEvent<HTMLDivElement>,
        option?: IDropdownOption
    ): void => {
        const selectedEnv: Environment =
            Environment[option?.key.toString() as keyof typeof Environment];
        updateEnv(selectedEnv);

        // reload the page to refresh the endpoint configs
        window.location.reload();
    };

    return (
        <Stack
            horizontal
            verticalAlign="center"
            grow={3}
            styles={headerStackStyles}
        >
            <Stack horizontal grow={2} verticalAlign="center" horizontalAlign="start" tokens={horizontalGapStackTokens}>
                <a
                    href="https://www.microsoft.com"
                    title="Microsoft Home Page"
                    aria-label="Microsoft Home Page"
                    className={microsoftLogoStyles}
                >
                    <img
                        src={microsoftLogo}
                        className={microsoftLogoStyles}
                        alt="Microsoft"
                    />
                </a>
                <Link
                    href="https://www.aka.ms/offerstore"
                    title="Commercial Marketplace | Offer Store"
                    aria-label="Commercial Marketplace | OfferStore"
                    styles={pipeFabricStyles}
                >
                    | Commercial Marketplace | OfferStore
                </Link>
                <SearchBox
                    placeholder="Search"
                    name="Search"
                    autoComplete="on"
                    onSearch={(newValue) => { SetVal((newValue as string) || ""); history('/?search=' + newValue) }}
                    styles={searchBoxStyles}
                />
            </Stack>
          
            <Stack grow={1} horizontal verticalAlign="center" horizontalAlign="end" tokens={stackTokens}>
                <Dropdown
                    options={options}
                    styles={dropdownStyles}
                    defaultSelectedKey={Environment[env]}
                    onChange={onChange}
                ></Dropdown>
                <ProfileContent />
                <IconButton
                    iconProps={helpIcon}
                    title="Help"
                    ariaLabel="Help"
                    href="/docs"
                    target="_blank"
                />
                <IconButton
                    iconProps={settingsIcon}
                    title="Settings"
                    ariaLabel="Settings"
                    onClick={toggleShowPanel}
                />
            </Stack>

            <Panel
                isOpen={showPanel}
                type={PanelType.smallFixedFar}
                onDismiss={toggleShowPanel}
                headerText="Settings"
                closeButtonAriaLabel="Close"
            >
                <span>
                    <p>Environment: {process.env.REACT_APP_EnvironmentName}</p>
                </span>
            </Panel>
        </Stack>
    );
    function ProfileContent() {
    
        const name = accounts[0] && accounts[0].username;
        return (
            <TooltipHost content="Sign out">
                <Text onClick={() => handleLogout()}>
                    {name}
                </Text>
            </TooltipHost>
        );
    }
    function handleLogout() {
        localStorage.clear();
        instance.logoutRedirect().catch((e) => {
            console.error(e);
        });
      }
};




