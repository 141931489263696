import * as React from 'react'
import { getTheme, HighContrastSelector, IMessageBarStyles, MessageBar, MessageBarType } from "@fluentui/react";
import { useBoolean } from '@fluentui/react-hooks'

export type StatusMessage =
    | { type: 'success', message: string }
    | { type: 'error', message: string }
    | { type: 'warning', message: string }

export const StatusMessageBar = ({ message, isMultiline }: { message?: StatusMessage | null, isMultiline?: boolean }) => {
    const [isVisible, { setTrue, setFalse }] = useBoolean(false);

    const getMessageBarStyle = () => {
        const theme = getTheme();

        const messageBarStyle: Partial<IMessageBarStyles> = {
            content: { padding: "8px 0" },
            actions: {
                alignSelf: "start",
                marginTop: "8px",
                marginRight: "24px",
            },
            innerText: {
                whiteSpace: "normal"
            },
            // The default link color (themePrimary) doesn't contrast strong enough against certain MessageBar backgrounds
            // so links inside MessageBar should have their color overridden to match how Fluent fixes this problem
            root: {
                ".ms-Link": {
                    color: theme.semanticColors.messageLink
                },
                ".ms-Link > span": {
                    color: theme.semanticColors.messageLink
                },
                [HighContrastSelector]: {
                    ".ms-Link": {
                        color: "linktext"
                    },
                    ".ms-Link > span": {
                        color: "linktext"
                    },
                }
            }
        };

        messageBarStyle.actions = { alignItems: "flex-end", marginBottom: "8px", marginRight: "24px" };
        messageBarStyle.innerText = { lineHeight: "22px" };
        messageBarStyle.icon = { lineHeight: "16px", paddingTop: "4px" };
        return messageBarStyle;
    };


    React.useEffect(() => {
        if (message) {
            setTrue();
        }
    }, [message]);

    if (!message || !isVisible) { return null }

    return <MessageBar
        messageBarType={
            message.type === 'success'
                ? MessageBarType.success
                : message.type === 'warning'
                    ? MessageBarType.warning
                    : MessageBarType.error}
        isMultiline={isMultiline ?? false}
        styles={getMessageBarStyle()}
        onDismiss={() => setFalse()}
        dismissButtonAriaLabel="Close">
        {message.message}
    </MessageBar>;
}
