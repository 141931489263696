import { Spinner, Stack, StackItem, IStyleSet } from '@fluentui/react';
import { Announced } from '@fluentui/react/lib/Announced';
import { DetailsList, DetailsListLayoutMode, Selection, SelectionMode, IDetailsListStyles } from '@fluentui/react/lib/DetailsList';
import { mergeStyleSets } from '@fluentui/react/lib/Styling';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useApi } from '../../hooks/useApi';
import { DeadLetter, DeadLetterEvent } from '../../models/deadLetter';
import { getDeadLetterListColumns } from '../../models/deadLetterListColumn';
import devOpsApi, { GetDeadLettersRequest, TagRequest } from '../../services/devOpsApiClient';
import { StatusMessage, StatusMessageBar } from '../../statusMessageBar';
import { Pagination } from '../../pagination';
import _ from 'lodash';

const classNames = mergeStyleSets({
    fileIconHeaderIcon: {
        padding: 0,
    },
    fileIconCell: {
        textAlign: 'center',
        selectors: {
            '&:before': {
                content: '.',
                display: 'inline-block',
                verticalAlign: 'middle',
                height: '100%',
                width: '0px',
                visibility: 'hidden',
            },
        },
    },
    fileIconImg: {
        verticalAlign: 'middle',
        maxHeight: '16px',
        maxWidth: '16px',
    },
    controlWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    selectionDetails: {
        marginBottom: '20px',
    },
});

const disableStyle: Partial<IStyleSet<IDetailsListStyles>> = {
    root: {
        opacity: 0.4
    },
};

const enableStyle: Partial<IStyleSet<IDetailsListStyles>> = {
    root: {
        opacity: 1
    },
};

export interface DeadLetterListProps {
    searchList: SearchListProps;
    commandList: CommandListProps;
}
export interface SearchListProps {
    enabled: number,
    tagType: number,
    productId: string,
    startDate: string,
    endDate: string,
    revisionId: string,
    eventType: string,
    pageIndex: number,
    pageSize: number,
    skipCount: number,
}

export interface CommandListProps {
    enabled: number,
    command: string,
}

export function DeadLetterListView(props: DeadLetterListProps) {
    const [deadLetters, updateDeadLetters] = React.useState<DeadLetter>();
    const [statusMessage, setStatusMessage] = useState<StatusMessage | null>(null);
    const [request, updateRequest] = useState<GetDeadLettersRequest>({} as GetDeadLettersRequest);
    const [tagRequest, updateTagRequest] = useState<TagRequest>({} as TagRequest);
    const [ready, response, isLoading, error, execute] = useApi<DeadLetter>();
    const [items, updateItems] = React.useState<DeadLetterEvent[]>([]);
    const [position, setPosition] = React.useState("top");
    const [isUpdateTag, setIsUpdateTag] = React.useState(false);

    const navigate = useNavigate();
    const selection = new Selection({
        onSelectionChanged: () => {
            updateSelectionDetails(_getSelectionDetails());
            updateSelectItems(_getSelectionItems());
        },
    });
    const [selectionDetails, updateSelectionDetails] = React.useState<string>(_getSelectionDetails());
    const [selectItems, updateSelectItems] = React.useState<DeadLetterEvent[]>(_getSelectionItems());

    //Pagination
    const [calculatedPages, setCalculatedPages] = useState<number>(0);
    const [currentPageIndex, setCurrentPageIndex] = useState<number>(0);
    const [skipCount, setSkipCount] = useState<number[]>([0]);
    const [initialPage, setInitialPage] = useState<boolean>(false);
    const pageSize = 10;

    const goToNextPage = () => {
        setCurrentPageIndex(currentPageIndex + 1);
        setInitialPage(false);
    };

    const goToPreviousPage = () => {
        if (currentPageIndex > 0) {
            setCurrentPageIndex(currentPageIndex - 1);
        }
    };

    const initialPageIndex = () => {
        setSkipCount([0]);
        setCurrentPageIndex(0);
        setCalculatedPages(0);
        setInitialPage(true);
        setPosition("top");
        setIsUpdateTag(false);
    };

    React.useEffect(() => {
        if (initialPage) {
            return;
        }
        const request = {
            productId: props.searchList.productId,
            startTime: props.searchList.startDate,
            endTime: props.searchList.endDate,
            tagType: props.searchList.tagType,
            revisionId: props.searchList.revisionId,
            eventType: props.searchList.eventType,
            pageIndex: currentPageIndex + 1,
            pageSize: props.searchList.pageSize,
            skipCount: skipCount[currentPageIndex],
        } as GetDeadLettersRequest;

        updateRequest(request);

        if (items.length !== 0) {
            setPosition("bottom");
        }

    }, [currentPageIndex]);

    React.useMemo(() => {
        if (props.searchList.enabled !== 0) {

            initialPageIndex();

            const request = {
                productId: props.searchList.productId,
                startTime: props.searchList.startDate,
                endTime: props.searchList.endDate,
                tagType: props.searchList.tagType,
                revisionId: props.searchList.revisionId,
                eventType: props.searchList.eventType,
                pageIndex: props.searchList.pageIndex,
                pageSize: props.searchList.pageSize,
                skipCount: props.searchList.skipCount,
            } as GetDeadLettersRequest;
            updateRequest(request);
        }

    }, [props.searchList.enabled]);

    React.useMemo(() => {
        if (props.commandList.enabled !== 0 && selectItems.length > 0) {
            setIsUpdateTag(true);
            const tagRequest = {
                body: selectItems,
            } as TagRequest;
            updateTagRequest(tagRequest);
        }

    }, [props.commandList.enabled]);

    React.useEffect(() => {
        if (ready) {
            execute([devOpsApi.getDeadLetters(request)]);
        }
    }, [request, ready]);

    React.useEffect(() => {
        if (ready) {
            if (props.commandList.command === "addTag") {
                execute([devOpsApi.addTagForDeadLetters(tagRequest)]);
            }
            else if (props.commandList.command === "removeTag") {
                execute([devOpsApi.removeTagForDeadLetters(tagRequest)]);
            }
        }
    }, [tagRequest, ready]);

    React.useEffect(() => {
        if (response) {
            if (isUpdateTag && deadLetters) {
                const responseEvents = (response as DeadLetter).DeadLetterEvents;
                const updateEvents = deadLetters.DeadLetterEvents.map((elementA) => {
                    const correspondingElementB = responseEvents.find((elementB) => elementA.BlobName === elementB.BlobName);
                    if (correspondingElementB) {
                      return {
                        ...elementA,
                        IsTagged: correspondingElementB.IsTagged,
                      };
                    }
                    return elementA;
                  });
                  updateItems(updateEvents);
            }
            else {
                updateDeadLetters(response as DeadLetter);
            }

            if (statusMessage) {
                setStatusMessage(null);
            }
        }
    }, [response]);

    React.useEffect(() => {
        if (error) {
            setStatusMessage(error);
            updateItems([]);
        }
    }, [error]);

    React.useEffect(() => {
        if (deadLetters) {
            setSkipCount([...skipCount, deadLetters.SkipCount]);
            updateItems(deadLetters.DeadLetterEvents);
            setCalculatedPages(deadLetters.HasNextPage ? deadLetters.DeadLetterEvents.length : 0);
            updateSelectionDetails("");
            updateSelectItems([]);
        }
    }, [deadLetters]);

    return (
        (
            <Stack >
                <StackItem>
                    {position === "top" && isLoading && (<Spinner label="Loading..." />)}
                    <div style={{ pointerEvents: isLoading ? 'none' : 'auto' }}>
                        {
                            (<div className={classNames.selectionDetails}>{items.length !== 0 && selectionDetails}</div>)
                        }
                        <Announced message={selectionDetails} />
                        {items.length !== 0 && <DetailsList styles={isLoading ? disableStyle : enableStyle}
                            items={items}
                            compact={false}
                            columns={getDeadLetterListColumns()}
                            selectionMode={isLoading ? SelectionMode.none : SelectionMode.multiple}
                            setKey="multiple"
                            layoutMode={DetailsListLayoutMode.justified}
                            isHeaderVisible={true}
                            selection={selection}
                            selectionPreservedOnEmptyClick={true}
                            onItemInvoked={_onItemInvoked}
                            enterModalSelectionOnTouch={true}
                            ariaLabelForSelectionColumn="Toggle selection"
                            ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                            checkButtonAriaLabel="select row"
                        />}
                        <StatusMessageBar message={statusMessage} isMultiline />
                    </div></StackItem>
                <StackItem>
                    <Stack
                        horizontal
                    >
                        {items.length !== 0 && (<Pagination
                            total={deadLetters != undefined ? deadLetters.TotalCount : 0}
                            calculatedPages={calculatedPages}
                            pageSize={pageSize}
                            goToPreviousPage={goToPreviousPage}
                            goToNextPage={goToNextPage}
                            currentPageIndex={currentPageIndex}
                        />)}{position === "bottom" && isLoading && (<Spinner label="Loading..." />)}
                    </Stack>
                </StackItem>
            </Stack>)
    );
 
    function _onItemInvoked(item: any): void {
        var deadLetter = item as DeadLetterEvent;
        return navigate(`/explorer/ingestion/${deadLetter.RevisionId}/${deadLetter.ProductId}`);
    }

    function _getSelectionDetails(): string {
        const selectionCount = selection.getSelectedCount();

        switch (selectionCount) {
            case 0:
                return 'No items selected';
            case 1:
                {
                    var event = selection.getSelection()[0] as DeadLetterEvent;
                    return '1 item selected: ' + event.RevisionId + ', ' + event.ProductId + ', ' + event.EventType;
                }
            default:
                return `${selectionCount} items selected`;
        }
    }

    function _getSelectionItems(): DeadLetterEvent[] {
        const selectionCount = selection.getSelectedCount();
        if (selectionCount > 0)
        {
            return selection.getSelection() as DeadLetterEvent[];
        }
        else {
            return [];
        }
    }
}
