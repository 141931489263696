import React from 'react'
import ReactJson from 'react-json-view';
import { ProgressIndicator } from '@fluentui/react/lib/ProgressIndicator';
import { Label, ILabelStyles } from '@fluentui/react/lib/Label';
import { StatusMessage, StatusMessageBar } from '../statusMessageBar';
import { Separator } from '@fluentui/react/lib/Separator';

const labelStyles: Partial<ILabelStyles> = { root: { fontSize: 14, fontWeight: 600 } };
export enum viewTypeEnum {
  SubDocument = "SubDocument",
  CompareSubDocument = "CompareSubDocument",
  ReVisionSubDocument="ReVisionSubDocument",

}
export interface SubDocumentViewProps {
  subDocumentContent: string[] | undefined,
  errorMessage: StatusMessage | null,
  isLoading: boolean,
  viewType: viewTypeEnum,
  isDisplay: boolean,
  isDeffult: boolean,
}

export function SubDocumentView(props: SubDocumentViewProps) {
  return (
    <div>
      {props.isDisplay ? (
        <div>
          <Label styles={labelStyles}>

            {props.subDocumentContent === undefined ? (props.isLoading === true ? ""
              : "Please select DocumentType & Dimensions to show the matching SubDocument(s).")
              : props.errorMessage !== null ? "Exception:" : props.subDocumentContent === null ? "No SubDocument!"
                : props.subDocumentContent.length + " SubDocument(s) Matched:"}
            <br />
            {
              props.viewType === viewTypeEnum.SubDocument ? "Current" : props.viewType === viewTypeEnum.CompareSubDocument?"Compare":"RevisionHistory"
            }
            {
              props.isDeffult ? (<label style={{ marginLeft: 50, color: "red", padding: 2, }}>different</label>) : ("")
            }
          </Label>
          <ProgressIndicator label={props.isLoading === true ? "Loading..." : ""} progressHidden={props.isLoading === false} />
          <StatusMessageBar message={props.errorMessage} isMultiline />
          {props.subDocumentContent !== undefined && props.subDocumentContent !== null && (props.subDocumentContent.map(renderJson))}
        </div>
      ) : ("")}
    </div>
  );

  function renderJson(element: string, index: number) {
    return ( 
      <div key={index}>
        <ReactJson src={JSON.parse(element)} theme="google" name={false} displayObjectSize={false} displayDataTypes={false} />
        <Separator />
      </div>
    );
  }
}
