import { Spinner } from '@fluentui/react';
import React, { useState, useEffect } from 'react';
import { DetailsList, DetailsListLayoutMode, IColumn, SelectionMode } from '@fluentui/react/lib/DetailsList';
import { documentMapping } from '../models/documentMapping';
import { useApi, ApiParameters } from '../hooks/useApi';
import { StatusMessage, StatusMessageBar } from '../statusMessageBar';
import pcdApiClient, { GetDocumentMappingRequest } from '../services/pcdApiClient';

export interface docMappingListProps {
    bigIdVersionIds: string[],
}

export function DocumentMappingView(props: docMappingListProps) {
    const [docMapping, updateDocMapping] = useState<documentMapping[]>([]);
    const [apiParameters, updateApiParameters] = useState<ApiParameters[]>([]);
    const [ready, response, isLoading, error, execute] = useApi<documentMapping>();
    const [finished, setFinished] = useState(false);
    const [statusMessage, setStatusMessage] = useState<StatusMessage | null>(null);

    const columns: IColumn[] = [
        {
            key: 'idColumn',
            name: 'BigId',
            fieldName: 'bigId',
            minWidth: 100,
            maxWidth: 150,
            isResizable: true,
            data: 'string',
            isPadded: true,
        },
        {
            key: 'ridColumn',
            name: 'RevisionId',
            fieldName: 'revisionId',
            minWidth: 100,
            maxWidth: 150,
            isResizable: true,
            data: 'string',
            isPadded: true,
        },
        {
            key: 'stateColumn',
            name: 'State',
            fieldName: 'state',
            minWidth: 50,
            maxWidth: 100,
            isResizable: true,
            data: 'string',
            isPadded: true,
        },
        {
            key: 'versionColumn',
            name: 'Version',
            fieldName: 'version',
            minWidth: 50,
            maxWidth: 100,
            isResizable: true,
            data: 'string',
            isPadded: true,
        },
        {
            key: 'ctTimeColumn',
            name: 'Created Time',
            fieldName: 'createdTime',
            minWidth: 180,
            maxWidth: 200,
            isResizable: true,
            data: 'string',
            isPadded: true,
        },
    ];


    useEffect(() => {
        if (finished) {
            return;
        }
        props.bigIdVersionIds.forEach((bigIdVersion) => {
            const request = {
                bigId: bigIdVersion[0],
                versionId: bigIdVersion[1],
            } as GetDocumentMappingRequest;

            updateApiParameters(preApiParas => [...preApiParas, pcdApiClient.getDocumentMapping(request)]);
        });
    }, [props]);

    useEffect(() => {
        if (ready && !finished && apiParameters.length !== 0) {
            execute(apiParameters);
        }
    }, [ready]);

    useEffect(() => {
        if (response) {
            setFinished(true);
            if (Array.isArray(response)) {
                const documents = response as documentMapping[];
                if (documents.length === 0) {
                    return;
                }

                updateDocMapping(documents);

            } else {
                const document = response as documentMapping;

                updateDocMapping([document]);
            }
            setFinished(true);
            if (statusMessage) {
                setStatusMessage(null);
            }
        }
    }, [response]);

    useEffect(() => {
        if (error) {
            setStatusMessage(error);
        }
    }, [error]);


    const [items, updateItems] = useState<documentMapping[]>(docMapping);

    useEffect(() => {
        updateItems(docMapping);
    }, [docMapping]);

    return (
        <div>
            {isLoading && (<Spinner label="Loading..." />)}
            {items.length !== 0 && <DetailsList
                items={items}
                compact={false}
                columns={columns}
                selectionMode={SelectionMode.none}
                setKey="multiple"
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                selectionPreservedOnEmptyClick={true}
                enterModalSelectionOnTouch={true}
                ariaLabelForSelectionColumn="Toggle selection"
                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                checkButtonAriaLabel="select row"
            />}
            <StatusMessageBar message={statusMessage} isMultiline />
        </div>
    );
}
