import React from 'react'
import { IColumn, IStackStyles, IStackTokens, Stack } from "@fluentui/react";
import { SubDocButton } from "../sessions/subDocDownLoadButton";
import { Session } from "./session";

export const getIntentsListColumns = (): IColumn[] => {
      const stackStyles: Partial<IStackStyles> = {
        root: {
          margin: "0 auto",
          color: "#605e5c",
        },
      };
      const numericalSpacingStackTokens: IStackTokens = {
        childrenGap: 10,
        padding: 0,
    };
    return [
        {
            key: 'sessionIdColumn',
            name: 'SessionId',
            fieldName: 'sessionId',
            minWidth: 100,
            maxWidth:120,
            isResizable: true,
            data: 'number',
            isPadded: true,
        },
        {
            key: 'documentIdColumn',
            name: 'DocumentId',
            fieldName: 'documentId',
            minWidth: 450,
            isResizable: true,
            data: 'string',
            isPadded: true,
        },
        {
            key: 'createdDateTimeColumn',
            name: 'Date Created',
            fieldName: 'createdDateTime',
            minWidth: 200,
            maxWidth:230,
            isResizable: true,
            data: 'string',
            onRender: (item: Session) => {
                return <span>{item.createdDateTime}</span>;
            },
            isPadded: true,
        },
        {
            key: 'stateColumn',
            name: 'Ingestion State',
            fieldName: 'state',
            minWidth: 130,
            isResizable: true,
            data: 'string',
            isPadded: true,
        },
        
    ];
};


