import React, { useState } from "react"
import externaleApi, { GetManifestDocRequest } from "../services/externalApiClient"
import { useApi } from "../hooks/useApi"
import { StatusMessage, StatusMessageBar } from "../statusMessageBar"
import { ILabelStyles, IStackStyles, IStackTokens, IStyleSet, Label, Spinner, Stack, Text } from '@fluentui/react';
import { ManifestDoc, manifestProductDocument } from "../models/productDocument";
import { DocumentDownLoad } from "./documentDownLoad";
import { MainifestSession } from "../models/session";
import { count } from "console";

export interface ManifestDocProps {
    session:  MainifestSession
}
export interface DocumentIdList {
    documentId:  string;
    count: number;
}
export interface Docs {
    docs:  any[];
    count: number;
}
const labelStyles: ILabelStyles = {
    root: { marginTop: 3 },
};
const labelTagStyles: ILabelStyles = {
    root: {
        margin: 3,
        padding: 5,
        fontSize: 12,
        lineHeight: 15,
        display: "inline-block",
        border: '0.5px solid #ccc',
        borderRadius: 4,
        color: '#55afff',
        textAlign: 'center',
    }
}
const stackTokens: IStackTokens = { childrenGap: 15 };
const stackStyles: Partial<IStackStyles> = {
    root: {
        margin: '0 auto',
        color: '#605e5c',
    },
};
export function ManifestDocument(props: ManifestDocProps) {
    const [request, updateRequest] = useState<GetManifestDocRequest>({} as GetManifestDocRequest)
    const [ready, response, isLoading, error, execute] = useApi<any>();
    const [statusMessage, setStatusMessage] = useState<StatusMessage | null>(null);
    const [productType, setProductType] = useState<DocumentIdList[]>([]);
    const [values,setValues] = useState<any[]>([]);
    const [manifestDoc, setManifestDoc] = useState<ManifestDoc[]>([]);
    const [requestOver, setRequestOver] = useState<boolean>(false);
    const [fileCount, setFileCount] = useState<number>(0);
    React.useEffect(() => {
        const request = {
            revisionId: props.session.revisionId,
            queryOptions: { PageSize: 1, continuationToken: "" }
        } as GetManifestDocRequest

        updateRequest(request);
    }, [props]);

    React.useEffect(() => {
        if (ready) {
            execute([externaleApi.queryManifestDocAsync(request)]);
        }
    }, [request, ready]);
    React.useEffect(() => {
        if (response) {
            if (response.ContinuationToken != null) {
                setValues([...values,...response.Items.$values]);
               updateManifestDoc(response);
            }
            else {
                setValues([...values,...response.Items.$values]);
                setRequestOver(true);
                if (statusMessage) {
                    setStatusMessage(null);
                }
            }
        }
    },
    [response]);

    React.useEffect(() => {
        if (error) {
            setStatusMessage(error);
        }
    }, [error]);

    React.useEffect(() => {
      if (requestOver)
      {
        if (values.length === 0) {
            setStatusMessage({ type: "error", message: "No documents found." });
            return
        }
        setFileCount(values.length);
        const groupedValues = values.reduce((acc, value) => {
            const documentId = value.DocumentId;
            if (!acc[documentId]) {
            acc[documentId] = { count: 0, docs: [] };
            }
            acc[documentId].count++;
            acc[documentId].docs.push(value);
            return acc;
        }, {});
        const transformedValues = Object.entries(groupedValues).map(([key, value]: [string, any]) => (
            {
            key:key,
            docs:value.docs,
            count:value.count
        } as ManifestDoc));
        setManifestDoc(transformedValues);

      }
    }, [requestOver]);

    React.useEffect(() => {
        if (manifestDoc.length > 0) {
            setProductType(manifestDoc.map((item) => ({ documentId: item.key, count: item.count} as DocumentIdList)));
        }
    }, [manifestDoc]);
    
    return (
        <div>
            <Stack horizontalAlign="start" verticalAlign="start" verticalFill styles={stackStyles} tokens={stackTokens}>
                <StatusMessageBar message={statusMessage} isMultiline />
                <Label styles={labelStyles}>ManifestDocument Details </Label>
                <Text>RevisionId: {props.session.revisionId}</Text>
                <Text>CV: {props.session.correlationVector}</Text>
                <Text>DocumentId: {productType.map((item, index) => {
                    return <Label styles={labelTagStyles} key={index}>{item.documentId} ({item.count})</Label>
                })} </Text>
            </Stack>
            {manifestDoc.length !== 0 ? (
            <Stack horizontalAlign="start" verticalAlign="start" verticalFill styles={stackStyles} style={{marginTop:40}} tokens={stackTokens}>
            <DocumentDownLoad manifestDoc={manifestDoc} fileCount={fileCount} />
            </Stack> 
        ) : (
            statusMessage?.type === "error" ? (<div></div>):(
            <Spinner label="Loading..." />)
        )}
           
        </div>
    )

    function updateManifestDoc(response: any) {
        let continuationToken: string = response.ContinuationToken;
        const request = {
            revisionId: props.session.revisionId,
            queryOptions: { PageSize: 1, continuationToken: continuationToken }
        } as GetManifestDocRequest
        updateRequest(request);
    }
    
}