import React, { FormEvent, useEffect, useState } from 'react';
import { DatePicker, DefaultButton, IButtonStyles, IComboBox, IStackStyles, ITimeRange, PrimaryButton, Stack, TextField, TimePicker, mergeStyleSets} from '@fluentui/react';
import VF from '../util/ValueFormatting';
const styles = mergeStyleSets({
  root: {
    margin: '0 10px 10px 10px',
    selectors: {
      '.ms-DatePicker': {
        width: '100%',
      },
    },
  },
});
const datePickerStyles = {
  textField: {
    width: 300,
  }
};
const textStyles: IStackStyles = {
  root: {
      marginTop: 30,
      marginLeft:10,
      marginRight:5,
      width: 45
  }


}
const ButtonStyle: IButtonStyles ={
  root:{
    marginRight:10,
    marginTop:30
  }
}
interface ChildProps {
  onSearchClick: (starTime:Date|null,endTime:Date|null,flag:string) => void
  onCleanClick: (starTime:Date|null,endTime:Date|null,flag:string)=>void
  start: Date
  end: Date
}
enum TimeType {
  Start = "Start",
  End = "End"
}

export function SearchComponent(props:ChildProps) {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [starTime, setStarTime] = React.useState<Date|null>(null);
  const [endTime, setEndTime] = React.useState<Date|null>(null);
  const [startDays, setStartDays] = React.useState('');
  const [endDays, setendDays] = React.useState('');
  const [labelEnd, setEndLabel] = useState('End Time');
  const [labelStart, setStartLabel] = useState('Start Time');
  const timeRange: ITimeRange = {
    start: 0,
    end: 24,
  };
  const onDateChange =(param :string)=>(date: Date | null | undefined) => {
    if(param == TimeType.Start){
      setStartDate(date ?? null);
      setStarTime(date ?? null)
    }
    else
    {
      setEndDate(date ?? null);
      date?.setHours(23)
      date?.setMinutes(59)
      date?.setMilliseconds(0)
      setEndTime(date ?? null)
    }
   
  };

  useEffect(() => {
  setStartDate(props.start)
  setEndDate(props.end)
  setStarTime(props.start)
  setEndTime(props.end)
}, [props.start,props.end]);


  const _searchClicked = () => {
    props.onSearchClick(starTime as Date, endTime as Date,"search")
  };

  function _initClicked() {
    setStartDate(null);
    setEndDate(null);
    setEndTime(null)
    setStarTime(null)
    setStartDays('')
    setendDays('')
    props.onCleanClick(null,null,"clean")
  }
  
  
  const _starTimeShowTimePicker = (param : string) => (event: React.FormEvent<IComboBox>, time: Date) => {

    if (time.toString()=="Invalid Date")
    {
      setStartDate(null)
      setStarTime(null)
      return
    }

  if (param == TimeType.Start)
  {
    if (startDate == null)
    {
      const startDateTime = new Date(time);
      startDateTime.setDate(startDateTime.getDate());
      setStartDate(startDateTime)
      setStarTime(startDateTime)
    }
    else{
    setStartDate(startDate)
    setStarTime(time)
    }
  }
  else
  {
    if (endDate == null)
    {
      const endDateTime = new Date(time);
      endDateTime.setDate(endDateTime.getDate());
      setEndDate(endDateTime)
      setEndTime(endDateTime)
    }
    else{
    setEndDate(endDate)
    setEndTime(time)
    }
  }
  }
   const getNumber = (param:string)=>(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) =>{
    if (param == TimeType.Start)
    {
      const starDayTime = new Date();
      starDayTime.setDate(starDayTime.getDate()+(Number(newValue)))
      setStartDate(starDayTime)
      setStartDays(newValue as string)
      starDayTime.setHours(0)
      starDayTime.setMinutes(0)
      starDayTime.setSeconds(0)
      setStarTime(starDayTime)
    }
    else
    {
      const endDayTime = new Date();
      endDayTime.setDate(endDayTime.getDate()+(Number(newValue)))
      setEndDate(endDayTime)
      setendDays(newValue as string)
      endDayTime.setHours(23,59,59)
      setEndTime(endDayTime)
    }
   }
  return (
    <Stack horizontal>
      <div className={styles.root}>
        <DatePicker
          styles={datePickerStyles}
          label={labelStart}
          formatDate={date=>VF.onFormatDate(date as Date)}
          value={startDate as Date}
          onSelectDate={onDateChange(TimeType.Start)}
          placeholder="please select start time"
        />
        <TimePicker
          placeholder="Select a time"
          timeRange={timeRange}
          increments={30}
          showSeconds
          dateAnchor={startDate as Date}
          value={starTime as Date}
          onChange={_starTimeShowTimePicker(TimeType.Start)}
        />
      </div>
      <div className={styles.root}>
        <DatePicker
          label={labelEnd}
          styles={datePickerStyles}
          formatDate={date=>VF.onFormatDate(date as Date)}
          value={endDate as Date}
          onSelectDate={onDateChange(TimeType.End)}
          placeholder="please select end time"
        />
          <TimePicker
          placeholder="Select a time"
          timeRange={timeRange}
          increments={30}
          showSeconds
          dateAnchor={endDate as Date}
          value={endTime as Date}
          onChange={_starTimeShowTimePicker(TimeType.End)}
        />
      </div>
      <Stack horizontal>
      <TextField styles={textStyles} type="number" maxLength={50} value={startDays} onChange={getNumber(TimeType.Start)}/>
      <div style={{marginTop:35}}>-</div>
      <TextField styles={textStyles} type="number" maxLength={50} value={endDays} onChange={getNumber(TimeType.End)}/>
        </Stack>
      <Stack horizontal >
      <PrimaryButton styles={ButtonStyle} text="Search" onClick={_searchClicked} allowDisabledFocus  />
      <DefaultButton styles={ButtonStyle} text="Clean" onClick={_initClicked} allowDisabledFocus  />
      </Stack>
      </Stack>
  );
}
