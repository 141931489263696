import {
  IStackStyles,
  Spinner,
  Stack,
  IStackTokens,
  Link,
} from "@fluentui/react";
import { mergeStyles } from "@fluentui/react/lib/Styling";
import React, { useState, useEffect } from "react";
import { useApi, ApiParameters } from "../hooks/useApi";
import { OrchestrationEvent } from "../models/orchestrationEvent";
import devOpsApi, { GetEventsRequest } from "../services/devOpsApiClient";
import { StatusMessage, StatusMessageBar } from "../statusMessageBar";
import { SwatchColorPicker } from "@fluentui/react/lib/SwatchColorPicker";
import { Environment, getEnvironment } from "../../config/environmentConfig";
import { Session } from "../models/session";

const stackStyles: IStackStyles = {
  root: {
    width: 200,
    height: 575,
  },
};
const stackStylesMain: IStackStyles = {
  root: {
    width: 1200,

  },
};
const stackLineStyles: IStackStyles = {
  root: {
    width: 65,
    height: 575,
  },
};
const headingStackTokens: IStackTokens = { childrenGap: 20 };
const roundSpacingStackTokens: IStackTokens = {
  childrenGap: "20%",
  padding: "m 20px",
};
const lineSpacingStackTokens: IStackTokens = {
  childrenGap: "0%",
};
const itemAlignmentsStackStyles: IStackStyles = {
  root: {
    height: 60,
    float: "right",
  },
};
const itemAlignmentsStackTokens: IStackTokens = {
  childrenGap: 5,
  padding: 10,
};
const itemStyles: React.CSSProperties = {
  alignItems: "center",
  display: "flex",
  height: 190,
  justifyContent: "center",
  width: 100,
};

const itemStyles2: React.CSSProperties = {
  alignItems: "center",
  display: "flex",
  height: 180,
  justifyContent: "center",
  width: 100,
  marginTop: -90,
};
const greenRoundClass = mergeStyles({
  width: 160,
  height: 160,
  backgroundColor: "green",
  borderRadius: 80,
  textAlign: "center",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "white",
  fontSize: "14px",
  padding: 5,
});
const greenRoundClass2 = mergeStyles({
  width: 160,
  height: 160,
  backgroundColor: "green",
  borderRadius: 80,
  textAlign: "center",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "white",
  fontSize: "14px",
  padding: 5,
});
const yellowRoundClass = mergeStyles({
  width: 160,
  height: 160,
  backgroundColor: "yellow",
  borderRadius: 80,
  textAlign: "center",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "black",
  fontSize: "14px",
  padding: 5,
});
const redRoundClass = mergeStyles({
  width: 160,
  height: 160,
  backgroundColor: "red",
  borderRadius: 80,
  textAlign: "center",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "white",
  fontSize: "14px",
  padding: 5,
});
const grayRoundClass = mergeStyles({
  width: 160,
  height: 160,
  backgroundColor: "gray",
  borderRadius: 80,
  textAlign: "center",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "white",
  fontSize: "14px",
  padding: 5,
});
const legendClass = mergeStyles({
  fontSize: 14,
  fontWeight: 400,
});
const colorCellsExample = [
  { id: "a", label: "Not Start", color: "gray" },
  { id: "b", label: "Started", color: "yellow" },
  { id: "c", label: "Success", color: "green" },
  { id: "d", label: "Failed", color: "red" },
];

export interface OverViewProps {
  documentId: string;
  sessionId: string;
  session: Session;
}

export function SessionOverView(props: OverViewProps) {
  const [events, updateEvents] = React.useState<OrchestrationEvent[]>([]);
  const [apiParameters, updateApiParameters] = useState<ApiParameters[]>([]);
  const [ready, response, isLoading, error, execute] = useApi<any>();
  const [statusMessage, setStatusMessage] = useState<StatusMessage | null>(
    null
  );
  const sessionMonitoringEvent = "sessionmonitoringevent";
  const [eventsClass, setEventsClass] = React.useState<string[]>([]);
  const [costTime, setCostTime] = React.useState<string[]>([]);
  const [intervalTime, setIntervalTime] = React.useState<string[]>([]);
  const enviromentName = +getEnvironment();

  useEffect(() => {
    const request = {
      productId: props.documentId,
      revisionId: props.sessionId,
    } as GetEventsRequest;

    updateApiParameters([devOpsApi.getEvents(request)]);
  }, [props]);

  useEffect(() => {
    if (ready) {
      execute(apiParameters);
    }
  }, [apiParameters, ready]);

  useEffect(() => {
    if (response) {
      const documents = response.TrackingEvents as OrchestrationEvent[];
      if (documents.length === 0) {
        return;
      }
      const sortDocuments = documents
        .slice()
        .sort(
          (pre, after) =>
            new Date(pre.eventTime.toString()).getTime() -
            new Date(after.eventTime.toString()).getTime()
        );
      updateEvents(sortDocuments);

      if (statusMessage) {
        setStatusMessage(null);
      }
    }
  }, [response]);

  useEffect(() => {
    if (error) {
      setStatusMessage(error);
    }
  }, [error]);

  useEffect(() => {
    if (events.length > 0) {
      updateEventsInfo();
    }
  }, [events]);

  function getDateDiff(startDate: Date, endDate: Date, nowDate: Date) {
    var sd = Date.parse(startDate.toString());
    var ed = Date.parse(endDate.toString());
    var now = Date.parse(nowDate.toString());
    if (sd === now || ed === now) {
      return "n/a";
    }
    var drr = ed - sd;
    var day = parseInt((drr / (24 * 60 * 60 * 1000)).toString());
    var hours = parseInt(
      ((drr % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000)).toString()
    );
    var minutes = parseInt(((drr % (60 * 60 * 1000)) / (60 * 1000)).toString());
    var seconds = parseInt(((drr % (60 * 1000)) / 1000).toString());
    var result =
      (day === 0 ? "" : day + "d") +
      (hours === 0 ? "" : hours + "h") +
      (minutes === 0 ? "" : minutes + "m") +
      (seconds === 0 ? "" : seconds + "s");
    return result === "" ? "0s" : result;
  }

  function updateEventsInfo() {
    let classArray: string[] = [
      grayRoundClass,
      grayRoundClass,
      grayRoundClass,
      grayRoundClass,
      grayRoundClass,
      grayRoundClass,
      grayRoundClass,
      grayRoundClass,
      grayRoundClass,
      grayRoundClass
    ];
    let timeArray: string[] = ["", "", "", "", "", "", "", "", "", ""];
    let intervalArray: string[] = ["", "", "", "", "", "", "", "", ""];
    const sessionMonitoringEvents = events.filter(
      (e) => e.eventType.toLocaleLowerCase() === sessionMonitoringEvent
    );
    let rateCardSyncServiceCount = 0;
    let ratingFeedSyncServiceCount = 0;
    const now = new Date();
    let ingestionApiStartTime: Date = now;
    let ingestionApiEndTime: Date = now;
    let ingestionApiRecentEndTime: Date = now;
    let pcdConverterServiceStartTime: Date = now;
    let pcdConverterServiceEndTime: Date = now;
    let pcdConverterServiceRecentEndTime: Date = now;
    let pcdSyncServiceStartTime: Date = now;
    let pcdSyncServiceEndTime: Date = now;
    let pcdSyncServiceRecentEndTime: Date = now;
    let transformSyncServiceStartTime: Date = now;
    let transformSyncServiceEndTime: Date = now;
    let transformSyncServiceRecentEndTime: Date = now;
    let rateCardConverterServiceStartTime: Date = now;
    let rateCardConverterServiceEndTime: Date = now;
    let rateCardConverterServiceRecentEndTime: Date = now;
    let rateCardSyncServiceStartTime: Date = now;
    let rateCardSyncServiceEndTime: Date = now;
    let rateCardSyncServiceRecentEndTime: Date = now;
    let ratingFeedConverterServiceStartTime: Date = now;
    let ratingFeedConverterServiceEndTime: Date = now;
    let ratingFeedConverterServiceRecentEndTime: Date = now;
    let ratingFeedSyncServiceStartTime: Date = now;
    let ratingFeedSyncServiceEndTime: Date = now;
    let ratingFeedSyncServiceRecentEndTime: Date = now;
    let ICSStartTime: Date = now;
    let ICSEndTime: Date = now;
    let ICSRecentEndTime: Date = now;
    let PayloadReplicationEventStartTime: Date = now;
    let PayloadReplicationEventEndTime: Date = now;
    let PayloadReplicationEventRecentEndTime: Date = now;
    sessionMonitoringEvents.forEach((element) => {
      let eventName = JSON.parse(
        JSON.stringify(element.data)
      ).EventName.toString();
      let subState = JSON.parse(
        JSON.stringify(element.data)
      ).SubState.toString();
      switch (eventName) {
        case "IngestionApiNewRequestEvent":
          if (subState === "Failed") classArray[0] = redRoundClass;
          else if (
            subState === "Success" &&
            classArray[0] !== redRoundClass &&
            classArray[0] !== greenRoundClass
          )
            classArray[0] = yellowRoundClass;
          if (
            Date.parse(ingestionApiStartTime.toString()) ===
            Date.parse(now.toString())
          )
            ingestionApiStartTime = element.eventTime;
          break;
        case "IngestionApiRequestCompleteEvent":
          if (subState === "Failed" && classArray[0] !== greenRoundClass)
            classArray[0] = redRoundClass;
          if (subState === "Success") classArray[0] = greenRoundClass;
          if (
            Date.parse(ingestionApiEndTime.toString()) ===
            Date.parse(now.toString())
          )
            ingestionApiEndTime = element.eventTime;
          ingestionApiRecentEndTime = element.eventTime;
          break;
        case "PcdConverterServiceNewRequestEvent":
          if (subState === "Failed") classArray[1] = redRoundClass;
          else if (
            subState === "Success" &&
            classArray[1] !== redRoundClass &&
            classArray[1] !== greenRoundClass
          )
            classArray[1] = yellowRoundClass;
          if (
            Date.parse(pcdConverterServiceStartTime.toString()) ===
            Date.parse(now.toString())
          )
            pcdConverterServiceStartTime = element.eventTime;
          break;
        case "PcdConverterServiceRequestCompleteEvent":
          if (subState === "Failed" && classArray[1] !== greenRoundClass)
            classArray[1] = redRoundClass;
          if (subState === "Success" || subState === "Skipped")
            classArray[1] = greenRoundClass;
          if (
            Date.parse(pcdConverterServiceEndTime.toString()) ===
            Date.parse(now.toString())
          )
            pcdConverterServiceEndTime = element.eventTime;
          pcdConverterServiceRecentEndTime = element.eventTime;
          break;
        case "PcdSyncServiceNewRequestEvent":
          if (subState === "Failed") classArray[2] = redRoundClass;
          else if (
            subState === "Success" &&
            classArray[2] !== redRoundClass &&
            classArray[2] !== greenRoundClass
          )
            classArray[2] = yellowRoundClass;
          if (
            Date.parse(pcdSyncServiceStartTime.toString()) ===
            Date.parse(now.toString())
          )
            pcdSyncServiceStartTime = element.eventTime;
          break;
        case "PcdSyncServiceRequestCompleteEvent":
          if (subState === "Failed" && classArray[2] !== greenRoundClass)
            classArray[2] = redRoundClass;
          if (subState === "Success") classArray[2] = greenRoundClass;
          if (
            Date.parse(pcdSyncServiceEndTime.toString()) ===
            Date.parse(now.toString())
          )
            pcdSyncServiceEndTime = element.eventTime;
          pcdSyncServiceRecentEndTime = element.eventTime;
          break;
        case "TransformSyncServiceNewRequestEvent":
          if (subState === "Failed") classArray[3] = redRoundClass;
          else if (
            subState === "Success" &&
            classArray[3] !== redRoundClass &&
            classArray[3] !== greenRoundClass
          )
            classArray[3] = yellowRoundClass;
          if (
            Date.parse(transformSyncServiceStartTime.toString()) ===
            Date.parse(now.toString())
          )
            transformSyncServiceStartTime = element.eventTime;
          break;
        case "TransformSyncServiceRequestCompleteEvent":
          if (subState === "Failed" && classArray[3] !== greenRoundClass)
            classArray[3] = redRoundClass;
          if (subState === "Success") classArray[3] = greenRoundClass;
          if (
            Date.parse(transformSyncServiceEndTime.toString()) ===
            Date.parse(now.toString())
          )
            transformSyncServiceEndTime = element.eventTime;
          transformSyncServiceRecentEndTime = element.eventTime;
          break;
        case "RateCardConverterServiceNewRequestEvent":
          if (subState === "Failed") classArray[4] = redRoundClass;
          else if (
            subState === "Success" &&
            classArray[4] !== redRoundClass &&
            classArray[4] !== greenRoundClass
          )
            classArray[4] = yellowRoundClass;
          if (
            Date.parse(rateCardConverterServiceStartTime.toString()) ===
            Date.parse(now.toString())
          )
            rateCardConverterServiceStartTime = element.eventTime;
          break;
        case "RateCardConverterServiceRequestCompleteEvent":
          if (subState === "Failed" && classArray[4] !== greenRoundClass)
            classArray[4] = redRoundClass;
          if (subState === "Success" || subState === "Skipped")
            classArray[4] = greenRoundClass;
          if (
            Date.parse(rateCardConverterServiceEndTime.toString()) ===
            Date.parse(now.toString())
          )
            rateCardConverterServiceEndTime = element.eventTime;
          rateCardConverterServiceRecentEndTime = element.eventTime;
          break;
        case "RateCardSyncServiceNewRequestEvent":
          if (subState === "Failed") classArray[5] = redRoundClass;
          else if (
            subState === "Success" &&
            classArray[5] !== redRoundClass &&
            classArray[5] !== greenRoundClass
          )
            classArray[5] = yellowRoundClass;
          if (
            Date.parse(rateCardSyncServiceStartTime.toString()) ===
            Date.parse(now.toString())
          )
            rateCardSyncServiceStartTime = element.eventTime;
          break;
        case "RateCardSyncServiceRequestCompleteEvent":
          if (subState === "Failed" && classArray[5] !== greenRoundClass)
            classArray[5] = redRoundClass;
          if (subState === "Success" || subState === "Skipped") {
            rateCardSyncServiceCount++;
            if (rateCardSyncServiceCount === 5) classArray[5] = greenRoundClass;
          }
          if (
            Date.parse(rateCardSyncServiceEndTime.toString()) ===
            Date.parse(now.toString())
          )
            rateCardSyncServiceEndTime = element.eventTime;
          rateCardSyncServiceRecentEndTime = element.eventTime;
          break;
        case "RatingFeedConverterServiceNewRequestEvent":
          if (subState === "Failed") classArray[6] = redRoundClass;
          else if (
            subState === "Success" &&
            classArray[6] !== redRoundClass &&
            classArray[6] !== greenRoundClass
          )
            classArray[6] = yellowRoundClass;
          if (
            Date.parse(ratingFeedConverterServiceStartTime.toString()) ===
            Date.parse(now.toString())
          )
            ratingFeedConverterServiceStartTime = element.eventTime;
          break;
        case "RatingFeedConverterServiceRequestCompleteEvent":
          if (subState === "Failed" && classArray[6] !== greenRoundClass)
            classArray[6] = redRoundClass;
          if (
            subState === "Skipped" &&
            classArray[6] !== greenRoundClass &&
            classArray[6] !== redRoundClass
          )
            classArray[6] = greenRoundClass2;
          if (subState === "Success") classArray[6] = greenRoundClass;
          if (
            Date.parse(ratingFeedConverterServiceEndTime.toString()) ===
            Date.parse(now.toString())
          )
            ratingFeedConverterServiceEndTime = element.eventTime;
          ratingFeedConverterServiceRecentEndTime = element.eventTime;
          break;
        case "RatingFeedSyncServiceNewRequestEvent":
          if (subState === "Failed") classArray[7] = redRoundClass;
          else if (
            subState === "Success" &&
            classArray[7] !== redRoundClass &&
            classArray[7] !== greenRoundClass
          )
            classArray[7] = yellowRoundClass;
          if (
            Date.parse(ratingFeedSyncServiceStartTime.toString()) ===
            Date.parse(now.toString())
          )
            ratingFeedSyncServiceStartTime = element.eventTime;
          break;
        case "RatingFeedSyncServiceRequestCompleteEvent":
          if (subState === "Failed" && classArray[7] !== greenRoundClass)
            classArray[7] = redRoundClass;
          if (subState === "Success" || subState === "Skipped") {
            ratingFeedSyncServiceCount++;
            if (ratingFeedSyncServiceCount === 2)
              classArray[7] = greenRoundClass;
          }
          if (
            Date.parse(ratingFeedSyncServiceEndTime.toString()) ===
            Date.parse(now.toString())
          )
            ratingFeedSyncServiceEndTime = element.eventTime;
          ratingFeedSyncServiceRecentEndTime = element.eventTime;
          break;
        case "ICSNewRequestEvent":
          if (subState === "Failed") classArray[8] = redRoundClass;
          else if (
            subState === "Success" &&
            classArray[8] !== redRoundClass &&
            classArray[8] !== greenRoundClass
          )
            classArray[8] = yellowRoundClass;
          if (
            Date.parse(ICSStartTime.toString()) === Date.parse(now.toString())
          )
            ICSStartTime = element.eventTime;
          break;
        case "ICSRequestCompleteEvent":
          if (subState === "Failed" && classArray[8] !== greenRoundClass)
            classArray[8] = redRoundClass;
          if (subState === "Success") classArray[8] = greenRoundClass;
          if (Date.parse(ICSEndTime.toString()) === Date.parse(now.toString()))
            ICSEndTime = element.eventTime;
          ICSRecentEndTime = element.eventTime;
          break;
        case "PayloadReplicationNewRequestEvent":
          if (subState === "Failed") classArray[9] = redRoundClass;
          else if (
            subState === "Success" &&
            classArray[9] !== redRoundClass &&
            classArray[9] !== greenRoundClass
          )
            classArray[9] = yellowRoundClass;
          if (
            Date.parse(PayloadReplicationEventStartTime.toString()) === Date.parse(now.toString())
          )
          PayloadReplicationEventStartTime = element.eventTime;
          break;
        case "PayloadReplicationRequestCompleteEvent":
        if (subState === "Failed" && classArray[9] !== greenRoundClass)
          classArray[9] = redRoundClass;
        if (subState === "Success") classArray[9] = greenRoundClass;
        if (Date.parse(PayloadReplicationEventEndTime.toString()) === Date.parse(now.toString()))
        PayloadReplicationEventEndTime = element.eventTime;
        PayloadReplicationEventRecentEndTime = element.eventTime;
        break;
        default:
          break;
      }
    });
    timeArray[0] = getDateDiff(
      ingestionApiStartTime,
      ingestionApiRecentEndTime,
      now
    );
    timeArray[1] = getDateDiff(
      pcdConverterServiceStartTime,
      pcdConverterServiceRecentEndTime,
      now
    );
    timeArray[2] = getDateDiff(
      pcdSyncServiceStartTime,
      pcdSyncServiceRecentEndTime,
      now
    );
    timeArray[3] = getDateDiff(
      transformSyncServiceStartTime,
      transformSyncServiceRecentEndTime,
      now
    );
    timeArray[4] = getDateDiff(
      rateCardConverterServiceStartTime,
      rateCardConverterServiceRecentEndTime,
      now
    );
    timeArray[5] = getDateDiff(
      rateCardSyncServiceStartTime,
      rateCardSyncServiceRecentEndTime,
      now
    );
    timeArray[6] = getDateDiff(
      ratingFeedConverterServiceStartTime,
      ratingFeedConverterServiceRecentEndTime,
      now
    );
    timeArray[7] = getDateDiff(
      ratingFeedSyncServiceStartTime,
      ratingFeedSyncServiceRecentEndTime,
      now
    );
    timeArray[8] = getDateDiff(ICSStartTime, ICSRecentEndTime, now);
    timeArray[9] = getDateDiff(PayloadReplicationEventStartTime, PayloadReplicationEventRecentEndTime, now);
    intervalArray[0] = getDateDiff(
      ingestionApiEndTime,
      pcdConverterServiceStartTime,
      now
    );
    intervalArray[1] = getDateDiff(
      pcdConverterServiceEndTime,
      pcdSyncServiceStartTime,
      now
    );
    intervalArray[2] = getDateDiff(
      pcdConverterServiceEndTime,
      transformSyncServiceStartTime,
      now
    );
    intervalArray[3] = getDateDiff(
      ingestionApiEndTime,
      rateCardConverterServiceStartTime,
      now
    );
    intervalArray[4] = getDateDiff(
      rateCardConverterServiceEndTime,
      rateCardSyncServiceStartTime,
      now
    );
    intervalArray[5] = getDateDiff(
      ingestionApiEndTime,
      ratingFeedConverterServiceStartTime,
      now
    );
    intervalArray[6] = getDateDiff(
      ratingFeedConverterServiceEndTime,
      ratingFeedSyncServiceStartTime,
      now
    );
    intervalArray[7] = getDateDiff(pcdSyncServiceEndTime, ICSStartTime, now);
    intervalArray[8] = getDateDiff(ICSEndTime, PayloadReplicationEventStartTime, now);
    setCostTime(timeArray);
    setIntervalTime(intervalArray);
    setEventsClass(classArray);
  }

  const generateGenevaLink = (
    session: Session | undefined,
    serviceName: string
  ) => {
    if (session) {
      const namespaces =
        +getEnvironment() === Environment.PROD
          ? "OfferStoreIngestionProd,BigCatPublishNsPROD,BigCatStagingPROD"
          : "OfferStoreIngestionInt,BigCatPublishNsINT,BigCatStagingINT";

      return (
        `https://portal.microsoftgeneva.com/logs/dgrep?be=DGrep&time=${session.createdDateTime}&offset=%2B1&offsetUnit=Days&UTC=true` +
        `&ep=Diagnostics%20PROD&ns=${namespaces}&en=CustomEvents,Log` +
        `&conditions=[["cV","contains","${encodeURIComponent(encodeURI(
          session.correlationVector.substring(
            0,
            session.correlationVector.indexOf(".")
          )
        ))}"],["ext_cloud_role","%3D%3D","${serviceName}"]]`
      );
    }

    return "";
  };

  return enviromentName === Environment.AIRCAPI ||
    enviromentName === Environment.AIRCAPI_INT ? (
    <div style={{width:1200}}>
      {isLoading && <Spinner label="Loading..." />}
      {eventsClass.length === 10 && (
        <Stack  horizontal disableShrink tokens={headingStackTokens}>
          <Stack styles={stackStyles} tokens={roundSpacingStackTokens}>
            <Link
              href={generateGenevaLink(props.session, "offerstoreingestionapi")}
              title="Go to Geneva"
              target="_blank"
            >
              <div className={eventsClass[0]}>
                Ingestion API
                <br />
                {costTime[0]}
              </div>
            </Link>
          </Stack>
          <Stack styles={stackLineStyles} tokens={lineSpacingStackTokens}>
            <div style={itemStyles}>
              <svg>
                <defs>
                  <marker
                    id="markerArrow"
                    markerWidth="12"
                    markerHeight="12"
                    refX="9"
                    refY="6"
                    orient="auto"
                  >
                    <path
                      d="M2,2 L2,11 L10,6 L2,2"
                      style={{ fill: "rgb(120,120,120)" }}
                    />
                  </marker>
                </defs>
                <line
                  x1="0"
                  y1="80"
                  x2="100"
                  y2="80"
                  style={{
                    stroke: "rgb(120,120,120)",
                    strokeWidth: 2,
                  }}
                  markerEnd="url(#markerArrow)"
                />
                <text x="40" y="65" fill="gray">
                  {intervalTime[0]}
                </text>
              </svg>
            </div>
          </Stack>
          <Stack styles={stackStyles} tokens={roundSpacingStackTokens}>
            <Link
              href={generateGenevaLink(
                props.session,
                "offerstoreconverterservice"
              )}
              title="Go to Geneva"
              target="_blank"
            >
              <div className={eventsClass[1]}>
                Pcd Converter
                <br />
                {costTime[1]}
              </div>
            </Link>
          </Stack>
          <Stack
            styles={stackLineStyles}
            tokens={lineSpacingStackTokens}
            verticalAlign="center"
          >
            <div style={itemStyles}>
              <svg>
                <line
                  x1="0"
                  y1="80"
                  x2="100"
                  y2="80"
                  style={{
                    stroke: "rgb(120,120,120)",
                    strokeWidth: 2,
                  }}
                  markerEnd="url(#markerArrow)"
                />
                <text x="40" y="65" fill="gray">
                  {intervalTime[1]}
                </text>
              </svg>
            </div>
            <div style={itemStyles}>
            </div>
            <div style={itemStyles}>
            </div>
          </Stack>
          <Stack styles={stackStyles} tokens={roundSpacingStackTokens}>
            <Link
              href={generateGenevaLink(props.session, "offerstoresync")}
              title="Go to Geneva"
              target="_blank"
            >
              <div className={eventsClass[2]}>
                Pcd Sync
                <br />
                {costTime[2]}
              </div>
            </Link>
          </Stack>
          <Stack
            styles={stackLineStyles}
            tokens={lineSpacingStackTokens}
            verticalAlign="start"
          >
            <div style={itemStyles}>
              <svg>
                <line
                  x1="0"
                  y1="80"
                  x2="100"
                  y2="80"
                  style={{
                    stroke: "rgb(120,120,120)",
                    strokeWidth: 2,
                  }}
                  markerEnd="url(#markerArrow)"
                />
                <text x="40" y="65" fill="gray">
                  {intervalTime[7]}
                </text>
              </svg>
            </div>
          </Stack>
          <Stack styles={stackStyles} tokens={roundSpacingStackTokens}>
            <Link
              href={generateGenevaLink(
                props.session,
                "offerstoreingestioncompletesignal"
              )}
              title="Go to Geneva"
              target="_blank"
            >
              <div className={eventsClass[8]}>
                ICS
                <br />
                {costTime[8]}
              </div>
            </Link>
          </Stack>
          <Stack
            horizontal
            disableShrink
            styles={itemAlignmentsStackStyles}
            tokens={itemAlignmentsStackTokens}
          >
            <Stack.Item align="center">
              <span className={legendClass}>Legend:</span>
            </Stack.Item>
            <Stack.Item align="center">
              <SwatchColorPicker
                columnCount={4}
                cellShape={"circle"}
                cellHeight={30}
                cellWidth={30}
                cellBorderWidth={2}
                colorCells={colorCellsExample}
                aria-labelledby={"colorpicker-grid"}
              />
            </Stack.Item>
          </Stack>
        </Stack>
      )}
      <StatusMessageBar message={statusMessage} isMultiline />
    </div>
  ) : (
    <div>
      {isLoading && <Spinner label="Loading..." />}
      {eventsClass.length === 10 && (
        <Stack horizontal disableShrink tokens={headingStackTokens}>
          <Stack
            styles={stackStyles}
            tokens={roundSpacingStackTokens}
            verticalAlign="center"
          >
            <Link
              href={generateGenevaLink(props.session, "offerstoreingestionapi")}
              title="Go to Geneva"
              target="_blank"
            >
              <div className={eventsClass[0]}>
                Ingestion API
                <br />
                {costTime[0]}
              </div>
            </Link>
          </Stack>
          <Stack
            styles={stackLineStyles}
            tokens={lineSpacingStackTokens}
            verticalAlign="center"
          >
            <div style={itemStyles}>
              <svg>
                <defs>
                  <marker
                    id="markerArrow"
                    markerWidth="12"
                    markerHeight="12"
                    refX="9"
                    refY="6"
                    orient="auto"
                  >
                    <path
                      d="M2,2 L2,11 L10,6 L2,2"
                      style={{ fill: "rgb(120,120,120)" }}
                    />
                  </marker>
                </defs>
                <line
                  x1="0"
                  y1="150"
                  x2="100"
                  y2="90"
                  style={{
                    stroke: "rgb(120,120,120)",
                    strokeWidth: 2,
                  }}
                  markerEnd="url(#markerArrow)"
                />
                <text x="15" y="15" fill="gray" transform="rotate(-40 160,30)">
                  {intervalTime[0]}
                </text>
              </svg>
            </div>
            <div style={itemStyles}>
              <svg>
                <line
                  x1="0"
                  y1="80"
                  x2="100"
                  y2="80"
                  style={{
                    stroke: "rgb(120,120,120)",
                    strokeWidth: 2,
                  }}
                  markerEnd="url(#markerArrow)"
                />
                <text x="40" y="65" fill="gray">
                  {intervalTime[3]}
                </text>
              </svg>
            </div>
            <div style={itemStyles}>
              <svg>
                <line
                  x1="0"
                  y1="0"
                  x2="100"
                  y2="80"
                  style={{
                    stroke: "rgb(120,120,120)",
                    strokeWidth: 2,
                  }}
                  markerEnd="url(#markerArrow)"
                />
                <text x="15" y="10" fill="gray" transform="rotate(40 20,40)">
                  {intervalTime[5]}
                </text>
              </svg>
            </div>
          </Stack>
          <Stack styles={stackStyles} tokens={roundSpacingStackTokens}>
            <Link
              href={generateGenevaLink(
                props.session,
                "offerstoreconverterservice"
              )}
              title="Go to Geneva"
              target="_blank"
            >
              <div className={eventsClass[1]}>
                Pcd Converter
                <br />
                {costTime[1]}
              </div>
            </Link>
            <Link
              href={generateGenevaLink(
                props.session,
                "offerstoreratecardconverterservice"
              )}
              title="Go to Geneva"
              target="_blank"
            >
              <div className={eventsClass[4]}>
                RateCard Converter
                <br />
                {costTime[4]}
              </div>
            </Link>
            <Link
              href={generateGenevaLink(
                props.session,
                "offerstoreratingfeedconverterservice"
              )}
              title="Go to Geneva"
              target="_blank"
            >
              <div className={eventsClass[6]}>
                RatingFeed Converter
                <br />
                {costTime[6]}
              </div>
            </Link>
          </Stack>
          <Stack
            styles={stackLineStyles}
            tokens={lineSpacingStackTokens}
            verticalAlign="center"
          >
            <div style={itemStyles}>
              <svg>
                <line
                  x1="0"
                  y1="80"
                  x2="100"
                  y2="80"
                  style={{
                    stroke: "rgb(120,120,120)",
                    strokeWidth: 2,
                  }}
                  markerEnd="url(#markerArrow)"
                />
                <text x="40" y="65" fill="gray">
                  {intervalTime[1]}
                </text>
              </svg>
            </div>
            <div style={itemStyles}>
              <svg>
                <line
                  x1="0"
                  y1="80"
                  x2="100"
                  y2="80"
                  style={{
                    stroke: "rgb(120,120,120)",
                    strokeWidth: 2,
                  }}
                  markerEnd="url(#markerArrow)"
                />
                <text x="40" y="65" fill="gray">
                  {intervalTime[4]}
                </text>
              </svg>
            </div>
            <div style={itemStyles}>
              <svg>
                <line
                  x1="0"
                  y1="80"
                  x2="100"
                  y2="80"
                  style={{
                    stroke: "rgb(120,120,120)",
                    strokeWidth: 2,
                  }}
                  markerEnd="url(#markerArrow)"
                />
                <text x="40" y="65" fill="gray">
                  {intervalTime[6]}
                </text>
              </svg>
            </div>
          </Stack>
          <Stack styles={stackStyles} tokens={roundSpacingStackTokens}>
            <Link
              href={generateGenevaLink(props.session, "offerstoresync")}
              title="Go to Geneva"
              target="_blank"
            >
              <div className={eventsClass[2]}>
                Pcd Sync
                <br />
                {costTime[2]}
              </div>
            </Link>
            <Link
              href={generateGenevaLink(
                props.session,
                "offerstoreratecardsyncservice"
              )}
              title="Go to Geneva"
              target="_blank"
            >
              <div className={eventsClass[5]}>
                RateCard Sync
                <br />
                {costTime[5]}
              </div>
            </Link>
            <Link
              href={generateGenevaLink(
                props.session,
                "offerstoreratingfeedsyncservice"
              )}
              title="Go to Geneva"
              target="_blank"
            >
              <div className={eventsClass[7]}>
                RatingFeed Sync
                <br />
                {costTime[7]}
              </div>
            </Link>
          </Stack>
          <Stack
            styles={stackLineStyles}
            tokens={lineSpacingStackTokens}
            verticalAlign="start"
          >
            <div style={itemStyles}>
              <svg>
                <line
                  x1="0"
                  y1="80"
                  x2="100"
                  y2="80"
                  style={{
                    stroke: "rgb(120,120,120)",
                    strokeWidth: 2,
                  }}
                  markerEnd="url(#markerArrow)"
                />
                <text x="40" y="65" fill="gray">
                  {intervalTime[2]}
                </text>
              </svg>
            </div>
            <div style={itemStyles}>
              <svg>
                <line
                  x1="0"
                  y1="0"
                  x2="100"
                  y2="80"
                  style={{
                    stroke: "rgb(120,120,120)",
                    strokeWidth: 2,
                  }}
                  markerEnd="url(#markerArrow)"
                />
                <text x="15" y="10" fill="gray" transform="rotate(40 20,40)">
                  {intervalTime[7]}
                </text>
              </svg>
            </div>
          </Stack>
          <Stack styles={stackStyles} tokens={roundSpacingStackTokens}>
            <Link
              href={generateGenevaLink(
                props.session,
                "offerstoretransformsyncservice"
              )}
              title="Go to Geneva"
              target="_blank"
            >
              <div className={eventsClass[3]}>
                Transform Sync
                <br />
                {costTime[3]}
              </div>
            </Link>
            <Link
              href={generateGenevaLink(
                props.session,
                "offerstoreingestioncompletesignal"
              )}
              title="Go to Geneva"
              target="_blank"
            >
              <div className={eventsClass[8]}>
                ICS
                <br />
                {costTime[8]}
              </div>
            </Link>
          </Stack>
          <Stack
            styles={stackLineStyles}
            tokens={lineSpacingStackTokens}
            verticalAlign="start"
          >
            <div style={itemStyles}>
              <svg>
              </svg>
            </div>
            <div style={itemStyles2}>
              <svg>
                <line
                       x1="0"
                       y1="180"
                       x2="90"
                       y2="60"
                  style={{
                    stroke: "rgb(120,120,120)",
                    strokeWidth: 2,
                  }}
                  markerEnd="url(#markerArrow)"
                />
                <text x="25" y="105" fill="gray" transform="rotate(-40,30,90)">
                  {intervalTime[7]}
                </text>
              </svg>
            </div>
          </Stack>
          <Stack styles={stackStyles} tokens={roundSpacingStackTokens}>
            <Link
              href={generateGenevaLink(
                props.session,
                "offerstoreingestioncompletesignal"
              )}
              title="Go to Geneva"
              target="_blank"
            >
              <div className={eventsClass[9]}>
              Cloud Replicator
                <br />
                {costTime[9]}
              </div>
            </Link>
          </Stack>
          <Stack
            horizontal
            disableShrink
            styles={itemAlignmentsStackStyles}
            tokens={itemAlignmentsStackTokens}
          >
            <Stack.Item align="center">
              <span className={legendClass}>Legend:</span>
            </Stack.Item>
            <Stack.Item align="center">
              <SwatchColorPicker
                columnCount={4}
                cellShape={"circle"}
                cellHeight={30}
                cellWidth={30}
                cellBorderWidth={2}
                colorCells={colorCellsExample}
                aria-labelledby={"colorpicker-grid"}
              />
            </Stack.Item>
          </Stack>
        </Stack>
      )}
      <StatusMessageBar message={statusMessage} isMultiline />
    </div>
  );
}
