import { EndpointType, getEndpoint } from "../../config/endpointConfig";
import { ApiParameters } from '../hooks/useApi';

export interface GetPublishSessionRequest {
    publishMode: string;
    revisionId: string;
    bigcatVersion: string
}

export interface GetBigcatVersionRequest {
    productId: string;
    revisionId: string;
}

export interface GetRatingFeedRequest {
    productId: string;
    revisionId: string;
    publishMode: string;
}

const apiSubPath: string = "RatingFeed";

function getBaseUrl() {
    return getEndpoint(EndpointType.DevOpsApi);
}

const getGetPublishSession = (request: GetPublishSessionRequest) => {
    var url = new URL(
        `${getBaseUrl()}/${apiSubPath}/publishSession/${request.publishMode}/${request.revisionId}/${request.bigcatVersion}`
    );
    return { uri: url.toString(), method: "GET" } as ApiParameters;
}

const getRatingFeedDocument = (request: GetRatingFeedRequest) => {
    var url = new URL(
        `${getBaseUrl()}/${apiSubPath}/documents/${request.productId}`
    );

    if (request.revisionId && request.revisionId !== "") {
        url.searchParams.set("revisionId", request.revisionId);
    }
    if (request.publishMode && request.publishMode !== "") {
        url.searchParams.set("mode", request.publishMode);
    }

    return { uri: url.toString(), method: "GET", responseType: "blob", } as ApiParameters;
}

const getRatingFeedBigcatVersions = (request: GetBigcatVersionRequest) => {
    var url = new URL(
        `${getBaseUrl()}/${apiSubPath}/bigcatVersion/${request.productId}/${request.revisionId}`
    );
    
    return { uri: url.toString(), method: "GET" } as ApiParameters;
}

const interfaces = {
    getGetPublishSession,
    getRatingFeedDocument,
    getRatingFeedBigcatVersions,
};

export default interfaces;