import { EndpointType, getEndpoint } from "../../config/endpointConfig";
import { ApiParameters } from "../hooks/useApi";

export interface GetPcdRequest {
  documentId: string;
  revisionId: string;
}

export interface GetPcdEntitiesRequest {
  documentId: string;
  revisionId: string;
  documentType: string;
}

export interface GetSyncRecordRequest {
  documentId: string;
  revisionId: string;
  documentType: string;
}

export interface GetDocumentMappingRequest {
  bigId: string;
  versionId: string;
}

export interface GetTestDocumentMappingRequest {
  bigId: string;
  configurationName: string;
}
export interface GetPublishRecordHistoryRequest {
  bigId: string;
}
function getBaseUrl() {
  return getEndpoint(EndpointType.DevOpsApi);
}

const apiPcdLookupSubPath: string = "PcdLookup";
const apiSubPath: string = "PcdConverter";

const getPcdData = (request: GetPcdRequest) => {
  var url = new URL(
    `${getEndpoint(EndpointType.PcdLookupApi)}/${apiPcdLookupSubPath}/pcds/${request.documentId}/${
      request.revisionId
    }`
  );

  return {
    uri: url.toString(),
    method: "GET",
    responseType: "blob",
  } as ApiParameters;
};

const getPcdEntities = (request: GetPcdEntitiesRequest) => {
  var url = new URL(
    `${getBaseUrl()}/${apiSubPath}/pcdEntities/${request.documentId}/${
      request.revisionId
    }/${request.documentType}`
  );

  return { uri: url.toString(), method: "GET" } as ApiParameters;
};

const getSyncRecord = (request: GetSyncRecordRequest) => {
  var url = new URL(
    `${getBaseUrl()}/${apiSubPath}/syncRecord/${request.documentId}/${
      request.revisionId
    }/${request.documentType}`
  );

  return { uri: url.toString(), method: "GET" } as ApiParameters;
};

const getDocumentMapping = (request: GetDocumentMappingRequest) => {
  var url = new URL(
    `${getBaseUrl()}/${apiSubPath}/DocumentMapping/${request.bigId}/${
      request.versionId
    }`
  );

  return { uri: url.toString(), method: "GET" } as ApiParameters;
};

const getTestDocumentMapping = (request: GetTestDocumentMappingRequest) => {
  var url = new URL(
    `${getBaseUrl()}/${apiSubPath}/testDocumentMapping/${request.bigId}/${
      request.configurationName
    }`
  );

  return { uri: url.toString(), method: "GET" } as ApiParameters;
};

const getPublishRecordHistoryAsync = (request: GetPublishRecordHistoryRequest) => {
  var url = new URL(
    `${getEndpoint(EndpointType.PcdLookupApi)}/${apiPcdLookupSubPath}/publishRecordHistory/${
      request.bigId
    }`
);
return { uri: url.toString(), method: "GET" } as ApiParameters;
};
const interfaces = {
  getPcdData,
  getPcdEntities,
  getSyncRecord,
  getDocumentMapping,
  getTestDocumentMapping,
  getPublishRecordHistoryAsync
};

export default interfaces;
