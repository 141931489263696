import React from 'react'
import { useState, useEffect, useMemo } from 'react';
import { useIsAuthenticated } from "@azure/msal-react";
import { useIsLoginEnforced } from "../../config/authConfig";
import { useBoolean } from '@fluentui/react-hooks';
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { Stack, Text, Link, FontWeights, IStackTokens, IStackStyles, ITextStyles } from '@fluentui/react';
import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import { ReplayEventItem, ReplayEventRequestBody } from '../models/replayEventItem';
import { useApi, ApiParameters } from '../hooks/useApi';
import devOpsApi, { ReplayEventRequest } from '../services/devOpsApiClient';
import { appInsights } from "../../config/telemetryConfig";
import { Environment, getEnvironment } from "../../config/environmentConfig";
import { StatusMessage, StatusMessageBar } from '../statusMessageBar';
import { Upload, Button, message, Table, Space, Popconfirm } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { LayoutStyles } from '../styles/layout';
import 'antd/dist/antd.min.css';
import * as XLSX from 'xlsx';

const boldStyle: Partial<ITextStyles> = {
    root: { fontWeight: FontWeights.semibold },
};
const stackTokens: IStackTokens = { childrenGap: 15 };
const stackStyles: Partial<IStackStyles> = {
    root: {
        margin: "0 auto",
        color: "#605e5c",
    },
};
const modalPropsStyles = { main: { maxWidth: 450 } };
const dialogContentProps = {
    type: DialogType.normal,
    title: 'Replay event',
    subText: 'Are you sure to batch replay events?',
};

export function ReplayEventView() {
    const [items, setItems] = useState<ReplayEventItem[]>([]);
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
    const [apiParameters, setApiParameters] = useState<ApiParameters[] | undefined>(undefined);
    const [requestBody, setRequestBody] = useState<ReplayEventRequestBody>({stateEntries: [], enableEventAutoResolveByType: false} as ReplayEventRequestBody);
    const [ready, response, isLoading, error, execute] = useApi<any>();
    const [statusMessage, setStatusMessage] = useState<StatusMessage | null>(null);
    const isAuthenticated = useIsAuthenticated() || !useIsLoginEnforced;
    const excelFile = isAuthenticated && require('../../assets/excel/ReplayEvent_SampleExcel.xlsx');
    const modalProps = useMemo(
        () => ({
            isBlocking: true,
            styles: modalPropsStyles,
            dragOptions: undefined,
        }),
        [],
    );
    const columns = [
        {
            title: 'ProductId',
            dataIndex: 'productId',
            key: 'productId',
            width: 580,
        },
        {
            title: "CorrelationVector",
            dataIndex: "correlationVector",
            key: 'correlationVector',
            width: 500,
        },
        {
            title: "Action",
            key: "action",
            width: 100,
            render: (text: any, record: any) => (
                <Space size="middle" align="center">
                    <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.key)}>
                        <Link>Delete</Link>
                    </Popconfirm>
                </Space>
            ),
        }
    ];

    const props = {
        name: 'file',
        maxCount: 1,
        beforeUpload: (file: any) => {
            const isExcel = file.type === "application/vnd.ms-excel" || file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
            if (!isExcel) {
                message.error(`${file.name} is not a excel file`);
                return Upload.LIST_IGNORE;
            }
            const fileReader = new FileReader();
            fileReader.onload = event => {
                try {
                    const workbook = XLSX.read(event.target?.result, { type: 'binary' });
                    const jsonArr = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]]);
                    let tempItems = new Array<ReplayEventItem>();
                    for (let i = 0; i < jsonArr.length; i++) {
                        const productId = JSON.parse(JSON.stringify(jsonArr[i])).ProductID;
                        const correlationVector = JSON.parse(JSON.stringify(jsonArr[i])).CorrelationVector;
                        const isRepublish = JSON.parse(JSON.stringify(jsonArr[i])).IsRepublish;
                        if (productId !== undefined && correlationVector !== undefined)
                            tempItems.push({ key: (i + 1).toString(), productId: productId, correlationVector: correlationVector, isRepublish: isRepublish});
                    }
                    if (tempItems.length > 0)
                        setItems(tempItems);
                } catch (e) {
                    message.error('Parse file error!');
                }
            }
            fileReader.readAsBinaryString(file);
            return false;
        },
        onRemove: (file: any) => {
            setItems([]);
        }
    };

    function handleDelete(key: string): void {
        const rows = items;
        setItems(rows.filter(item => item.key !== key));
    }

    function submitReplayEvent(): void {
        toggleHideDialog();
        let request = {
            body: {stateEntries: [], enableEventAutoResolveByType: false} as ReplayEventRequestBody,
        } as ReplayEventRequest;
        items.forEach(element => {
            if (element.productId !== "" && element.correlationVector !== "") {
                request.body.stateEntries.push({ productId: element.productId, correlationVector: element.correlationVector, isRepublish: element.isRepublish });
            }
        });
        if (request.body.stateEntries.length > 0) {
            setRequestBody(request.body);
            setApiParameters([devOpsApi.getReplayEvent(request)]);
        }
    }

    useEffect(() => {
        if (ready && apiParameters) {
            execute(apiParameters);
        }
    }, [apiParameters, ready, execute]);

    useEffect(() => {
        if (response) {
            const message = { type: "success", message: JSON.stringify(response) } as StatusMessage;
            setStatusMessage(message);

            appInsights.trackEvent({
                name: "BatchReplayEvent",
                properties: {
                    EventData: JSON.stringify(requestBody.stateEntries),
                    Environment: Environment[getEnvironment()],
                    Response: JSON.stringify(response),
                },
            });

            if (statusMessage) {
                setStatusMessage(null);
            }
        }
    }, [response]);

    useEffect(() => {
        if (error) {
            const errorMessage = { type: error.type, message: error.message } as StatusMessage;
            if (errorMessage.message !== "") {
                setStatusMessage(errorMessage);
            }
        }
    }, [error]);

    useEffect(() => {
        if (items.length === 0) {
            setStatusMessage(null);
        }
    }, [items]);

    return (
        <div style={LayoutStyles.Main}>
            <Stack horizontalAlign="start" verticalAlign="start" verticalFill styles={stackStyles} tokens={stackTokens}>
                <Text variant="xxLarge" styles={boldStyle}>
                    Batch replay events
                </Text>
               <Text variant="large">
                    Please <Link href={excelFile} underline>download excel template</Link> to input &quot;ProductId&quot; &amp; &quot;CorrelationVector&quot;,
                    import excel file to confirm,
                    and click submit to replay events:
                </Text>
                <Upload {...props} listType='picture' disabled={isLoading}>
                    <Button icon={<UploadOutlined />}>
                        Click to import Excel file
                    </Button>
                </Upload>
                <Table columns={columns} dataSource={items} />
                <Space size="middle">
                    <PrimaryButton text="Submit to replay" disabled={items.length === 0 || isLoading} onClick={toggleHideDialog} />
                    {isLoading && (<Spinner label="Replaying..." size={SpinnerSize.medium} labelPosition="left" />)}
                    {items.length > 0 &&
                        (<Text variant="medium">
                            Total Count: {items.length}
                        </Text>)
                    }
                    <Dialog
                        hidden={hideDialog}
                        onDismiss={toggleHideDialog}
                        dialogContentProps={dialogContentProps}
                        modalProps={modalProps}
                    >
                        <DialogFooter>
                            <PrimaryButton onClick={submitReplayEvent} text="Replay" />
                            <DefaultButton onClick={toggleHideDialog} text="Don't replay" />
                        </DialogFooter>
                    </Dialog>
                </Space>
                <StatusMessageBar message={statusMessage} isMultiline />
            </Stack>
        </div>
    );
}
