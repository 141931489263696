import * as React from 'react';
import { Stack, StackItem, DatePicker, IStackItemTokens, mergeStyleSets, defaultDatePickerStrings, DefaultButton } from '@fluentui/react';
import VF from './util/ValueFormatting';
import { StatusMessage, StatusMessageBar } from './statusMessageBar';

const styles = mergeStyleSets({
    root: { selectors: { '> *': { marginBottom: 15 } } },
    control: { maxWidth: 300, marginBottom: 15 },
});

const calendarWrapperTokens: IStackItemTokens = { padding: '4px 2px 2px' };
const buttonWrapperTokens: IStackItemTokens = { padding: '36px 2px 2px' };

export interface IDateRangePickerProps {
    onApplyDate?: (startDate: Date | undefined, endDate: Date | undefined) => void;
    onClearDate?: () => void;
}

export function DateRangePicker({ onApplyDate, onClearDate }: IDateRangePickerProps) {
    const [calendarStartDate, setCalenderStartDate] = React.useState<Date | undefined>();
    const [calendarEndDate, setCalenderEndDate] = React.useState<Date | undefined>();
    const [statusMessage, setStatusMessage] = React.useState<StatusMessage | null>(null);

    const isValidDateSelected = React.useMemo(() => {
        if (calendarStartDate && calendarEndDate) {
            let isValid = false;
            isValid = !(VF.yyyymmddDate(calendarStartDate) > VF.yyyymmddDate(calendarEndDate));
            if (!isValid) {
                setStatusMessage({
                    type: 'error',
                    message: "the StartDate is greater than EndDate",
                });
                return false;
            }
        }
        return true;
    }, [calendarStartDate, calendarEndDate]);

    React.useEffect(() => {
        if (isValidDateSelected && onApplyDate) {
            setStatusMessage(null);
            onApplyDate(calendarStartDate, calendarEndDate);
        } else {
            if (onClearDate) {
                onClearDate();
            }
        }
    }, [calendarStartDate, calendarEndDate]);

    return (
        <Stack wrap>
            <Stack horizontal wrap>
                <StackItem grow tokens={calendarWrapperTokens}>
                    <DatePicker
                        label="Start Date"
                        allowTextInput
                        ariaLabel="Select a date. Input format is day slash month slash year."
                        value={calendarStartDate}
                        formatDate={VF.onFormatDate}
                        onSelectDate={setCalenderStartDate as (date: Date | null | undefined) => void}
                        className={styles.control}
                        strings={defaultDatePickerStrings}
                    />
                </StackItem>
                <StackItem grow tokens={calendarWrapperTokens}>
                    <DatePicker
                        label="End Date"
                        allowTextInput
                        ariaLabel="Select a date. Input format is day slash month slash year."
                        value={calendarEndDate}
                        formatDate={VF.onFormatDate}
                        onSelectDate={setCalenderEndDate as (date: Date | null | undefined) => void}
                        className={styles.control}
                        strings={defaultDatePickerStrings}
                    />
                </StackItem>
                <StackItem grow tokens={buttonWrapperTokens}>
                    <DefaultButton
                        key={'clear-custom-date'}
                        text={'Clear date'}
                        iconProps={{
                            iconName: 'ClearFilter',
                        }}
                        onClick={
                            (/*ev: React.MouseEvent<HTMLButtonElement>*/) => {
                                if (calendarStartDate || calendarEndDate) {
                                    setCalenderStartDate(undefined);
                                    setCalenderEndDate(undefined);
                                    if (onClearDate)
                                        onClearDate();
                                }
                            }
                        }
                    />
                </StackItem>
            </Stack>
            <StatusMessageBar message={statusMessage} isMultiline />
        </Stack>
    );
}
