import React, { Suspense } from 'react';
import {
    Stack,
    Text,
    FontWeights,
    IStackTokens,
    IStackStyles,
    ITextStyles,
    IStackProps,
    TextField,
    Dropdown,
    IDropdownOption,
    IDropdownStyles,
    CommandButton,
    IIconProps,
    ComboBox,
    IComboBoxOption,
    IComboBoxStyles,
    IComboBox,
} from "@fluentui/react";
import { LayoutStyles } from '../styles/layout';
import { SearchListProps, DeadLetterListView, CommandListProps } from './deadLetter/deadLetterListView';
import { PrimaryButton } from "@fluentui/react/lib/Button";
import { DateRangePicker } from '../dateRangePicker';
import VF from '../util/ValueFormatting';

const boldStyle: Partial<ITextStyles> = {
    root: { fontWeight: FontWeights.semibold },
};
const stackTokens: IStackTokens = { childrenGap: 15 };
const stackStyles: Partial<IStackStyles> = {
    root: {
        margin: "0 auto",
        color: "#605e5c",
    },
};

const eventTypeOptions: IComboBoxOption[] = [
    { key: 'APIReceivedJsonEvent', text: 'APIReceivedJsonEvent' },
    { key: 'bigcat-contentartifacts-ingested', text: 'bigcat-contentartifacts-ingested' },
    { key: 'bigcat-pcd-conversion-completion', text: 'bigcat-pcd-conversion-completion' },
    { key: 'bigcat-pcd-validation-completion', text: 'bigcat-pcd-validation-completion' },
    { key: 'bigcat-subdocument-ingested', text: 'bigcat-subdocument-ingested' },
    { key: 'ConverterGeneratedPcdEvent', text: 'ConverterGeneratedPcdEvent' },
    { key: 'ConverterCompletionEvent', text: 'ConverterCompletionEvent' },
    { key: 'ConverterUpdateStateEvent', text: 'ConverterUpdateStateEvent' },
    { key: 'ConverterRateCardGeneratedEvent', text: 'ConverterRateCardGeneratedEvent' },
    { key: 'ContentArtifactIngestionCompletionEvent', text: 'ContentArtifactIngestionCompletionEvent' },
    { key: 'DocumentValidationCompletionEvent', text: 'DocumentValidationCompletionEvent' },
    { key: 'DailyManifestCreationEvent', text: 'DailyManifestCreationEvent' },
    { key: 'OrchestrationTestEvent', text: 'OrchestrationTestEvent' },
    { key: 'ProductIngestionCompltionEvent', text: 'ProductIngestionCompltionEvent' },
    { key: 'PcdConverterServiceRequestCompleteEvent', text: 'PcdConverterServiceRequestCompleteEvent' },
    { key: 'PayloadReplicationEvent', text: 'PayloadReplicationEvent' },
    { key: 'PayloadPackageReceivedEvent', text: 'PayloadPackageReceivedEvent' },
    { key: 'ProductArtifactIngestionCompletionEvent', text: 'ProductArtifactIngestionCompletionEvent' },
    { key: 'RepublishEvent', text: 'RepublishEvent' },
    { key: 'RateCardConverterServiceRequestCompleteEvent', text: 'RateCardConverterServiceRequestCompleteEvent' },
    { key: 'ratingfeed-conversion-completion', text: 'ratingfeed-conversion-completion' },
    { key: 'rating-feed-daily-manifest', text: 'rating-feed-daily-manifest' },
    { key: 'RatingFeedConverterServiceRequestCompleteEvent', text: 'RatingFeedConverterServiceRequestCompleteEvent' },
    { key: 'StagingCatProductDocumentGenerationEvent', text: 'StagingCatProductDocumentGenerationEvent' },
    { key: 'SessionMonitoringEvent', text: 'SessionMonitoringEvent' },
    { key: 'SyncServicePushedEvent', text: 'SyncServicePushedEvent' },
  ];

const tagOptions: IDropdownOption[] = [
    { key: '-1', text: 'All' },
    { key: '1', text: 'Has Tag' },
    { key: '0', text: 'No Tag' },
  ];

const revisionIdProps: Partial<IStackProps> = {
    styles: { root: { width: 180 } },
};

const productIdProps: Partial<IStackProps> = {
    styles: { root: { width: 500 } },
};

const eventTypeComboBoxStyles: Partial<IComboBoxStyles> = {
    root: { width: 380 },
};

const tagTypeDropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: 180 },
};

const addIcon: IIconProps = { iconName: 'Add' };
const deleteIcon: IIconProps = { iconName: 'Delete' };

interface IDeadLetterViewState {
    productId: string
    startTime: string
    endTime: string
    revisionId: string
    eventType: string
    enableSearch: number
    tagType: number
    pageSize: number
    pageIndex: number
    skipCount: number
    command: string
    enableCommand: number
}

export interface IDeadLetterViewProps {

}

export class DeadLetterView extends React.Component<
    IDeadLetterViewProps,
    IDeadLetterViewState
> {
    constructor(props: IDeadLetterViewProps) {
        super(props);

        this.state = {
            productId: "",
            startTime: "",
            endTime: "",
            revisionId: "",
            eventType: "",
            enableSearch: 0,
            tagType: -1,
            pageSize: 10,
            pageIndex: 0,
            skipCount: 0,
            command: "",
            enableCommand: 0,
        };

        this.handleClick = this.handleClick.bind(this);
        this.handleAddTagClick = this.handleAddTagClick.bind(this);
        this.handleRemoveTagClick = this.handleRemoveTagClick.bind(this);
    }
    
    render() {
        return (        
            <div style={LayoutStyles.Main}>
                <Stack
                    horizontalAlign="start"
                    verticalAlign="start"
                    verticalFill
                    styles={stackStyles}
                    tokens={stackTokens}
                >
                    <Text variant="xxLarge" styles={boldStyle}>
                        Find details of dead letter event.
                    </Text>
                    <Text variant="large">
                        For a guide on how to find the dead letter, please go to TSG link.{" "}
                    </Text>
                    <Stack horizontal wrap tokens={stackTokens}>
                        <ComboBox
                            placeholder="Please select/input EventType"
                            label="Event Type"
                            allowFreeform={true}
                            autoComplete={'on'}
                            options={eventTypeOptions}
                            styles={eventTypeComboBoxStyles}
                            text = {this.state.eventType}
                            onChange={(event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
                                this.setState({ eventType: option == undefined ? value || "" : option.key as string });
                            }}
                        />
                    <Stack {...productIdProps}>
                        <TextField
                            label="Product Id"
                            placeholder="Please input ProductId"
                            onChange={(e, val) => {
                                this.setState({ productId: val || "" });
                            }}
                        ></TextField>
                    </Stack>
                    <Stack {...revisionIdProps}>
                        <TextField
                            label="Revision Id"
                            placeholder="Please input RevisionId"
                            onChange={(e, val) => {
                                this.setState({ revisionId: val || "" });
                            }}
                        ></TextField>
                    </Stack>                        
                        <Dropdown
                            placeholder="Please select Tag type"
                            label="Tag Type"
                            options={tagOptions}
                            styles={tagTypeDropdownStyles}
                            onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
                                this.setState({ tagType: option?.key as number });
                            }}
                        />
                    </Stack>
                    <Stack horizontal wrap>
                        <Suspense fallback={null}>
                            <DateRangePicker
                                onClearDate={() => {
                                    this.setState({ startTime: "" });
                                    this.setState({ endTime: "" });
                                }}
                                onApplyDate={(
                                    startDate: Date | undefined,
                                    endDate: Date | undefined
                                ) => {
                                    this.setState({ startTime: startDate === undefined ? "" : VF.onFormatDate(startDate) });
                                    this.setState({ endTime: endDate === undefined ? "" : VF.onFormatDate(endDate) });
                                }}
                            />
                        </Suspense>
                    </Stack>
                    <PrimaryButton text="Search" onClick={this.handleClick} />
                    <DeadLetterListView
                    searchList={{
                        enabled: this.state.enableSearch,
                        productId: this.state.productId,
                        startDate: this.state.startTime,
                        endDate: this.state.endTime,
                        revisionId: this.state.revisionId,
                        eventType: this.state.eventType,
                        tagType: this.state.tagType,
                        pageSize: this.state.pageSize,
                        pageIndex: this.state.pageIndex,
                        skipCount: this.state.skipCount,
                    } as SearchListProps}
                    commandList = {{command: this.state.command, enabled: this.state.enableCommand} as CommandListProps} />
                    <Stack horizontal wrap>
                        <CommandButton iconProps={addIcon} text="Add Tag" onClick={this.handleAddTagClick} />
                        <CommandButton iconProps={deleteIcon} text="Remove Tag" onClick={this.handleRemoveTagClick} />
                    </Stack>
            </Stack>
        </div>
    );
}

    handleClick(): void {
        console.log(`Searching.. productid=${this.state.productId.trim()}, StartTime=${this.state.startTime.trim()}, EndTime=${this.state.endTime.trim()}, revisionId=${this.state.revisionId.trim()}, eventType=${this.state.eventType.trim()}, tagType=${this.state.tagType.toString()}, pageIndex=${this.state.pageIndex.toString()}, pageSize=${this.state.pageSize.toString()}, skipCount=${this.state.skipCount.toString()}`);
        this.setState(() => ({
            productId: this.state.productId.trim(),
            startTime: this.state.startTime.trim(),
            endTime: this.state.endTime.trim(),
            revisionId: this.state.revisionId.trim(),
            eventType: this.state.eventType.trim(),
            tagType: this.state.tagType,
            enableSearch: this.state.enableSearch + 1,
            pageIndex: this.state.pageIndex,
            pageSize: this.state.pageSize,
            skipCount: this.state.skipCount,
        }));
    }

    handleAddTagClick(): void {
        this.setState(() => ({
            command: "addTag",
            enableCommand: this.state.enableCommand + 1,
        }));
    }

    handleRemoveTagClick(): void {
        this.setState(() => ({
            command: "removeTag",
            enableCommand: this.state.enableCommand + 1,
        }));
    }
}
