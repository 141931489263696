export class TrieNode {
  Dimension: any;
  DimensionHashtable: Map<string, TrieNode>;
  IsLeaf: boolean;
  LeafSubdocument: string;
  NullNode: TrieNode | null;

  constructor() {
    this.Dimension = null;
    this.DimensionHashtable = new Map<string, TrieNode>();
    this.IsLeaf = false;
    this.LeafSubdocument = "";
    this.NullNode = null;
  }

  // add a value to this trie
  // this function is used to build a trie
  public AddValue(subDocument: string, dimensionTypeList: Array<string>, index: number, permutationCount: number): void
  {
    const subDocumentJObject = JSON.parse(subDocument);
    const subDocumentDimensionCount = (subDocumentJObject.Key.$values as Array<any>).length;
    if ((subDocumentDimensionCount === permutationCount) || (index === dimensionTypeList.length))
    {
      this.AddSubdocument(subDocument);
      return;
    }
    let dimension = this.GetDimensionOfSpecificType(subDocumentJObject.Key.$values as Array<any>, dimensionTypeList[index]);
    this.GetOrAddNode(dimension).AddValue(subDocument, dimensionTypeList, index + 1, permutationCount + (dimension == null ? 0 : 1));
  }

  // input a permutation and get most matched subdocument from this trie
  // 'most matched' here means the subdocument that has more dimensions,
  // which means it is more accurate
  public GetValue(modelDimensions: Array<any>, dimensionTypeList: Array<string>, index: number): string
  {
    if (index >= dimensionTypeList.length)
    {
      return this.IsLeaf ? this.LeafSubdocument : "";
    }
    let dimension = this.GetDimensionOfSpecificType(modelDimensions, dimensionTypeList[index]);
    let nodeArray = this.GetNodes(dimension);
    let subDocuments = new Array<string>();
    nodeArray.forEach(p => {
      let subDocument = p.GetValue(modelDimensions, dimensionTypeList, index + 1);
      if (subDocument !== "")
      {
        subDocuments.push(subDocument);
      }
    });
    if (this.IsLeaf)
    {
      subDocuments.push(this.LeafSubdocument);
    }
    if (subDocuments.length == 0)
    {
      return "";
    }
    let maxCount = 0;
    subDocuments.forEach(p => {
      let dimensionArray = JSON.parse(p).Key.$values as Array<any>;
      if (dimensionArray.length > maxCount)
      {
        maxCount = dimensionArray.length;
      }
    });
    let subDocument = "";
    subDocuments.forEach(p => {
      let dimensionArray = JSON.parse(p).Key.$values as Array<any>;
      if (subDocument === "" && dimensionArray.length === maxCount)
      {
        subDocument = p;
      }
    });
    return subDocument;
  }

  // add subdocument to this Node
  private AddSubdocument(subDocument: string): void
  {
    this.IsLeaf = true;
    this.LeafSubdocument = subDocument;
  }

  private GetDimensionOfSpecificType(dimensions: Array<any>, dimensionType: string): any
  {
    let dimension: any = null;
    dimensions.forEach(p => {
      let type = JSON.parse(JSON.stringify(p)).$type as string;
      let startIndex = type.indexOf(".");
      let lastIndex = type.indexOf(",");
      type = type.substring(startIndex + 1, lastIndex);
      if (dimension === null && type === dimensionType)
      {
        dimension = p;
      }
    });
    return dimension;
  }

  // get matched child nodes
  // this.NullNode always match, if it's not null
  // if there's a matched key in this.DimensionHashtable, the related TrieNode is also a matched node.
  private GetNodes(dimension: any): Array<TrieNode>
  {
    var result = new Array<TrieNode>();
    if (this.NullNode !== null)
    {
      result.push(this.NullNode);
    }
    if (dimension === null)
    {
      return result;
    }
    let key = JSON.stringify(dimension);
    if (this.DimensionHashtable.has(key))
    {
      result.splice(0, 0, this.DimensionHashtable.get(key) as TrieNode);
    }
    return result;
  }

  // Get TrieNode for a certain dimension. Create a new TrieNode if it does not exist
  private GetOrAddNode(dimension: any): TrieNode
  {
    if (dimension === null)
    {
      if (this.NullNode === null)
      {
        this.NullNode = new TrieNode();
        this.NullNode.Dimension = dimension;
      }
      return this.NullNode;
    }
    let key = JSON.stringify(dimension);
    if (!this.DimensionHashtable.has(key))
    {
      let trieNode = new TrieNode();
      trieNode.Dimension = dimension;
      this.DimensionHashtable.set(key, trieNode);
    }
    return this.DimensionHashtable.get(key) as TrieNode;
  }
}
