import {  ILabelStyles, IStackStyles, IStackTokens, IStyleSet, Label, Spinner, Stack, Text } from '@fluentui/react';
import React, { useState } from 'react';
import { useApi } from '../hooks/useApi';
import { DocmentType, ProductDocument } from '../models/productDocument';
import externaleApi, {  QueryDocAsyncRequest } from '../services/externalApiClient';
import { SubDocButton } from './subDocDownLoadButton';
import { StatusMessage, StatusMessageBar } from '../statusMessageBar';
import { SubDocumentDimensionType } from './subDocumentDimensionTypeView';

const stackTokens: IStackTokens = { childrenGap: 15 };
const stackStyles: Partial<IStackStyles> = {
    root: {
        margin: '0 auto',
        color: '#605e5c',
    },
};
const labelStyles:ILabelStyles = {
    root: { marginTop: 3 },
};
const labelTagStyles: ILabelStyles ={
    root:{
        margin:3,
        padding:5,
        fontSize:12,
        lineHeight:15,
        display:"inline-block",
        border:'0.5px solid #ccc',
        borderRadius:4,
        color:'#55afff',
        textAlign:'center',      
    }
}
export interface SubDocProps {
    documentId: string,
    sessionId: string,
}
export function SubDocView(props: SubDocProps) {
    const [request, updateRequest] = useState<QueryDocAsyncRequest>({} as QueryDocAsyncRequest);
    const [productDocument, updateProductDoc] = React.useState<ProductDocument>();
    const [ready, response, isLoading, error, execute] = useApi<any>();
    const [statusMessage, setStatusMessage] = useState<StatusMessage | null>(null);



    React.useEffect(() => {
        const request = {
            productId: props.documentId,
            revisionId: props.sessionId,
            queryOptions: {
                PageSize: 1000,
                DocumentTypes: [DocmentType.product],
              }
        } as QueryDocAsyncRequest;
        updateRequest(request);
    }, [props]);

    React.useEffect(() => {
        if (ready) {
            execute([externaleApi.queryDocumentsAsync(request)]);
        }
    }, [request, ready]);
    React.useEffect(() => {
        if (response) {
            let res = response["Items"]["$values"] as any[];
            updateProductDoc(res[0] as ProductDocument);
            if (statusMessage) {
                setStatusMessage(null);
            }
        }
    },
        [response]);

    React.useEffect(() => {
        if (error) {
            setStatusMessage(error);
        }
    }, [error]);
    const [item, updateItems] = React.useState<ProductDocument>(productDocument as ProductDocument);

    React.useEffect(() => {
        updateItems(productDocument as ProductDocument);
    }, [productDocument]);
    return (
        <div>
            <Stack horizontalAlign="start" verticalAlign="start" verticalFill styles={stackStyles} tokens={stackTokens}>
            <StatusMessageBar message={statusMessage} isMultiline />
            <Label styles={labelStyles}>ProductDocument Details </Label>
            <Text>Id: {item?.id}</Text>
            <Text>DocumentId: {item?.DocumentId}</Text>
            <Text>RevisionId: {item?.RevisionId}</Text>
            <Text>ProductType: <Label styles={labelTagStyles}>{item?.ProductType}</Label></Text>
            {
                item !== undefined ? ( <SubDocumentDimensionType documentId={item.DocumentId} sessionId={item.RevisionId} dimensionTypes={item.DimensionTypes.$values}></SubDocumentDimensionType>) : <Spinner label="Loading..." />
            }
            <Text>DocumentTypes: {item?.DocumentTypes.$values.map((item, index) => { return <Label styles={labelTagStyles} key={index} disabled>{item}</Label> })}</Text>
            </Stack>
            {
                item !== undefined ? (<SubDocButton documentId={item.DocumentId} sessionId={item.RevisionId} />) : ''
            }
            {item !== undefined || (isLoading && <Spinner label="Loading..." />)}
            </div>

    );
}