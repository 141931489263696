import { Spinner, Stack, StackItem, IIconProps, IStyleSet, CommandBarButton } from '@fluentui/react';
import { DetailsList, DetailsListLayoutMode, IColumn, Selection, SelectionMode, IDetailsListStyles } from '@fluentui/react/lib/DetailsList';
import { useBoolean } from '@fluentui/react-hooks';
import React, { useState } from 'react';
import { ApiParameters, useApi } from '../../hooks/useApi';
import { useMsal } from "@azure/msal-react";
import devOpsApi, { DeleteSuppressionRuleEvent } from '../../services/devOpsApiClient';
import { DeleteSuppressionRuleStateRequest, SuppresionRuleStateScope, SuppressionRuleState, SuppressionRuleStateFeedType } from '../../models/suppressionRule';
import { StatusMessage, StatusMessageBar } from '../../statusMessageBar';
import { SuppressionRuleStateView } from './suppressionRuleView';
import { AddOrEditSuppressionRuleView } from './addOrEditSuppressionRuleView';
import { ModalWrapper } from '../../modalWrapper';
import { appInsights } from '../../../config/telemetryConfig';
import { Environment, getEnvironment } from '../../../config/environmentConfig';

const addIcon: IIconProps = { iconName: 'Add' };
const removeIcon: IIconProps = { iconName: 'Remove' };
const editIcon: IIconProps = { iconName: 'Edit' };

const disableStyle: Partial<IStyleSet<IDetailsListStyles>> = {
    root: {
        opacity: 0.4
    },
};

const enableStyle: Partial<IStyleSet<IDetailsListStyles>> = {
    root: {
        opacity: 1
    },
};

export interface SuppressionRulesListViewProps {
    scope?: SuppresionRuleStateScope,
    feedType: SuppressionRuleStateFeedType,
}

export function SuppressionRulesListView(props: SuppressionRulesListViewProps) {
    const { accounts } = useMsal();
    const loggedInUserAlias = accounts[0] && accounts[0].username.replace('@pme.gbl.msidentity.com', '');

    const [position, setPosition] = React.useState("top");

    const { feedType, scope } = props;

    const [rules, updateRules] = React.useState<SuppressionRuleState[]>([]);
    const [statusMessage, setStatusMessage] = useState<StatusMessage | null>(null);
    const [items, updateItems] = React.useState<SuppressionRuleState[]>(rules);

    const [ready, response, isLoading, error, execute] = useApi<SuppressionRuleState[]>();

    const [deleteApiParameters, setDeleteApiParameters] = useState<ApiParameters[] | undefined>(undefined);
    const [readyDelete, responseDelete, isLoadingDelete, errorDelete, executeDelete] = useApi<any>();

    const [isViewModalOpen, { setTrue: showViewModal, setFalse: hideViewModal }] = useBoolean(false);
    const [isAddModalOpen, { setTrue: showAddModal, setFalse: hideAddModal }] = useBoolean(false);
    const [isEditModalOpen, { setTrue: showEditModal, setFalse: hideEditModal }] = useBoolean(false);

    const selection = new Selection({
        onSelectionChanged: () => {
            setSelectSuppressionRule(_getSelectedSuppressionRule());
        },
    });
    const [selectedSuppressionRule, setSelectSuppressionRule] = React.useState<SuppressionRuleState | undefined>(_getSelectedSuppressionRule());

    React.useEffect(() => {
        if (ready) {
            execute([devOpsApi.getSuppressionRules(feedType, scope)]);
        }
    }, [ready]);

    React.useEffect(() => {
        if (response) {
            updateRules(response as SuppressionRuleState[]);
            if (statusMessage) {
                setStatusMessage(null);
            }
        }
    }, [response]);

    React.useEffect(() => {
        if (error) {
            setStatusMessage(error);
            updateItems([]);
        }
    }, [error]);

    React.useEffect(() => {
        if (readyDelete && deleteApiParameters) {
            executeDelete(deleteApiParameters);
        }
    }, [readyDelete, deleteApiParameters]);

    React.useEffect(() => {
        if (responseDelete) {

            appInsights.trackEvent({
                name: "DeleteSuppressionRuleState",
                properties: {
                    EventData: JSON.stringify(deleteApiParameters),
                    Environment: Environment[getEnvironment()],
                    Response: JSON.stringify(response),
                },
            });

            updateRules(rules.filter(rule => rule !== selectedSuppressionRule));
            if (statusMessage) {
                setStatusMessage(null);
            }
            setSelectSuppressionRule(undefined);
        }   
    }, [responseDelete]);

    React.useEffect(() => {
        if (errorDelete) {
            const message = { type: errorDelete.type, message: errorDelete.message } as StatusMessage;
            setStatusMessage(message);
        }
    }, [errorDelete]);

    React.useEffect(() => {
        updateItems(rules);
    }, [rules]);

    return (
        <Stack>
            <StatusMessageBar message={statusMessage} isMultiline />
            <StackItem>
                <CommandBarButton
                    iconProps={addIcon}
                    text="New rule"
                    onClick={_onAddBtnClickHandler}
                />
                <CommandBarButton
                    iconProps={editIcon}
                    text="Edit rule"
                    onClick={_onEditBtnClickHandler}
                />
                <CommandBarButton
                    iconProps={removeIcon}
                    text="Delete rule"
                    onClick={_onDeleteBtnClickHandler}
                />
            </StackItem>

            {/* List of suppression rule states */}
            <StackItem>
                {position === "top" && isLoading && (<Spinner label="Loading..." />)}
                {rules.length !== 0 && <DetailsList styles={isLoading ? disableStyle : enableStyle}
                    items={items}
                    compact={false}
                    columns={_getColumns()}
                    selectionMode={isLoading ? SelectionMode.none : SelectionMode.single}
                    getKey={_getKey}
                    setKey="multiple"
                    layoutMode={DetailsListLayoutMode.justified}
                    isHeaderVisible={true}
                    selection={selection}
                    selectionPreservedOnEmptyClick={true}
                    onItemInvoked={_onItemInvoked}
                    onRenderItemColumn={_onRenderItemColumn}
                    enterModalSelectionOnTouch={true}
                    ariaLabelForSelectionColumn="Toggle selection"
                    ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                    checkButtonAriaLabel="select row"
                />}
            </StackItem>

            {/* View suppression rule state popup */}
            {isViewModalOpen && selectedSuppressionRule &&
                <ModalWrapper {...{
                    modalHeading: "SuppresionRuleState",
                    component: <SuppressionRuleStateView suppressionRuleState={selectedSuppressionRule} />,
                    onModalDismissHandler: hideViewModal
                }} />}

            {/* Add suppresion rule state popup */}
            {isAddModalOpen &&
                <ModalWrapper {...{
                    modalHeading: "Add SuppressionRuleState",
                component: <AddOrEditSuppressionRuleView feedType={feedType} scope={scope} onAddOrEditCancel={hideAddModal} onAddOrEditSuccess={_onAddRuleSuccessHandler} />,
                    onModalDismissHandler: hideAddModal
                }} />}

            {/* Edit suppresion rule state popup */}
            {isEditModalOpen && selectedSuppressionRule &&
                <ModalWrapper {...{
                    modalHeading: "Edit SuppressionRuleState",
                component: <AddOrEditSuppressionRuleView feedType={feedType} scope={scope} suppressionRuleState={selectedSuppressionRule} onAddOrEditCancel={hideEditModal} onAddOrEditSuccess={_onEditRuleSuccessHandler} />,
                    onModalDismissHandler: hideEditModal
                }} />}
        </Stack>
    );

    function _getKey(item: SuppressionRuleState, index?: number): string {
        return `${item.documentType}-${item.validationRuleName}`;
    }

    function _onItemInvoked(item: SuppressionRuleState): void {
        setSelectSuppressionRule(item);
        showViewModal();
    }

    function _onRenderItemColumn(item?: any, index?: number | undefined, column?: IColumn | undefined) {
        if (!item || !column) {
            return;
        }

        const fieldContent = item[column.fieldName as keyof SuppressionRuleState] as string;

        switch (column.key) {
            case 'suppressedDocumentIdsColumn':
                return <span>{item.suppressedDocumentIds.join(',')}</span>;
            default:
                return <span>{fieldContent}</span>;
        }
    }

    function _getSelectedSuppressionRule(): SuppressionRuleState | undefined {
        var selectedCount = selection.getSelectedCount();
        if (selectedCount >= 1) {
            return selection.getSelection()[0] as SuppressionRuleState;
        }
        return undefined;
    }

    function _onAddBtnClickHandler() {
        showAddModal();
    }

    function _onEditBtnClickHandler(): void {
        if (!selectedSuppressionRule) {
            alert("Please select a suppression rule to edit.")
            return;
        }

        showEditModal();
    }

    function _onDeleteBtnClickHandler(): void {
        if (!selectedSuppressionRule) {
            alert("Please select a suppression rule to delete.")
            return;
        }

        var userConfirmation = window.confirm("Do you want to delete a rule?");
        if (!userConfirmation) {
            return;
        }

        let deleteRequest: DeleteSuppressionRuleStateRequest = {
            deletedByAlias: loggedInUserAlias,
            comments: "Deleting suppression rule from OfferStore portal",
            documentType: selectedSuppressionRule ? selectedSuppressionRule.documentType : "",
            validationRuleName: selectedSuppressionRule ? selectedSuppressionRule.validationRuleName : ""
        }

        let deleteRequestEvent = {
            body: deleteRequest,
        } as DeleteSuppressionRuleEvent;

        setDeleteApiParameters([devOpsApi.getDeleteSuppressionRule(feedType, deleteRequestEvent, scope)]);
    }

    // Update the existing suppression rule states if the rule already exists,
    // else add the new rule to the list
    function _onAddRuleSuccessHandler(rule: SuppressionRuleState): void {
        // remove the existing rule if any and add new rule to the list
        var filteredRules = rules.filter(sr => sr.documentType !== rule.documentType && sr.validationRuleName !== rule.validationRuleName);
        if (filteredRules.length == rules.length - 1) {
            filteredRules.push(rule);
            updateRules(filteredRules);

            // this is to update the selected rule in the list
            setSelectSuppressionRule(rule);
        }
        else {
            rules.push(rule);    
        }
        hideAddModal();
    }

    function _onEditRuleSuccessHandler(rule: SuppressionRuleState): void {
        // remove the existing rule from the list and
        // add new rule to the list
        var filteredRules = rules.filter(sr => sr.documentType !== rule.documentType && sr.validationRuleName !== rule.validationRuleName);
        filteredRules.push(rule);

        // this is to update the selected rule in the list
        setSelectSuppressionRule(rule);

        // update the list
        updateRules(filteredRules);
        hideEditModal();

    }

    function _getColumns(): IColumn[] {
        return [
            {
                key: 'documentTypeColumn',
                name: 'DocumentType',
                fieldName: 'documentType',
                minWidth: 120,
                isResizable: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'validationRuleNameColumn',
                name: 'ValidationRuleName',
                fieldName: 'validationRuleName',
                minWidth: 240,
                isResizable: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'suppressedDocumentIdsColumn',
                name: 'SuppressedDocumentIds',
                fieldName: 'suppressedDocumentIds',
                minWidth: 200,
                isResizable: true,
                data: 'string',
                isPadded: true,
            }
        ];
    }
}