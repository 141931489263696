import { IStackStyles, Spinner, Stack, IStackTokens, Link } from '@fluentui/react';
import { mergeStyles } from '@fluentui/react/lib/Styling';
import React, { useState, useEffect } from 'react';
import { useApi, ApiParameters } from '../hooks/useApi';
import { OrchestrationEvent } from '../models/orchestrationEvent';
import devOpsApi, { GetManifastEventsRequest } from '../services/devOpsApiClient';
import { StatusMessage, StatusMessageBar } from '../statusMessageBar';
import { SwatchColorPicker } from '@fluentui/react/lib/SwatchColorPicker';
import { Environment, getEnvironment } from '../../config/environmentConfig';
import { MainifestSession } from '../models/session';

const stackStyles: IStackStyles = {
    root: {
        width: 200,
        height: 575,
    },
};
const stackLineStyles: IStackStyles = {
    root: {
        width: 100,
        height: 575,
    },
};
const headingStackTokens: IStackTokens = { childrenGap: 20 };
const roundSpacingStackTokens: IStackTokens = {
    childrenGap: '20%',
    padding: 'm 20px',
};
const lineSpacingStackTokens: IStackTokens = {
    childrenGap: '0%',
};
const itemAlignmentsStackStyles: IStackStyles = {
    root: {
      height: 60,
      float: 'right',
    },
  };
const itemAlignmentsStackTokens: IStackTokens = {
    childrenGap: 5,
    padding: 10,
};
const itemStyles: React.CSSProperties = {
    alignItems: 'center',
    display: 'flex',
    height: 190,
    justifyContent: 'center',
    width: 100,
};
const greenRoundClass = mergeStyles({
    width: 160,
    height: 160,
    backgroundColor: "green",
    borderRadius: 80,
    textAlign: "center",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: "white",
    fontSize: '14px',
    padding: 5,
});
const yellowRoundClass = mergeStyles({
    width: 160,
    height: 160,
    backgroundColor: "yellow",
    borderRadius: 80,
    textAlign: "center",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: "black",
    fontSize: '14px',
    padding: 5,
});
const redRoundClass = mergeStyles({
    width: 160,
    height: 160,
    backgroundColor: "red",
    borderRadius: 80,
    textAlign: "center",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: "white",
    fontSize: '14px',
    padding: 5,
});
const grayRoundClass = mergeStyles({
    width: 160,
    height: 160,
    backgroundColor: "gray",
    borderRadius: 80,
    textAlign: "center",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: "white",
    fontSize: '14px',
    padding: 5,
});
const legendClass = mergeStyles({
    fontSize: 14,
    fontWeight: 400,
});
const colorCellsExample = [
    { id: 'a', label: 'Not Start', color: 'gray' },
    { id: 'b', label: 'Started', color: 'yellow' },
    { id: 'c', label: 'Success', color: 'green' },
    { id: 'd', label: 'Failed', color: 'red' },
];

export interface OverViewProps {
    sessionId: string,
    session: MainifestSession,
}

export function ManifestSessionOverView(props: OverViewProps) {
    const [events, updateEvents] = React.useState<OrchestrationEvent[]>([]);
    const [apiParameters, updateApiParameters] = useState<ApiParameters[]>([]);
    const [ready, response, isLoading, error, execute] = useApi<any>();
    const [statusMessage, setStatusMessage] = useState<StatusMessage | null>(null);
    const sessionMonitoringEvent = "sessionmonitoringevent";
    const [eventsClass, setEventsClass] = React.useState<string[]>([]);
    const [costTime, setCostTime] = React.useState<string[]>([]);
    const [intervalTime, setIntervalTime] = React.useState<string[]>([]);

    useEffect(() => {
        const request = {
            revisionId: props.sessionId,
        } as GetManifastEventsRequest;

        updateApiParameters([devOpsApi.getManifastEvents(request)]);

    }, [props]);

    useEffect(() => {
        if (ready) {
            execute(apiParameters);
        }
    }, [apiParameters, ready]);

    useEffect(() => {
        if (response) {
            const documents = response.TrackingEvents as OrchestrationEvent[];
            if (documents.length === 0) {
                return;
            }
            const sortDocuments = documents.slice().sort((pre, after) => new Date(pre.eventTime.toString()).getTime() - new Date(after.eventTime.toString()).getTime());
            updateEvents(sortDocuments);

            if (statusMessage) {
                setStatusMessage(null);
            }
        }
    }, [response]);

    useEffect(() => {
        if (error) {
            setStatusMessage(error);
        }
    }, [error]);

    useEffect(() => {
        if (events.length > 0) {
            updateEventsInfo();
        }
    }, [events]);

    function getDateDiff(startDate: Date, endDate: Date, nowDate: Date)
    {
        var sd = Date.parse(startDate.toString());
        var ed = Date.parse(endDate.toString());
        var now = Date.parse(nowDate.toString());
        if (sd === now || ed === now)
        {
            return "n/a";
        }
        var drr = ed - sd;
        var day = parseInt((drr / (24 * 60 * 60 * 1000)).toString());
        var hours = parseInt((drr % (24 * 60 * 60 * 1000) / (60 * 60 * 1000)).toString());
        var minutes = parseInt((drr % (60 * 60 * 1000) / (60 * 1000)).toString());
        var seconds = parseInt((drr % (60 * 1000) / 1000).toString());
        var result = (day === 0 ? "" : day + "d") + (hours === 0 ? "" : hours + "h") + (minutes === 0 ? "" : minutes + "m") + (seconds === 0 ? "" : seconds + "s");
        return result === "" ? "0s" : result;
    }

    function updateEventsInfo()
    {
        let classArray: string[] = [grayRoundClass, grayRoundClass, grayRoundClass, grayRoundClass, grayRoundClass, grayRoundClass, grayRoundClass, grayRoundClass, grayRoundClass];
        let timeArray: string[] = ["", "", "", "", "", "", "", "", ""];
        let intervalArray: string[] = ["", "", "", "", "", "", "", ""];
        const sessionMonitoringEvents = events.filter(e => e.eventType.toLocaleLowerCase() === sessionMonitoringEvent);
        let lldSyncServiceCount = 0;
        const now = new Date();
        let ingestionApiStartTime: Date = now;
        let ingestionApiEndTime: Date = now;
        let ingestionApiRecentEndTime: Date = now;
        let lldConverterServiceStartTime: Date = now;
        let lldConverterServiceEndTime: Date = now;
        let lldConverterServiceRecentEndTime: Date = now;
        let lldSyncServiceStartTime: Date = now;
        let lldCardSyncServiceEndTime: Date = now;
        let lldSyncServiceRecentEndTime: Date = now;
        sessionMonitoringEvents.forEach(element => {
            let eventName = JSON.parse(JSON.stringify(element.data)).EventName.toString();
            let subState = JSON.parse(JSON.stringify(element.data)).SubState.toString();
            switch (eventName) {
                case "IngestionApiNewRequestEvent":
                    if (subState === "Failed")
                        classArray[0] = redRoundClass;
                    else if (subState === "Success" && classArray[0] !== redRoundClass && classArray[0] !== greenRoundClass)
                        classArray[0] = yellowRoundClass;
                    if (Date.parse(ingestionApiStartTime.toString()) === Date.parse(now.toString()))
                        ingestionApiStartTime = element.eventTime;
                    break;
                case "IngestionApiRequestCompleteEvent":
                    if (subState === "Failed" && classArray[0] !== greenRoundClass)
                        classArray[0] = redRoundClass;
                    if (subState === "Success")
                        classArray[0] = greenRoundClass;
                    if (Date.parse(ingestionApiEndTime.toString()) === Date.parse(now.toString()))
                        ingestionApiEndTime = element.eventTime;
                    ingestionApiRecentEndTime = element.eventTime;
                    break;
                case "LinkedDocumentConverterServiceNewRequestEvent":
                    if (subState === "Failed")
                        classArray[4] = redRoundClass;
                    else if (subState === "Success" && classArray[4] !== redRoundClass && classArray[4] !== greenRoundClass)
                        classArray[4] = yellowRoundClass;
                    if (Date.parse(lldConverterServiceStartTime.toString()) === Date.parse(now.toString()))
                        lldConverterServiceStartTime = element.eventTime;
                    break;
                case "LinkedDocumentConverterServiceRequestCompleteEvent":
                    if (subState === "Failed" && classArray[4] !== greenRoundClass)
                        classArray[4] = redRoundClass;
                    if (subState === "Success" || subState === "Skipped")
                        classArray[4] = greenRoundClass;
                    if (Date.parse(lldConverterServiceEndTime.toString()) === Date.parse(now.toString()))
                        lldConverterServiceEndTime = element.eventTime;
                    lldConverterServiceRecentEndTime = element.eventTime;
                    break;
                case "LinkedDocumentSyncServiceNewRequestEvent":
                    if (subState === "Failed")
                        classArray[5] = redRoundClass;
                    else if (subState === "Success" && classArray[5] !== redRoundClass && classArray[5] !== greenRoundClass)
                        classArray[5] = yellowRoundClass;
                    if (Date.parse(lldSyncServiceStartTime.toString()) === Date.parse(now.toString()))
                        lldSyncServiceStartTime = element.eventTime;
                    break;
                case "LinkedDocumentSyncServiceCompleteEvent":
                    if (subState === "Failed" && classArray[5] !== greenRoundClass)
                        classArray[5] = redRoundClass;
                    if (subState === "Success")
                    {
                        lldSyncServiceCount++;
                        if (lldSyncServiceCount === 4)
                            classArray[5] = greenRoundClass;
                    }
                    if (Date.parse(lldCardSyncServiceEndTime.toString()) === Date.parse(now.toString()))
                        lldCardSyncServiceEndTime = element.eventTime;
                    lldSyncServiceRecentEndTime = element.eventTime;
                    break;
                 default:
                    break;
            }
        });
        timeArray[0] = getDateDiff(ingestionApiStartTime, ingestionApiRecentEndTime, now);
        timeArray[4] = getDateDiff(lldConverterServiceStartTime, lldConverterServiceRecentEndTime, now);
        timeArray[5] = getDateDiff(lldSyncServiceStartTime,lldSyncServiceRecentEndTime, now);
        intervalArray[3] = getDateDiff(ingestionApiEndTime, lldConverterServiceStartTime, now);
        intervalArray[4] = getDateDiff(lldConverterServiceEndTime, lldSyncServiceStartTime, now);
        setCostTime(timeArray);
        setIntervalTime(intervalArray);
        setEventsClass(classArray);
    }

    const generateGenevaLink = (session: MainifestSession | undefined, serviceName: string) => {
        if (session) {
            const namespaces = +getEnvironment() === Environment.PROD ? 'OfferStoreIngestionProd,BigCatPublishNsPROD' : 'OfferStoreIngestionInt,BigCatPublishNsINT'

            return `https://portal.microsoftgeneva.com/logs/dgrep?be=DGrep&time=${session.createdDateTime}&offset=%2B1&offsetUnit=Days&UTC=true`
                + `&ep=Diagnostics%20PROD&ns=${namespaces}&en=CustomEvents,Log`
                + `&conditions=[["cV","contains","${encodeURIComponent(encodeURI(session.correlationVector.substring(0, session.correlationVector.indexOf('.'))))}"],["ext_cloud_role","%3D%3D","${serviceName}"]]`;
        }

        return '';
    };

    return (
        <div>
            {isLoading && (<Spinner label="Loading..." />)}
            {eventsClass.length === 9 && <Stack horizontal disableShrink tokens={headingStackTokens}>
                <Stack styles={stackStyles} tokens={roundSpacingStackTokens} verticalAlign="center">
                    <Link href={generateGenevaLink(props.session, "offerstoreingestionapi")} title="Go to Geneva" target='_blank'>
                        <div className={eventsClass[0]}>Ingestion API<br/>{costTime[0]}</div>
                    </Link>
                </Stack>
                <Stack styles={stackLineStyles} tokens={lineSpacingStackTokens} verticalAlign="center">
                    <div style={itemStyles}>
                        <svg>
                            <defs>
                                <marker id='markerArrow' markerWidth='12' markerHeight='12' refX='9' refY='6' orient='auto'>
                                    <path d='M2,2 L2,11 L10,6 L2,2' style={{fill:"rgb(120,120,120)",}} />
                                </marker>
                            </defs>
                        </svg>
                    </div>
                    <div style={itemStyles}>
                        <svg>
                            <line x1="0" y1="80" x2="100" y2="80" style={{
                                stroke: "rgb(120,120,120)",
                                strokeWidth: 2,
                            }} markerEnd='url(#markerArrow)' />
                            <text x="40" y="65" fill="gray">{intervalTime[3]}</text>
                        </svg>
                    </div>
                    <div style={itemStyles}>
                    </div>
                </Stack>
                <Stack styles={stackStyles} tokens={roundSpacingStackTokens} verticalAlign="center">
                    <Link href={generateGenevaLink(props.session, "offerstoreratecardconverterservice")} title="Go to Geneva" target='_blank'>
                        <div className={eventsClass[4]}>LLD Converter<br/>{costTime[4]}</div>
                    </Link>
                </Stack>
                <Stack styles={stackLineStyles} tokens={lineSpacingStackTokens} verticalAlign="center">
                    <div style={itemStyles}>
                    </div>
                    <div style={itemStyles}>
                        <svg>
                            <line x1="0" y1="80" x2="100" y2="80" style={{
                                stroke: "rgb(120,120,120)",
                                strokeWidth: 2,
                            }} markerEnd='url(#markerArrow)' />
                            <text x="40" y="65" fill="gray">{intervalTime[4]}</text>
                        </svg>
                    </div>
                    <div style={itemStyles}>
                    </div>
                </Stack>
                <Stack styles={stackStyles} tokens={roundSpacingStackTokens} verticalAlign="center">

                    <Link href={generateGenevaLink(props.session, "offerstoreratecardsyncservice")} title="Go to Geneva" target='_blank'>
                        <div className={eventsClass[5]}>LLD Sync<br/>{costTime[5]}</div>
                    </Link>
                </Stack>

                <Stack horizontal disableShrink styles={itemAlignmentsStackStyles} tokens={itemAlignmentsStackTokens}>
                    <Stack.Item align="center">
                    <span className={legendClass}>Legend:</span>
                    </Stack.Item>
                    <Stack.Item align="center">
                    <SwatchColorPicker
                        columnCount={4}
                        cellShape={'circle'}
                        cellHeight={30}
                        cellWidth={30}
                        cellBorderWidth={2}
                        colorCells={colorCellsExample}
                        aria-labelledby={'colorpicker-grid'}
                    />
                    </Stack.Item>
                </Stack>
            </Stack>}
            <StatusMessageBar message={statusMessage} isMultiline />
        </div>
    );
}