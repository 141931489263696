import { TooltipHost } from '@fluentui/react';
import { useBoolean, useId } from '@fluentui/react-hooks';
import { CannedChatIcon } from '@fluentui/react-icons-mdl2';
import { IButtonProps } from '@fluentui/react/lib/Button';
import { mergeStyles } from '@fluentui/react/lib/Styling';
import { TeachingBubble } from '@fluentui/react/lib/TeachingBubble';
import React from 'react';

const iconClass = mergeStyles({
    fontSize: 15,
    height: 15,
    width: 15,
    margin: '0 0px',
    padding: '0 0px'
});

export interface EventDetailButtonProps {
    Details: string,
}

export function EventDetailButton(props: EventDetailButtonProps) {
    const buttonId = useId('targetButton');
    const [teachingBubbleVisible, { toggle: toggleTeachingBubbleVisible }] = useBoolean(false);
    const eventDetailButtonProps: IButtonProps = React.useMemo(
        () => ({
            children: 'Copy',
            onClick: () => {
                navigator.clipboard.writeText(JSON.stringify(props.Details));
                toggleTeachingBubbleVisible();
            },
        }),
        [toggleTeachingBubbleVisible, props.Details],
    );

    return (
        <div>
            <TooltipHost content='View Details'>
                <CannedChatIcon id={buttonId} aria-label="ViewDetails" className={iconClass} onClick={toggleTeachingBubbleVisible} />
            </TooltipHost>

            {teachingBubbleVisible && (
                <TeachingBubble
                    target={`#${buttonId}`}
                    primaryButtonProps={eventDetailButtonProps}
                    onDismiss={toggleTeachingBubbleVisible}
                    headline="Event Details"
                >
                    {JSON.stringify(props.Details)}
                </TeachingBubble>
            )}
        </div>
    );
}
