import React, { useEffect, useState } from "react"
import { ManifestDoc } from "../models/productDocument";
import { PrimaryButton, ProgressIndicator, Stack } from "@fluentui/react";
import { strToU8, zip } from 'fflate';
import { saveAs } from 'file-saver';

export interface ManifestDocProps {
    manifestDoc: ManifestDoc[];
    fileCount: number;
}
export function DocumentDownLoad(props: ManifestDocProps) {
    const [completedFiles, setCompletedFiles] = useState(0);
    const [showProgress, setShowProgress] = useState(false);
    const [isButtonPressed, setIsButtonPressed] = useState(false);
    const [fileData, setFileData] = useState<{ [key: string]: Uint8Array }>({});
    const totalFiles = props.fileCount;

    useEffect(() => {
        if (completedFiles === totalFiles) {
            zip(fileData, (err, zipped) => {
                if (err) {
                    console.error("Error while zipping files:", err);
                } else {
                    saveAs(new Blob([zipped]), 'mainfestDocs.zip');
                }
            });
            setCompletedFiles(0);
            setShowProgress(false);
            setIsButtonPressed(false);
        }
    }, [completedFiles, fileData]);

    const handleClick = () => {
        setShowProgress(true);
        setIsButtonPressed(true);
        DownLoad();
    };

    const DownLoad = () => {
        const newFileData: { [key: string]: Uint8Array } = {};

        props.manifestDoc.forEach((doc) => {
            let doc_name = doc.key;
            doc.docs.forEach((item, index) => {
                let filename = `${doc_name}_${index + 1}.json`;
                let content = strToU8(JSON.stringify(item));
                newFileData[filename] = content;
                setCompletedFiles(prevFiles => (prevFiles >= totalFiles ? 0 : prevFiles + 1));
            });
        });

        setFileData(newFileData);
    }

    return (
        <Stack>
            <PrimaryButton disabled={isButtonPressed} onClick={handleClick}>DownLoad</PrimaryButton>
            {showProgress &&
                <ProgressIndicator
                    styles={{ root: { width: 300 } }}
                    description={`Completed ${completedFiles}/${totalFiles}`}
                    percentComplete={completedFiles / totalFiles} />}
        </Stack>
    );
}
