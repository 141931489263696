export const eventTypeData : string[] = [
    "APIReceivedJsonEvent",
    "ConverterGeneratedPcdEvent",
    "ConverterRateCardGeneratedEvent",
    "ConverterRatingFeedApiGeneratedEvent",
    "ConverterRatingFeedDirectGeneratedEvent",
    "ConverterUpdateStateEvent",
    "DocumentValidationCompletionEvent",
    "PcdTransformedEvent",
    "SessionMonitoringEvent"
]

export const manifestEventTypeData : string[] = [
    "ConverterUpdateStateEvent",
    "SessionMonitoringEvent"
]