export interface ProductDocument {
    "id": string,
    "DocumentId": string,
    "RevisionId": string,
    "SchemaVersion": string,
     "ProductType": string,
     "CountOfSubDocuments": number,
     "DimensionTypes":{$type:string,$values:string[]},
     "DocumentTypes": {$type:string,$values:string[]}
}
export enum DocmentType {
    product = "product",
    permutations = "Permutations",
    compressedPermutations = "CompressedPermutations"
}

export interface manifestProductDocument {
    "RevisionId": string,
    "Item": {$type:string,$values:any[]}
}

export interface ManifestDoc {
    key: string,
    docs: any[],
    count: number

}
