import React,{ useEffect, useState } from "react";

import devOpsApi,{ GetBigIdSessionsRequest } from "../services/devOpsApiClient";
import { ApiParameters, useApi } from "../hooks/useApi";
import { BigIdSession } from "../models/bigIdSessions";
import { DetailsList, IColumn, Spinner, Stack, StackItem } from "@fluentui/react";
import { StatusMessage, StatusMessageBar } from "../statusMessageBar";
import { useNavigate } from "react-router-dom";
import { Pagination } from '../pagination';

export interface BigIdSessionsListProps {
    bigId: string,
    cv: string
}
export function BigIdSessionView(props: BigIdSessionsListProps) {
    const [bigIdSessions, updateBigIdSessions] = React.useState<BigIdSession[]>([]);
    const [apiParameters, updateApiParameters] = useState<ApiParameters[]>([]);
    const [ready, response, isLoading, error, execute] = useApi<BigIdSession[]>();
    const [statusMessage, setStatusMessage] = useState<StatusMessage | null>(null);
    const [flag,updateFlag] = useState<boolean>(false);
    const [position, setPosition] = React.useState("top");
      //Pagination
      const [calculatedPages, setCalculatedPages] = useState<number>(1);
      const [currentPageIndex, setCurrentPageIndex] = useState<number>(0);
      const [initialPage, setInitialPage] = useState<boolean>(false);
      const pageSize = 50;
  
      const goToNextPage = () => {
          setCurrentPageIndex(currentPageIndex + 1);
          setInitialPage(false);
      };
  
      const goToPreviousPage = () => {
          if (currentPageIndex > 0) {
              setCurrentPageIndex(currentPageIndex - 1);
          }
      };
  
      const initialPageIndex = () => {
          setCurrentPageIndex(0);
          setCalculatedPages(1);
          setInitialPage(true);
          setPosition("top");
      };
    const navigate = useNavigate();
    const columns: IColumn[] = [
        {
            key: 'idColumn',
            name: 'Big Id',
            fieldName: 'bigId',
            minWidth: 230,
            maxWidth: 230,
            isResizable: true,
            data: 'string',
            isPadded: true,
        },
        {
            key: 'bigcatVersionColumn',
            name: 'BigcatVersion',
            fieldName: 'bigcatVersion',
            minWidth: 110,
            maxWidth: 110,
            isResizable: true,
            data: 'string',
            isPadded: true,
        },
        {
            key: 'CVColumn',
            name: 'Cv',
            fieldName: 'cv',
            minWidth: 110,
            maxWidth: 110,
            isResizable: true,
            data: 'string',
            isPadded: true,
        },
        {
            key: 'ProductIdColumn',
            name: 'Document Id',
            fieldName: 'productId',
            minWidth: 280,
            maxWidth: 280,
            isResizable: true,
            data: 'string',
            isPadded: true,
        },
        {
            key: 'sessionIdColumn',
            name: 'Session Id',
            fieldName: 'revisionId',
            minWidth: 180,
            maxWidth: 180,
            isResizable: true,
            data: 'string',
            isPadded: true,
        },
        {
            key: 'createdColumn',
            name: 'Create Time',
            fieldName: 'createdDateTime',
            minWidth: 180,
            maxWidth: 180,
            isResizable: true,
            data: 'string',
            isPadded: true,
        },
    ];

    React.useEffect(() => {
        if (initialPage) {
            return;
        }
        const request = {
            bigId:props.bigId,
            bigcatVersion:"",
            cv:props.cv,
            skip: pageSize * currentPageIndex,
        } as GetBigIdSessionsRequest;

        updateApiParameters([devOpsApi.getBigIdSessions(request)]);

        if (items.length !== 0) {
            setPosition("bottom");
        }

    }, [currentPageIndex]);

    useEffect(() => {
        initialPageIndex();
        const request = {
           bigId:props.bigId,
           bigcatVersion:"",
           cv:props.cv
        } as GetBigIdSessionsRequest;

        updateApiParameters([devOpsApi.getBigIdSessions(request)]);

    }, [props.bigId,props.cv]);

    useEffect(() => {
        if (ready) {
            updateFlag(false)
            execute(apiParameters);
        }
    }, [apiParameters, ready]);

    useEffect(() => {
        if (response) {
            const sessions = response as BigIdSession[];
            if (sessions.length === 0) {
                updateFlag(true)
                updateItems([])
                return;
            }
            updateBigIdSessions(sessions)
            if (statusMessage) {
                setStatusMessage(null);
            }
        }
        else
        {
            updateFlag(true)
            updateItems([])
        }
    }, [response]);
    useEffect(() => {
        if (error) {
            setStatusMessage(error);
        }
    }, [error]);
    const [items, updateItems] = useState<BigIdSession[]>(bigIdSessions);
    useEffect(() => {
        if (bigIdSessions.length > 0) {
            
           updateItems(bigIdSessions)
           setCalculatedPages(bigIdSessions.length);
        }
    }, [bigIdSessions]);
    function _onItemInvoked(item: any): void {
         navigate(`/explorer/bigIdIngestion/${item.bigId}/${item.bigcatVersion}`);
    }

    return (
        <Stack >
        <StackItem>
        <div>
              {flag==false&&isLoading && (<Spinner label="Loading..." />)}
              {items.length>0?
              <div>
                <DetailsList
                items={items}
                compact={false}
                columns={columns}
                setKey="multiple"
                isHeaderVisible={true}
                selectionPreservedOnEmptyClick={true}
                enterModalSelectionOnTouch={true}
                ariaLabelForSelectionColumn="Toggle selection"
                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                checkButtonAriaLabel="select row"
                onItemInvoked={_onItemInvoked}

            />
            <StatusMessageBar message={statusMessage} isMultiline />
            </div>:flag ==true?(<div><h2>Sorry,Not query Data!</h2></div>):(<div>      <StatusMessageBar message={statusMessage} isMultiline /></div>)}
        </div></StackItem>
                        <StackItem>
                        <Stack
                            horizontal
                        >
                            {items.length !== 0 && (<Pagination
                                total={0}
                                calculatedPages={calculatedPages}
                                pageSize={pageSize}
                                goToPreviousPage={goToPreviousPage}
                                goToNextPage={goToNextPage}
                                currentPageIndex={currentPageIndex}
                            />)}{position === "bottom" && isLoading && (<Spinner label="Loading..." />)}
                        </Stack>
    
                    </StackItem>
                    </Stack>
    )
}