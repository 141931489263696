import React from 'react';
import { mergeStyleSets, StackItem } from "@fluentui/react";
import { SuppressionRuleState } from "../../models/suppressionRule";

export interface SuppressionRuleStateViewProps {
    suppressionRuleState: SuppressionRuleState
}

const ruleStyles = mergeStyleSets({
    row: {
        display: 'flex',
        flexFlow: 'row nowrap',
        margin: '0 0 10px',
        width: '100%',
    },
    label: {
        flex: '1',
        margin: '0'
    },
    content: {
        flex: '1',
        margin: '0 !important'
    },
    body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
    },
});

export function SuppressionRuleStateView(props: SuppressionRuleStateViewProps) {
    return (
        <div className={ruleStyles.body}>
            <div className={ruleStyles.row}>
                <label className={ruleStyles.label}>DocumentType:</label>
                <p className={ruleStyles.content}>{props.suppressionRuleState.documentType}</p>
            </div>
            <div className={ruleStyles.row}>
                <label className={ruleStyles.label}>ValidationRuleName:</label>
                <p className={ruleStyles.content}>{props.suppressionRuleState.validationRuleName}</p>
            </div>
            <div className={ruleStyles.row}>
                <label className={ruleStyles.label}>Comments:</label>
                <p className={ruleStyles.content}>{props.suppressionRuleState.comments}</p>
            </div>
            <div className={ruleStyles.row}>
                <label className={ruleStyles.label}>LastModifiedBy:</label>
                <p className={ruleStyles.content}>{props.suppressionRuleState.lastModifiedBy}</p>
            </div>
            <div className={ruleStyles.row}>
                <label className={ruleStyles.label}>LastModifiedOn:</label>
                <p className={ruleStyles.content}>{props.suppressionRuleState.lastModifiedDateTime}</p>
            </div>
            {props.suppressionRuleState.suppressedDocumentIds && props.suppressionRuleState.suppressedDocumentIds.length != 0 &&
                <div className={ruleStyles.row}>
                    <label className={ruleStyles.label}>Suppressed Document Ids:</label>
                    <div className={ruleStyles.content}>
                        <table>
                            {props.suppressionRuleState.suppressedDocumentIds.map((docId) => (
                            <tr key={docId}>{ docId }</tr>
                        ))}
                        </table>
                    </div>
                </div>
            }
        </div>
    );
}