export default class RequestParamSetting {
    static setNonEmptyStringParam(url: URL, paramName: string, paramValue: string) {
        if (paramValue && paramValue !== "") {
            url.searchParams.set(paramName, paramValue);
        }
    }

    static setNonDefaultIntParam(url: URL, paramName: string, paramValue: number, defaultValue: number) {
        if (paramValue && paramValue !== defaultValue) {
            url.searchParams.set(paramName, paramValue.toString());
        }
    }

    static setNonMinIntParam(url: URL, paramName: string, paramValue: number, minValue: number) {
        if (paramValue && paramValue > minValue) {
            url.searchParams.set(paramName, paramValue.toString());
        }
    }
}
