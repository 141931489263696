import React from 'react'
import { Route, Routes } from "react-router-dom";
import { NavBar } from "./navbar";
import { LayoutStyles } from "./styles/layout";
import { HomeView } from "./views/homeView";
import { IngestionView } from "./views/ingestionView";
import { IntentsView } from "./views/intentsView";
import { SessionView } from "./views/session/sessionView";
import { ReplayEventView } from "./views/replayEventView";
import { PermutationsView } from "./views/permutationsView";
import { ValidationSuppressionView } from "./views/validationSuppression";
import { SuppressionRuleStateFeedType } from './models/suppressionRule';
import { BigIdSessionView } from './sessions/bigIdSessionView';
import { BigIdSessionsView } from './views/session/bigIdSessionView';
import { ManifestSessionView } from './views/manifestSessionView';
import { DeadLetterView } from "./views/deadLetterView";

export function PageRouter() {

  return (
    <div className="ms-Grid" dir="ltr">
      <div className="ms-Grid-row">
        <div className="ms-Grid-col ms-sm2 ms-xl2">
          <NavBar />
        </div>
        <div className="ms-Grid-col ms-sm10 ms-xl10">
          <Routes>
            <Route path="/" element={<HomeView />} />
            <Route path="/explorer/home" element={<HomeView />} />
            <Route path="/explorer/ingestion" element={<IngestionView ingestionType="ingestion"/>} />
            <Route path="/explorer/bigIdIngestion" element={<IngestionView ingestionType="bigid"/>} />
            <Route path="/explorer/manifestIngestion" element={<IngestionView ingestionType="manifest"/>} />
            <Route path="/explorer/ingestion" element={<IngestionView ingestionType="ingestion"/>} />
            <Route path="/explorer/intents" element={<IntentsView />} />
            <Route path="/explorer/ratingValidationSuppression" element={<ValidationSuppressionView feedType={SuppressionRuleStateFeedType.RatingFeed} />} />
            <Route path="/explorer/rateCardValidationSuppression" element={<ValidationSuppressionView feedType={SuppressionRuleStateFeedType.RateCard} />} />
            <Route
            path="/explorer/intents/:sessionId/:documentId"
            element={<PermutationsView/>}
          />
            <Route
              path="/explorer/ingestion/:sessionId/:documentId"
              element={<SessionView />}
            />
              <Route
              path="/explorer/bigIdIngestion/:bigId/:bigcatVersion"
              element={<BigIdSessionsView />}
            />
                <Route
              path="/explorer/manifestIngestion/:sessionId"
              element={<ManifestSessionView />}
            />
            <Route path="/explorer/deadletter" element={<DeadLetterView />} />
            <Route path="/batch/replayevent" element={<ReplayEventView />} />
            <Route
              path="*"
              element={
                <main style={LayoutStyles.Main}>
                  <p>Coming soon!</p>
                </main>
              }
            />
          </Routes>
        </div>
      </div>
    </div>
  );
}
