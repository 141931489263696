import React from 'react'
import { IColumn, IStackStyles, IStackTokens, Stack } from "@fluentui/react";
import { SubDocButton } from "../sessions/subDocDownLoadButton";
import { Session } from "./session";

export const getHomeListColumns = (): IColumn[] => {
      const stackStyles: Partial<IStackStyles> = {
        root: {
          margin: "0 auto",
          color: "#605e5c",
        },
      };
      const numericalSpacingStackTokens: IStackTokens = {
        childrenGap: 10,
        padding: 0,
    };
    return [
        {
            key: 'sessionIdColumn',
            name: 'SessionId',
            fieldName: 'sessionId',
            maxWidth: 320,
            minWidth: 320,
            isResizable: true,
            data: 'number',
            isPadded: true,
        },
        {
            key: 'createdDateTimeColumn',
            name: 'Date Created',
            fieldName: 'createdDateTime',
            minWidth: 350,
            maxWidth: 350,
            isResizable: true,
            data: 'string',
            onRender: (item: Session) => {
                return <span>{item.createdDateTime}</span>;
            },
            isPadded: true,
        },
        {
            key: 'DownLoad',
            name: 'SubDocuments',
            fieldName: 'DownLoad',
            minWidth: 300,
            maxWidth: 350,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item: Session) => {
                return (
                    <Stack styles={stackStyles} horizontal tokens={numericalSpacingStackTokens}>
                        <SubDocButton documentId={item.documentId} sessionId={item.sessionId}/>
                    </Stack>
                );
            },
        },
    ];
};


