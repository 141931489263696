import React from 'react';
import { useId, useBoolean } from '@fluentui/react-hooks';
import { ContextualMenu, FontWeights, getTheme, IButtonStyles, IconButton, IDragOptions, IDropdownOption, IDropdownStyles, IIconProps, mergeStyleSets, Modal } from '@fluentui/react';

const theme = getTheme();
const cancelIcon: IIconProps = { iconName: 'Cancel' };
const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
    },
    header: [
        theme.fonts.xLargePlus,
        {
            flex: '1 1 auto',
            borderTop: `4px solid ${theme.palette.themePrimary}`,
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    heading: {
        color: theme.palette.neutralPrimary,
        fontWeight: FontWeights.semibold,
        fontSize: 'inherit',
        margin: '0',
    },
    body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
    },
});

const iconButtonStyles: Partial<IButtonStyles> = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};

export interface ModalWrapperProps {
    modalHeading: string,
    component?: React.ReactNode,
    onModalDismissHandler: () => void
}

const dragOptions: IDragOptions = {
    moveMenuItemText: 'Move',
    closeMenuItemText: 'Close',
    menu: ContextualMenu,
    dragHandleSelector: '.ms-Modal-scrollableContent > div:first-child',
};

export function ModalWrapper(props: ModalWrapperProps) {
    const { modalHeading, component, onModalDismissHandler } = props;
    const [isModalOpen, { setFalse: hideModal }] = useBoolean(true);
    const titleId = useId(modalHeading);

    function _onModalDismiss() {
        hideModal();
        onModalDismissHandler();
    }

    return (
        <>
            <Modal
                titleAriaId={titleId}
                isOpen={isModalOpen}
                onDismiss={_onModalDismiss}
                isBlocking={false}
                containerClassName={contentStyles.container}
                dragOptions={dragOptions}
            >
                <div className={contentStyles.header}>
                    <h2 className={contentStyles.heading} id={titleId}>
                        {modalHeading}
                    </h2>
                    <IconButton
                        styles={iconButtonStyles}
                        iconProps={cancelIcon}
                        ariaLabel="Close popup modal"
                        onClick={_onModalDismiss}
                    />
                </div>
                <div className={contentStyles.body}>
                    {component}
                </div>
            </Modal>
        </>
    );
}