import React from 'react'
import { IColumn, TooltipHost } from "@fluentui/react";
import { Session } from "./session";

export const getSessionListColumns = (): IColumn[] => {
    return [
        {
            key: 'sessionIdColumn',
            name: 'SessionId',
            fieldName: 'sessionId',
            minWidth: 120,
            isResizable: true,
            data: 'number',
            isPadded: true,
        },
        {
            key: 'documentIdColumn',
            name: 'DocumentId',
            fieldName: 'documentId',
            minWidth: 450,
            isResizable: true,
            data: 'string',
            isPadded: true,
        },
        {
            key: 'createdDateTimeColumn',
            name: 'Date Created',
            fieldName: 'createdDateTime',
            minWidth: 200,
            isResizable: true,
            data: 'string',
            onRender: (item: Session) => {
                return <span>{item.createdDateTime}</span>;
            },
            isPadded: true,
        },
        {
            key: 'stateColumn',
            name: 'Ingestion State',
            fieldName: 'state',
            minWidth: 100,
            isResizable: true,
            data: 'string',
            isPadded: true,
        },
        {
            key: 'correlationVectorColumn',
            name: 'Correlation Vector',
            fieldName: 'correlationVector',
            minWidth: 200,
            isResizable: true,
            data: 'string',
            isPadded: true,
            onRender: (item: Session) => {
                return (
                    <TooltipHost content={item.correlationVector}>
                        <span>{item.correlationVector?.substring(0, item.correlationVector.indexOf('.'))}</span>
                    </TooltipHost>
                );
            },
        },
        {
            key: 'publishOptionsColumn',
            name: 'Publish Options',
            fieldName: 'publishOptions',
            minWidth: 200,
            isResizable: true,
            data: 'string',
            isPadded: true,
        }
    ];
};

