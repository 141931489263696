import { ApplicationInsights } from '@microsoft/applicationinsights-web'

export const appInsights = new ApplicationInsights({
    config: {
        connectionString: 'InstrumentationKey=a9007ccc-5f67-4dea-8f13-778fc9e73af0;IngestionEndpoint=https://westus2-2.in.applicationinsights.azure.com/;LiveEndpoint=https://westus2.livediagnostics.monitor.azure.com/',
        enableAutoRouteTracking: true
    }
});

export function initializeAppInsights() {
    appInsights.loadAppInsights();
    appInsights.trackPageView(); // Manually call trackPageView to establish the current user/session/pageview
}