import { Spinner } from '@fluentui/react';
import React, { useState, useEffect } from 'react';
import { DetailsList, DetailsListLayoutMode, IColumn, SelectionMode } from '@fluentui/react/lib/DetailsList';
import { testMapping } from '../models/testMapping';
import { useApi, ApiParameters } from '../hooks/useApi';
import { StatusMessage, StatusMessageBar } from '../statusMessageBar';
import pcdApiClient, { GetTestDocumentMappingRequest } from '../services/pcdApiClient';

export interface TestMappingListProps {
    bigIdVersionIds: string[],
    configurationName: string,
}

export function TestMappingView(props: TestMappingListProps) {
    const [testMapping, updateTestMapping] = useState<testMapping[]>([]);
    const [apiParameters, updateApiParameters] = useState<ApiParameters[]>([]);
    const [ready, response, isLoading, error, execute] = useApi<testMapping>();
    const [finished, setFinished] = useState(false);
    const [statusMessage, setStatusMessage] = useState<StatusMessage | null>(null);

    const columns: IColumn[] = [
        {
            key: 'testIdColumn',
            name: 'TestDocumentId',
            fieldName: 'testDocumentId',
            minWidth: 100,
            maxWidth: 150,
            isResizable: true,
            data: 'string',
            isPadded: true,
        },
        {
            key: 'conColumn',
            name: 'TestConfigurationName',
            fieldName: 'testConfigurationName',
            minWidth: 80,
            maxWidth: 150,
            isResizable: true,
            data: 'string',
            isPadded: true,
        },
        {
            key: 'diColumn',
            name: 'DocumentId',
            fieldName: 'documentId',
            minWidth: 80,
            maxWidth: 150,
            isResizable: true,
            data: 'string',
            isPadded: true,
        },
        {
            key: 'ctTimeColumn',
            name: 'Created Time',
            fieldName: 'createdTime',
            minWidth: 180,
            maxWidth: 200,
            isResizable: true,
            data: 'string',
            isPadded: true,
        },
    ];

    useEffect(() => {
        if (finished) {
            return;
        }

        props.bigIdVersionIds.forEach((bigIdVersion) => {

            if (bigIdVersion[2] === "Fragment") {
                const request = {
                    bigId: bigIdVersion[0],
                    configurationName: props.configurationName,
                } as GetTestDocumentMappingRequest;

                updateApiParameters(preApiParas => [...preApiParas, pcdApiClient.getTestDocumentMapping(request)]);
            }
        });
    }, [props]);

    useEffect(() => {
        if (ready && !finished && apiParameters.length !== 0) {
            execute(apiParameters);
        }
    }, [ready]);

    useEffect(() => {
        if (response) {
            setFinished(true);
            if (Array.isArray(response)) {
                const documents = response as testMapping[];
                if (documents.length === 0) {
                    return;
                }

                updateTestMapping(documents);

            } else {
                const document = response as testMapping;

                updateTestMapping([document]);
            }
            setFinished(true);
            if (statusMessage) {
                setStatusMessage(null);
            }
        }
    }, [response]);

    useEffect(() => {
        if (error) {
            setStatusMessage(error);
        }
    }, [error]);

    const [items, updateItems] = useState<testMapping[]>(testMapping);

    useEffect(() => {
        updateItems(testMapping);
    }, [testMapping]);

    return (
        <div>
            {isLoading && (<Spinner label="Loading..." />)}
            {items.length !== 0 && <DetailsList
                items={items}
                compact={false}
                columns={columns}
                selectionMode={SelectionMode.none}
                setKey="multiple"
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                selectionPreservedOnEmptyClick={true}
                enterModalSelectionOnTouch={true}
                ariaLabelForSelectionColumn="Toggle selection"
                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                checkButtonAriaLabel="select row"
            />}

            <StatusMessageBar message={statusMessage} isMultiline />
        </div>
    );
}
