const longMonthFormatter = new Intl.DateTimeFormat('en-US', { month: 'long' });
const shortMonthFormatter = new Intl.DateTimeFormat('en-US', { month: 'short' });
const longMonthNumericYearFormatter = new Intl.DateTimeFormat('en-US', {
    month: 'long',
    year: 'numeric',
});
const shortMonthNumericDayFormatter = new Intl.DateTimeFormat('en-us', {
    month: 'short',
    day: 'numeric',
});
const numericMonth2DigitDayFormatter = new Intl.DateTimeFormat('en-us', {
    month: 'numeric',
    day: '2-digit',
});
const yyyymmddFormatter = new Intl.DateTimeFormat('en-us', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
});

const prettyDateFormatter = new Intl.DateTimeFormat('en-us', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour12: true,
    hour: 'numeric',
    minute: 'numeric',
    // timeZoneName: 'short'
});

const shortTime = new Intl.DateTimeFormat('en-us', {
    hour: 'numeric',
    hour12: true,
});

export default class ValueFormatter {
    // Date
    /**@param {Date} date */
    static monthYearDate(date: Date | number): string {
        return longMonthNumericYearFormatter.format(date);
    }

    /**@param {Date} date */
    static longMonth(date: Date | number): string {
        return longMonthFormatter.format(date);
    }

    /**@param {Date} date */
    static shortMonth(date: Date | number): string {
        return shortMonthFormatter.format(date);
    }

    /** @param {Date} date */
    static monthDayDate(date: Date | number): string {
        return shortMonthNumericDayFormatter.format(date);
    }

    /** @param {Date} date */
    static yyyymmddDate(date: Date | number): string {
        const tempStr = yyyymmddFormatter.format(date).split('/');
        return `${tempStr[2]}${tempStr[0]}${tempStr[1]}`;
    }

    /** @param {Date} date */
    static yyyymmddFormatDate(date: Date | number): string {
        const tempStr = yyyymmddFormatter.format(date).split('-');
        return `${tempStr}`;
    }

    /** @param {Date} date */
    static onFormatDate = (date?: Date): string => {
        return !date ? '' : (date.getFullYear() + '/' + (date.getMonth() + 1) + '/' + date.getDate());
    };  
    static prettyDate(date: Date | number): string {
        return prettyDateFormatter.format(date);
    }

    static shortDate(date: Date | number): string {
        return numericMonth2DigitDayFormatter.format(date);
    }

    static shortTime(date: Date | number): string {
        return shortTime.format(date);
    }
    static FormatDate = (date?: Date): string => {
        return !date ? '' : (date.getFullYear() + '/' + (date.getMonth() + 1) + '/' + date.getDate() + ' ' + date.getHours()+':'+date.getMinutes()+':'+date.getSeconds());
} 
}
