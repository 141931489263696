import { Stack, IStackTokens, ProgressIndicator, PrimaryButton } from '@fluentui/react';
import { DocmentType, ProductDocument } from "../models/productDocument";
import externaleApi, {  QueryDocAsyncRequest } from "../services/externalApiClient";
import { strToU8, zipSync } from 'fflate';
import filer from 'file-saver';
import React, { useEffect, useState } from 'react';
import { ApiParameters, useApi } from '../hooks/useApi';
import { StatusMessage, StatusMessageBar } from '../statusMessageBar';

export interface SubDocButtonProps {
    documentId: string,
    sessionId: string,
}

const stackTokens: IStackTokens = { childrenGap: 40 };
export function SubDocButton(props: SubDocButtonProps) {
    const [apiParameters, setApiParameters] = useState<ApiParameters[]>([]);

    const [subDocuments, setSubDocuments] = useState<any[]>([]);
    const [ready, response, isLoading, error, execute] = useApi<any>();
    const [requestStage, setRequestStage] = useState('');
    const [jsonList, setJsonList] = useState<any[]>();
    const [productDoc, setProductDoc] = useState<ProductDocument>();
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [buttonChecked, setButtonChecked] = useState(false);
    const [progressIndicator, setProgressIndicator] = useState(false);
    const [request, updateRequest] = useState<QueryDocAsyncRequest>({} as QueryDocAsyncRequest);
    const [pageSize, setPageSize] = useState(1000);
    const [statusMessage, setStatusMessage] = useState<StatusMessage | null>(null);
    const [isDownload, setIsDownload] = useState(false);

    useEffect(() => {
        if (isDownload) {
            if (ready) {
                execute([externaleApi.queryDocumentsAsync(request)]);
            }
        }
        return;
    }, [request, ready]);
    useEffect(() => {

        if (response) {
            if (requestStage === 'getProductDoc') {
                let productDocument = response["Items"]["$values"][0] as ProductDocument;
                setProductDoc(productDocument)
                setPercentComplete(0.2);
                GetSubDocForRequest(productDocument);
            }
            else if (requestStage === 'querySubDocAndProductDoc') {
                let subArray: any[] = [];
                let res = response["Items"]["$values"] as any[];
                res.forEach(element => {
                    subArray.push(element);
                });
                if (response.ContinuationToken !== null) {


                    if (jsonList === undefined) {
                        setJsonList(subArray);
                    }
                    else {
                        jsonList?.forEach((item) => { subArray.push(item) })
                        setJsonList(subArray);
                    }
                    GetSubDocForRequest(response);
                }
                else {

                    let subList: any[] = [];
                    jsonList?.forEach((item) => { subArray.push(item) })
                    subList.push([productDoc]);
                    subList.push(subArray);
                    setJsonList([]);
                    setPercentComplete(0.3);
                    GetPermutationForRequest(subList);

                }

            }
            else if (requestStage === 'queryPermutations') {
                let perArray: any[] = [];
                let res = response["Items"]["$values"] as any[];
                res.forEach(element => {
                    perArray.push(element);
                });
                if (response.ContinuationToken !== null) {
                    if (jsonList === undefined) {
                        setJsonList(perArray);
                    }
                    else {
                        jsonList?.forEach((item) => { perArray.push(item) })
                        setJsonList(perArray);
                    }
                    GetPermutationForRequest(response);
                }
                else {
                    let perList: any[] = [];
                    jsonList?.forEach((item) => { perArray.push(item) })
                    subDocuments.forEach((item) => {
                        perList.push(item);
                    })
                    perList.push(perArray);
                    setPercentComplete(0.4);
                    DownLoadButton(perList)

                }
            }

        }
    }, [response]);
    useEffect(() => {
        if (error) {
            setStatusMessage(error);
        }
    }, [error]);
    const [percentComplete, setPercentComplete] = React.useState(0);

    return (
        <div>
            <StatusMessageBar message={statusMessage} isMultiline />
            <Stack horizontal tokens={stackTokens}>
                {
                    <PrimaryButton styles={{ root: { marginTop: 4 } }} text="DownLoad" onClick={DownLoad} allowDisabledFocus disabled={buttonDisabled} checked={buttonChecked} />
                }
            </Stack>
            {
                progressIndicator === true ? (<ProgressIndicator description={(percentComplete * 100).toFixed().toString() + '%'} percentComplete={percentComplete} />) : ''
            }
        </div>
    );
    async function DownLoad() {
        setPercentComplete(0.1);
        setButtonDisabled(true)
        setProgressIndicator(true);
        await GetProductAllForRequest();
    }

    function DownLoadButton(result: any[]): void {
        setSubDocuments(result);
        const zip = {}
        result?.forEach(element => {
            GetDoc(element, zip);
        });
        var filename = (productDoc as ProductDocument).ProductType
        Download(zip, filename);
    }

    function GetDoc(result: Array<any>, zip: any) {
        let res = filter(result, (productDoc as ProductDocument).DocumentId)
        res = OrederArray(res);
        var reName;
        var docname;
        var docNumber: number = 1;
        for (var i = 0; i < res.length; i++) {
            if (res[i]["Type"] === "Product") {
                docname = `Product_${res[i]["Type"]}`;
            }
            else if (res[i]["Type"] === "CompressedPermutations") {
                docname = `Permutations_${res[i]["Type"]}`;
            }
            else {
                docname = `SubDocument_${res[i]["Type"]}`;
            }
            if (docname === reName) {
                docNumber++;
            }
            else {
                docNumber = 1;
            }
            let compressed = strToU8(JSON.stringify(res[i]));
            zip[`${docname}_${docNumber}.json`] = compressed;
            reName = docname;
        }
        setPercentComplete(0.6);
    }

    async function Download(zip: any, filename: string) {
        setPercentComplete(0.7);
        const zipData = zipSync(zip);
        let zipBlob = new Blob([zipData.buffer], { type: 'application/zip' });
        filer.saveAs(zipBlob, `${filename}Product_SubDocuments`);
        setButtonChecked(false);
        setButtonDisabled(false);
        setPercentComplete(1);
        setProgressIndicator(false);
    }
    function filter(result: Array<any>, documentId: string): any[] {

        var filterArray: any[] = [];
        result.forEach((item) => {
            if (item["DocumentId"] === documentId) {
                filterArray.push(item);
            }
        });
        return filterArray;
    }
    async function GetProductAllForRequest() {
        setRequestStage('getProductDoc');
        updateRequest({
            productId: props.documentId,
            revisionId: props.sessionId,
            queryOptions: {
                PageSize: pageSize,
                DocumentTypes: [DocmentType.product],
            }
        } as QueryDocAsyncRequest);
        setIsDownload(true);
    }
    function OrederArray(result: Array<any>) {

        var OrderArray: any[] = result.sort(function (x, y) { return x["Type"].localeCompare(y["Type"]) });
        return OrderArray;
    }
    function GetSubDocForRequest(responseItem: any) {
        setRequestStage('querySubDocAndProductDoc');
        let requestQuerySubDocuments;
        if (productDoc === undefined) {
            requestQuerySubDocuments = {
                productId: responseItem.DocumentId,
                revisionId: responseItem.RevisionId,
                queryOptions: {
                    PageSize: pageSize,
                    DocumentTypes: responseItem.DocumentTypes.$values,
                }
            } as QueryDocAsyncRequest
        }
        else {
            requestQuerySubDocuments = {
                productId: productDoc.DocumentId,
                revisionId: productDoc.RevisionId,
                queryOptions: {
                    PageSize: pageSize,
                    DocumentTypes: productDoc.DocumentTypes.$values,
                    continuationToken: responseItem.ContinuationToken ? responseItem.ContinuationToken : undefined
                }
            } as QueryDocAsyncRequest
        }
        updateRequest(requestQuerySubDocuments)
    }
    function sleep(ms: number): Promise<void> {
        return new Promise<void>((resolve) => {
            setTimeout(resolve, ms)
        })
    }
    async function GetPermutationForRequest(responseItems: any[]) {
        let requestQueryPermutation;
        if (responseItems.length !== 0) {
            if (responseItems.length === 2) {
                setSubDocuments(responseItems);
                requestQueryPermutation = {
                    productId: (productDoc as ProductDocument).DocumentId,
                    revisionId: (productDoc as ProductDocument).RevisionId,
                    queryOptions: {
                        PageSize: pageSize,
                        DocumentTypes: [DocmentType.permutations,DocmentType.compressedPermutations],
                    }
                } as QueryDocAsyncRequest
            }
            else {
                let response = responseItems as any
                requestQueryPermutation = {
                    productId: (productDoc as ProductDocument).DocumentId,
                    revisionId: (productDoc as ProductDocument).RevisionId,
                    queryOptions: {
                        PageSize: pageSize,
                        DocumentTypes:[DocmentType.permutations,DocmentType.compressedPermutations],
                        continuationToken: response.ContinuationToken
                    }

                } as QueryDocAsyncRequest
            }
            setRequestStage('queryPermutations');
            await sleep(0.2)
            updateRequest(requestQueryPermutation)
        }
    }

}