import React from 'react';
import { LayoutStyles } from "../styles/layout";
import { SuppressionRuleStateFeedType } from "../models/suppressionRule";
import { SuppressionRulesListView } from "./validationSuppression/suppressionRulesListView";
import { Stack, Text, FontWeights, IStackTokens, IStackStyles, ITextStyles } from "@fluentui/react";
import { SessionSuppressionRulesListView } from './validationSuppression/sessionSuppressionRulesListView';

const boldStyle: Partial<ITextStyles> = {
    root: { fontWeight: FontWeights.semibold },
};

const stackTokens: IStackTokens = { childrenGap: 15 };
const stackStyles: Partial<IStackStyles> = {
    root: {
        margin: "0 auto",
        color: "#605e5c",
    },
};

export interface ValidationSuppressionViewProps {
    feedType: SuppressionRuleStateFeedType
}

export function ValidationSuppressionView(props: ValidationSuppressionViewProps)
{
    const { feedType } = props;

    return (
        <div style={LayoutStyles.Main}>
            <Stack
                horizontalAlign="start"
                verticalAlign="start"
                verticalFill
                styles={stackStyles}
                tokens={stackTokens}
            >
                <Text variant="xLarge" styles={boldStyle}>
                    {feedType} - Validation Suppression
                </Text>
                <Text variant="large" styles={boldStyle}>
                    Global Suppression Rules
                </Text>
                <SuppressionRulesListView feedType={feedType} />
            </Stack>
            <div>&nbsp;&nbsp;&nbsp;</div>
            <div>&nbsp;&nbsp;&nbsp;</div>
            <Stack
                horizontalAlign="start"
                verticalAlign="start"
                verticalFill
                styles={stackStyles}
                tokens={stackTokens}
            >
                <Text variant="large" styles={boldStyle}>
                    Session Suppression Rules
                </Text>
                <SessionSuppressionRulesListView feedType={feedType} />
            </Stack>
        </div>
    );
}