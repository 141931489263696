import { Stack, Text, ITextStyles } from "@fluentui/react";
import React from 'react';
import "./styles/pagination.css";

const totalStyle: Partial<ITextStyles> = {
    root: { height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' },
};

export interface PaginationControlProps {
    currentPageIndex: Number;
    calculatedPages: Number;
    pageSize: Number;
    goToPreviousPage: () => void;
    goToNextPage: () => void;
    total: Number;
}

export function Pagination(props: PaginationControlProps) {
    var totalPages = (props.total as number)%(props.pageSize as number) == 0 ? (props.total as number)/(props.pageSize as number) : Math.floor((props.total as number)/(props.pageSize as number)) + 1;
    return (
        <Stack
            data-testid="pagination"
            horizontal
        >
            <div className="pagination">
                <a title="previous page" className={props.currentPageIndex === 0 ? 'disabled-link' : 'enabled-link'} onClick={props.goToPreviousPage} >&laquo;</a>
                <a className="active" >{(props.currentPageIndex as number) + 1}</a>
                <a title="next page" className={props.calculatedPages < props.pageSize ? 'disabled-link' : 'enabled-link'} onClick={props.goToNextPage} >&raquo;</a>
            </div>
            {props.total as number > 0 && (<div>
                <Text styles={totalStyle}>Total Pages: {totalPages}, Total Counts: {props.total}</Text>
            </div>)}
        </Stack>
    );
}