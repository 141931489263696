import React, { useEffect, useState } from 'react'
import ratingFeedApiClient, { GetBigcatVersionRequest } from '../services/ratingFeedApiClient';
import { ApiParameters, useApi } from '../hooks/useApi';
import { ChoiceGroup, ComboBox, IChoiceGroupOption, IComboBox, IComboBoxOption, IDropdownOption } from '@fluentui/react';
import { StatusMessage, StatusMessageBar } from '../statusMessageBar';

export interface RatingFeedBigcatVersionListProps {
    documentId: string,
    revisionId: string,
    setbigCatVersion(version: string): void;
}
export function RatingFeedBigVersion(props: RatingFeedBigcatVersionListProps) { 
    const [ready, response, isLoading, error, execute] = useApi<any>();
    const [versionsData, setVersionsData] = useState<string[]>([]);
    const [selectedVersion,setSelectedVersion] = useState<string>('');
    const [apiParameters, updateApiParameters] = useState<ApiParameters[]>([]);
    const [statusMessage, setStatusMessage] = useState<StatusMessage | null>(null);
    useEffect(() => {
        const request = {
            productId: props.documentId,
            revisionId: props.revisionId,
        } as GetBigcatVersionRequest;
        updateApiParameters([ratingFeedApiClient.getRatingFeedBigcatVersions(request)]);
    }, [props.documentId, props.revisionId]);
    useEffect(() => {
        if (ready) {
            execute(apiParameters);
        }
    }, [apiParameters, ready]);

    useEffect(() => {
        if (response) {
            const sortValues = (response.bigcatVersions as []).sort() as string[];
            setVersionsData(sortValues);
            setSelectedVersion(sortValues[0]);
           props.setbigCatVersion(sortValues[0]);
        }
    }, [response]);
    useEffect(() => {
        if (error) {
            setStatusMessage(error);
        }
    }, [error]);

    const onChangeValue =(event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string): void => {
                if (option) {
            setSelectedVersion(option.key as string);
            props.setbigCatVersion(option.key as string);
        }
    }

    const versionOptions: IComboBoxOption[] = versionsData.map((version) => ({
        key: version,
        text: version,
    }));
    return (
        versionsData.length > 0 ? (
        <div>
            <h3 style={{width:'248px'}}>BigCat Versions</h3>
            <ComboBox 
                dropdownWidth={60}
                style={{width:'248px'}}
                options={versionOptions}
                selectedKey={selectedVersion}
                onChange={onChangeValue}
            />
        </div>
        
        ):(<div><StatusMessageBar message={statusMessage} isMultiline /></div>)
    );
}

